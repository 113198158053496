<ng-template #viewAuditLogModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="viewAuditLogModalLabel">
      Audit Log Details
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <h6 class="card-subtitle mb-2 text-muted">Table Name</h6>
          <p class="card-text">{{ _log.tableName }}</p>
        </div>
        <div class="mb-3">
          <h6 class="card-subtitle mb-2 text-muted">Action</h6>
          <p class="card-text">{{ _log.action }}</p>
        </div>
        <div class="mb-3">
          <h6 class="card-subtitle mb-2 text-muted">Creation Date</h6>
          <p class="card-text">{{ generalService.formatDateTime(_log.creationDate) }}</p>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <h6 class="card-subtitle mb-2 text-muted">Changes</h6>
          <div class="overflow-auto" style="max-height: 300px;">
            <table class="table table-bordered mb-0">
              <thead class="table-light">
                <tr>
                  <th class="p-2">Column Name</th>
                  <th class="p-2">Old Data</th>
                  <th class="p-2">New Data</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let key of getDictionaryKeys()" [ngClass]="{
                    'bg-danger text-light': _dataDict[key]?.hasChanged && _dataDict[key]?.old_value !== '-'
                  }">
                  <td class="p-2">{{ key }}</td>
                  <td class="p-2 text-nowrap text-truncate">
                    {{ _dataDict[key]?.old_value === '-' ? '-' : (key === 'Logo' || key === 'ProfileImage' ?
                    truncateBase64(_dataDict[key]?.old_value) : _dataDict[key]?.old_value) }}
                  </td>
                  <td class="p-2 text-nowrap text-truncate">
                    {{ _dataDict[key]?.new_value === '-' ? '-' : (key === 'Logo' || key === 'ProfileImage' ?
                    truncateBase64(_dataDict[key]?.new_value) : _dataDict[key]?.new_value) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close();">
      <i class="mdi mdi-close"> </i>Close
    </button>
  </div>
</ng-template>


<div class="row">
  <!--<h4 class="dashboard-heading">AUDIT LOG</h4>-->
  <h5>AUDIT LOG</h5>
</div>
<!-- <div>
  <input type="text" [(ngModel)]="_searchTerm" (input)="onSearch()" placeholder="Search...">
</div>
<table>
  <thead>
    <tr>
      <th>ID</th>
      <th>Action</th>
      <th>Timestamp</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let log of _logs">
      <td>{{ log.id }}</td>
      <td>{{ log.action }}</td>
      <td>{{ log.timestamp }}</td>
    </tr>
  </tbody>
</table> -->

<div class="card-header mt-3">
  <div class="row">
    <div class="col-md-3 col-sm-auto align-right">
      <input id="table-complete-search" type="text" class="form-control" placeholder="Search..."
        [(ngModel)]="_searchTerm" (keyup)="onSearch()" />
    </div>
    <div class="col-md-4 col-md-auto"></div>
    <div class="col-md-5 col-md-auto">

    </div>
  </div>
  &nbsp;
  <div class="row" *ngIf="_logs.length === 0">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body text-center">
          <p>No logs, currently.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="_logs.length > 0">
    <div class="col-md-12 stretch-card">
      <div class="card">
          <div class="table-responsive">
            <ngx-datatable [rows]="_logs" class="bootstrap" [loadingIndicator]="true"
              [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="_pageSize"
              [pagination]="true" [rowHeight]="40" (page)="onPageChange($event)"
              [count]="_totalRecords" [externalPaging]="true" [pageSize]="_pageSize">
              <ngx-datatable-column *ngFor="let column of _logColumns" [name]="column.header">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <ng-container *ngIf="column.header === 'Category'">
                    <div class="d-flex align-items-center hover" (mouseenter)="onRowMouseEnter(row)"
                      (mouseleave)="onRowMouseLeave(row)">
                      {{ row.tableName }}
                      <button
                        class="btn btn-secondary button-wrapper btn-icon-text btn-small-transparent btn-smaller"
                        *ngIf="row.isHovered" (click)="previewAudit(row)">
                        Preview
                      </button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="column.header === 'Action'">
                    {{row.action}}
                  </ng-container>
                  <ng-container *ngIf="column.header === 'Old Data'">
                    {{row.oldData || '-'}}
                  </ng-container>
                  <ng-container *ngIf="column.header === 'New Data'">
                    {{row.newData || '-'}}
                  </ng-container>
                  <ng-container *ngIf="column.header === 'Date'">
                    {{generalService.formatDateTime(row.creationDate)}}
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
&nbsp;


<!-- <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <label for="tableName" class="col-form-label">Table Name:</label>
        <p id="tableName">{{_log.tableName}}</p>

        <label for="action" class="col-form-label">Action:</label>
        <p id="action">{{_log.action}}</p>

        <label for="oldData" class="col-form-label">Old Data:</label>
        <p id="oldData">{{_log.oldData}}</p>

        <label for="newData" class="col-form-label">New Data:</label>
        <p id="newData">{{_log.newData}}</p>

        <label for="creationDate" class="col-form-label">Creation Date:</label>
        <p id="creationDate"> {{generalService.formatDateTime(_log.creationDate)}}</p>
      </div>
    </div>
  </div> -->