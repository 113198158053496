<ng-template #todayTemplate>
  <!-- <div class="row" *ngIf="_companyUsers.length === 0">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body text-center">
          <p>No data available, currently.</p>
        </div>
      </div>
    </div>
  </div> -->
  <ngx-datatable class="bootstrap my-datatable" [rows]="_timeSlots" [columnMode]="ColumnMode"
    [headerHeight]="50" [rowHeight]="40" [scrollbarH]="true" [scrollbarV]="false">

    <ngx-datatable-column name="Time" [frozenLeft]="true" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.time }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngFor="let user of _companyUsers; let i = index"
      [name]="user.customerName" [sortable]="false" [userId]="user.id">
      <ng-template ngx-datatable-header-template let-column="column">
        <div class="row">
          <div class="col-md-4">
            <div class="wd-30 ht-30 rounded-circle custom-rounded-circle custom-icon"
              style="background-color: #1ac0a24a; color: black; display: flex; justify-content: center; align-items: center;font-size:medium">

              <ng-container *ngIf="user.profileImage; else defaultIcon">
                <img [src]="user.profileImage" alt="Image" width="40" height="40">
              </ng-container>
              <ng-template #defaultIcon>
                <lord-icon src="https://cdn.lordicon.com/eszyyflr.json" trigger="hover"
                  state="hover-nodding" colors="primary:#121331,secondary:#1ac0a1"></lord-icon>
              </ng-template>
            </div>

          </div>
          <div class="col-md-8">
            <span
              class="datatable-header-cell-label draggable"><strong>{{user.customerName}}</strong></span>
            <br>
            <span>{{ user.roleNames }}</span>
          </div>
        </div>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template let-column="column">
        <div class="cell-wrapper" *ngIf="i !== -1" (dragover)="onDragOver($event,row, column, user)"
          (dragenter)="onDragEnter($event, row, column)"
          (dragleave)="onDragLeave($event, row, column)" (drop)="onDrop($event, row, column, user)"
          style="min-height: 28px; min-width: 138px;" [ngClass]="{
            'new': getCellData(row, user.id)?.status === 1,
            'inprogress': getCellData(row, user.id)?.status === 2,
            'completed': getCellData(row, user.id)?.status === 3
          }">
          <div *ngIf="getCellData(row, user.id)" class="cell-content">
            <a #p="ngbPopover" [ngbPopover]="popoverContent" container="body"
              [autoClose]="'outside'"
              (click)="onClickPopupShow(getCellData(row, user.id).itemId, user.id)">
              {{ getCellData(row, user.id).itemId }}
            </a>
            <button (click)="removeItem($event, row, column, user.id)"
              class="cross-button float-end">X</button>
            <ng-template #popoverContent>
              <div class="custom-popover-body">

                <div class="text-center">
                  <div class="wd-80 ht-80 rounded-circle custom-rounded-circle-popover custom-icon"
                    style="background-color: #1ac0a24a; color: black; display: inline-flex; justify-content: center; align-items: center;font-size:medium">

                    <ng-container *ngIf="user.profileImage; else defaultIcon">
                      <img [src]="user.profileImage" alt="Image" width="70" height="70"
                        align-item="center">
                    </ng-container>
                    <ng-template #defaultIcon>
                      <lord-icon src="https://cdn.lordicon.com/eszyyflr.json" trigger="hover"
                        state="hover-nodding"
                        colors="primary:#121331,secondary:#1ac0a1"></lord-icon>
                    </ng-template>
                  </div>
                </div>
                <div class="text-center">
                  <label><strong>{{user.customerName}}</strong>&nbsp;|&nbsp;</label>
                  <label><strong>{{user.roleNames}}</strong></label>
                </div>
                <hr class="mb-0 mt-1">
                <div class="row ">
                  <div class="col-md-6">
                    <label><strong>Job Id</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ getCellData(row, user.id)?.itemId }}</p>
                  </div>
                  <div class="col-md-6">
                    <label><strong>Job Type</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ enummappingService.mapAssignmentType(getCellData(row, user.id)?.type) }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label><strong>Status</strong></label>
                  </div>
                  <div class="col-md-6 ">
                    <p>{{ enummappingService.mapProcessStatus(getCellData(row, user.id)?.status) }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label><strong>Customer</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ _assignedItemById.customer || ' - ' }}</p>
                  </div>
                  <div class="col-md-6">
                    <label><strong>Account</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ _assignedItemById.account || ' - ' }}</p>
                  </div>
                  <div class="col-md-6">
                    <label><strong>Third Party</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ _assignedItemById.thirdPartyName || ' - ' }}</p>
                  </div>
                  <div class="col-md-6">
                    <label><strong>Origin</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ _assignedItemById.origin || ' - ' }}</p>
                  </div>
                  <div class="col-md-6">
                    <label><strong>Destination</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ _assignedItemById.destination || ' - ' }}</p>
                  </div>

                  <div class="col-md-6">
                    <label><strong>Move Type</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p> {{enummappingService.mapMoveType(_assignedItemById.moveType || 0) || ' - '}}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label><strong>Move Category</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p>{{enummappingService.mapMoveCategoryType(_assignedItemById.moveCategory || 0)
                      || '
                      - '}}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label><strong>Transport Type</strong></label>
                  </div>
                  <div class="col-md-6">
                    <p>{{ enummappingService.mapTransportType(_assignedItemById.moveTransport || 0)
                      || '
                      - '}}
                    </p>
                  </div>
                  <ng-container
                    *ngIf="_assignedItemById.type === eAssignmentType.Move || _assignedItemById.type === eAssignmentType.Survey">
                    <div class="col-md-6">
                      <label><strong>Survey Date</strong></label>
                    </div>
                    <div class="col-md-6">
                      <p>{{ generalService.formatDate(_assignedItemById.surveyDate,null) || ' - '}}
                      </p>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="_assignedItemById.type === eAssignmentType.Move || _assignedItemById.type === eAssignmentType.Packing">
                    <div class="col-md-5">
                      <label><strong>Packing Date</strong></label>
                    </div>
                    <div class="col-md-7">
                      <p>{{ generalService.formatDate(_assignedItemById.packagingDateFrom,
                        _assignedItemById.packagingDateTo) || ' - '}}</p>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="_assignedItemById.type === eAssignmentType.Move || _assignedItemById.type === eAssignmentType.Delivery">
                    <div class="col-md-6">
                      <label><strong>Delivery Date</strong></label>
                    </div>
                    <div class="col-md-6">
                      <p>
                        {{ generalService.formatDate(_assignedItemById.deliveryDate,null) || ' - '}}
                      </p>
                    </div>
                  </ng-container>
                  <div class="mt-2">
                    <div class="row">
                      <div class="col-md-10">
                        <a class="d-block" (click)="inventorySection()">
                          <div
                            class="d-flex justify-content-between align-items-center address-hover">
                            <h5 class="mr-2">Inventory</h5>
                            <span class="mr-2 arrow-icon">
                              <i
                                [ngClass]="_inventorySection ? 'feather icon-chevron-up' : 'feather icon-chevron-down'"></i>
                            </span>
                          </div>
                        </a>
                        <hr class="mt-1 border-dark-grey">
                      </div>
                      <div class="col-md-2">
                        <a class="border border-0 btn-icon-text float-end"
                          (click)="openAssignInventoryModal(assignInventoryModal, p, (getCellData(row, user.id)?.type), (getCellData(row, user.id)?.id), row, user.id)">
                          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                            colors="primary:#000000,secondary:#ebe6ef"
                            style="width: 20px; height: 20px">
                          </lord-icon>
                        </a>
                      </div>
                    </div>

                  </div>
                  <div [ngClass]="{ 'd-none': !_inventorySection }">
                    <div class="row">
                      <div class="col-md-6">
                        <label><strong>Labour</strong></label>
                      </div>
                      <div class="col-md-6">
                        <ng-container *ngIf="_labourList && _labourList.length > 0; else noLabour">
                          <ng-container *ngFor="let data of _labourList">
                            <p>
                              <a> <img *ngIf="data.type === eLabourType.Driver"
                                  src="assets/images/movd/driver.png"
                                  style="width: 20px; height: 20px;" ngbPopover="Driver"
                                  triggers="hover"></a>
                              <a> <img *ngIf="data.type === eLabourType.Loader"
                                  src="assets/images/movd/loader.png"
                                  style="width: 20px; height: 20px;" ngbPopover="Loader"
                                  triggers="hover"></a>
                              <a><img *ngIf="data.type === eLabourType.Packer"
                                  src="assets/images/movd/packer.png"
                                  style="width: 20px; height: 20px;" ngbPopover="Packer"
                                  triggers="hover"></a>
                              &nbsp; &nbsp; {{ data.name }}
                            </p>
                          </ng-container>
                        </ng-container>
                        <ng-template #noLabour>
                          <p> - </p>
                        </ng-template>
                      </div>
                      <hr class="mt-1 border-dark-grey">
                      <div class="col-md-6">
                        <label><strong>Vehicle</strong></label>
                      </div>
                      <div class="col-md-6">
                        <ng-container
                          *ngIf="_vehicleList && _vehicleList.length > 0; else noVehicle">
                          <ng-container *ngFor="let data of _vehicleList">
                            <p>
                              <a> <img src="assets/images/movd/cargo-truck.png"
                                  style="width: 20px; height: 20px;" ngbPopover="Truck"
                                  triggers="hover"></a>
                              &nbsp; &nbsp; {{ data.name }}
                            </p>
                          </ng-container>
                        </ng-container>
                        <ng-template #noVehicle>
                          <p> - </p>
                        </ng-template>
                      </div>
                      <hr class="mt-1 border-dark-grey">
                      <div class="col-md-6">
                        <label><strong>Packing Material</strong></label>
                      </div>
                      <div class="col-md-6">
                        <ng-container
                          *ngIf="_packingMaterialList && _packingMaterialList.length > 0; else noPackingMaterial">
                          <ng-container *ngFor="let data of _packingMaterialList">
                            <p>{{ data.name }} &nbsp; &nbsp;
                              {{enummappingService.mapMaterialType(data.type)}}</p>
                          </ng-container>
                        </ng-container>
                        <ng-template #noPackingMaterial>
                          <p> - </p>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</ng-template>

<ng-template #columnModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="columnModal">&nbsp;Edit Columns</h5>
  </div>
  <div class="modal-body">
    <table>
      <ng-container>
        <tr *ngFor="let column of _allColumns">
          <td>
            <label>
              <input type="checkbox" class="form-check-input" [(ngModel)]="_selectedColumns[column]"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="columnToggleSubject.next()" />
              {{ column }}
            </label>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-template>

<ng-template #assignInventoryModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <i class="mdi mdi-account-plus"> </i> &nbsp; Assign Inventory
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label class="col-form-label">Assign Labour</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="_labourData" bindLabel="name" bindValue="id"
          [(ngModel)]="_selectedLabourIds" [multiple]="true" placeholder="Select Labour Type">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="row">
              <div class="col-md-6">
                <span> {{ item.name }} </span>
              </div>
              <div class="col-md-3">
                <div class="text-end">{{ enummappingService.mapLabourType(item.type) }}</div>
              </div>
              <div class="col-md-3">
                <button type="button" class="btn btn-xs p-0 custom-btn"
                  popoverTitle="Labour Details" [ngbPopover]="labourPopover" container="body"
                  [autoClose]="'outside'" (click)="$event.stopPropagation()">View Detail</button>
                <ng-template #labourPopover>
                  <div class="custom-popover-body">
                    <div class="row">
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Name</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ item.name || ' - ' }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Type</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ enummappingService.mapLabourType(item.type) ||
                          ' - ' }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>NIC</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ item.nic || ' - ' }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Address</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ item.address || ' - ' }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Phone</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ item.phone || ' - ' }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Email</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ item.email || ' - ' }}</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-md-3 mt-3">
        <label class="col-form-label">Assign Vehicle</label>
      </div>
      <div class="col-md-9 mt-3">
        <ng-select [items]="_vehicleData" bindLabel="name" bindValue="id" [multiple]="true"
          [(ngModel)]="_selectedVehicleIds" placeholder="Select Vehicle Type">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="row">
              <div class="col-md-6">
                <span> {{ item.name }} </span>
              </div>
              <div class="col-md-3">
                <div class="text-end">{{ enummappingService.mapVehicleType(item.type) }}</div>
              </div>
              <div class="col-md-3">
                <button type="button" class="btn btn-xs p-0 custom-btn"
                  popoverTitle="Vehicle Details" [ngbPopover]="vehiclePopover" container="body"
                  [autoClose]="'outside'" (click)="$event.stopPropagation()">View Detail</button>
                <ng-template #vehiclePopover>
                  <div class="custom-popover-body">
                    <div class="row">
                      <div class="col-md-6">
                        <p class="col-form-label"><strong>Name</strong></p>
                      </div>
                      <div class="col-md-6">
                        <p class="col-form-label">{{ item.name || ' - ' }}</p>
                      </div>
                      <div class="col-md-6">
                        <p class="col-form-label"><strong>Type</strong></p>
                      </div>
                      <div class="col-md-6">
                        <p class="col-form-label">{{ enummappingService.mapVehicleType(item.type) ||
                          ' - ' }}</p>
                      </div>
                      <div class="col-md-6">
                        <p class="col-form-label"><strong>Model No</strong></p>
                      </div>
                      <div class="col-md-6">
                        <p class="col-form-label">{{ item.modelNo || ' - ' }}</p>
                      </div>
                      <div class="col-md-6">
                        <p class="col-form-label"><strong>Registration No</strong></p>
                      </div>
                      <div class="col-md-6">
                        <p class="col-form-label">{{ item.registrationNo || ' - ' }}</p>
                      </div>
                      <div class="col-md-6">
                        <p class="col-form-label"><strong>Capacity</strong></p>
                      </div>
                      <div class="col-md-6">
                        <p class="col-form-label">{{ item.capacity || ' - ' }}</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-md-3 mt-3">
        <label class="col-form-label">Assign Material</label>
      </div>
      <div class="col-md-9 mt-3">
        <ng-select [items]="_packingMaterialData" bindLabel="name" bindValue="id" [multiple]="true"
          [(ngModel)]="_selectedpackingMaterialIds" placeholder="Select Packing Material Type">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="row">
              <div class="col-md-5">
                <span> {{ item.name }} </span>
              </div>
              <div class="col-md-4">
                <div class="text-end">{{ enummappingService.mapMaterialType(item.type) }}</div>
              </div>
              <div class="col-md-3">
                <button type="button" class="btn btn-xs p-0 custom-btn"
                  popoverTitle="Packing Material Details" [ngbPopover]="materialPopover"
                  container="body" [autoClose]="'outside'" (click)="$event.stopPropagation()">View
                  Detail</button>
                <ng-template #materialPopover>
                  <div class="custom-popover-body">
                    <div class="row">
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Name</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ item.name || ' - ' }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Type</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ enummappingService.mapMaterialType(item.type)
                          || ' - ' }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Weight</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ item.weight || ' - ' }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Volume</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ item.volume || ' - ' }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="col-form-label"><strong>Quantity</strong></p>
                      </div>
                      <div class="col-md-8">
                        <p class="col-form-label">{{ item.quantity || ' - ' }}</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="assignInventory()">
      <i class="mdi mdi-content-save"> </i> &nbsp; Save
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close()">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>


<div>
  <app-title-breadcrumb [title]="'DISPATCH BOARD'" [breadcrumbs]="['Dashboard', 'Dispatch Board']">
  </app-title-breadcrumb>
  <h5>DISPATCH BOARD</h5>

</div>
<div class="row tabs-col mt-3">
  <div class="col-md-3 tabs-col">
    <div class="mt-1">
      <div class="input-group">
        <div class="position-relative">
          <input class="form-control clickable" name="dp" ngbDatepicker #d="ngbDatepicker"
            [value]="generalService.formatDate(_selectedDate, null)" (click)="d.toggle()"
            (dateSelect)="onDateSelect($event)" outsideDays="hidden" placeholder="Select Date"
            [dayTemplate]="t" [startDate]="_selectedDateShow!" readonly />
          <button class="btn btn-small cross-btn" type="button" title="Clear Date"
            *ngIf="_selectedDate" (click)="_selectedDate = null">
            <i class="feather icon-x"></i>
          </button>
        </div>
        <button class="input-group-text" type="button" title="date-range" (click)="d.toggle()">
          <i class="feather icon-calendar icon-md text-muted"></i>
        </button>
      </div>

      <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.highlight]="isHighlighted(date)">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
    <div class="status-color mt-2">
      <label class="form-label"><span class="blue-box"></span>New</label>
      <label class="form-label"><span class="red-box"></span>In Progress</label>
      <label class="form-label"><span class="green-box"></span>Completed</label>
    </div>
    <div class="btn-group" role="group" aria-label="Time Period">
      <button type="button" class="btn btn-outline-primary btn-rectangular btn-lg mt-3"
        [class.btn-primary]="_activeTabId === 1" (click)="onChangeTab(1)">Daily</button>
      <!-- <button type="button" class="btn btn-outline-primary btn-rectangular btn-lg mt-3"
        [class.btn-primary]="_activeTabId === 2" (click)="onChangeTab(2)">Weekly</button>
      <button type="button" class="btn btn-outline-primary btn-rectangular btn-lg mt-3"
        [class.btn-primary]="_activeTabId === 3" (click)="onChangeTab(3)">Monthly</button> -->
    </div>

    <div class="btn-group ms-3" role="group" aria-label="Time Period">
      <button type="button" class="btn btn-outline-primary btn-rectangular btn-lg mt-3"
        [class.btn-primary]="_activeTabId === 1" (click)="openRoleModal(roleModal)">Edit Roles</button>
    </div>

    <!--Edit Roles Model -->
    <ng-template #roleModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="roleModal">Edit Roles</h5>
      </div>
      <div class="modal-body">
        <table class="table">
          <tbody>
            <tr *ngFor="let role of _allRoles">
              <td>
                <label>
                  <input type="checkbox" class="form-check-input" [(ngModel)]="_selectedRoles[role]"
                    [ngModelOptions]="{ standalone: true }" (ngModelChange)="onRoleToggle(role, $event)" />
                  {{ role }}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="modal.close()">Ok</button>
      </div>
    </ng-template>
  </div>
  <div class="col-md-9 table-col">
    <!-- <ngx-datatable [rows]="_dispatchStats" [columnMode]="ColumnMode" class="">
      <ngx-datatable-column class="datatable-row-left" [sortable]="false">
        <ng-template let-row="row" class="text-small" ngx-datatable-cell-template>
          {{ enummappingService.mapAssignmentType(row.type) }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        *ngFor="let status of ['new', 'inProgress', 'completed', 'cancelled', 'total']"
        class="datatable-row-left" [name]="status.charAt(0).toUpperCase() + status.slice(1)"
        [sortable]="false">
        <ng-template ngx-datatable-header-template let-column="column">
          <div class="col-md-8 mt-2">
            <span class="datatable-header-cell-label draggable">
              <strong> {{ column.name }}</strong></span>
          </div>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div>
            {{ row[status] }}
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable> -->
    <table class="table dash-stats-table table-responsive table-striped">
      <thead>
        <tr>
          <th>Type</th>
          <th>New</th>
          <th>In Progress</th>
          <th>Completed</th>
          <th>Cancelled</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of _dispatchStats">
          <td>{{ enummappingService.mapAssignmentType(row.type) }}</td>
          <td>{{ row.new }}</td>
          <td>{{ row.inProgress }}</td>
          <td>{{ row.completed }}</td>
          <td>{{ row.cancelled }}</td>
          <td>{{ row.total }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</div>
<div class="row mt-1 tabs-col">
  <ng-container *ngIf="_activeTabId === 1">
    <ng-container *ngTemplateOutlet="todayTemplate"></ng-container>
  </ng-container>
</div>

<div class="row mt-2 tabs-col">
  <div class="col-3">
    <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="_searchTerm"
      (keyup)="onSearch()" (keypress)="onSearch()" />
  </div>
  <div class="col-5"></div>

  <div class="col-4">
    <button class="btn btn-xs btn-secondary btn-icon-text float-end"
      (click)="openColumnModal(columnModal)" style="margin-right: 3px; width: 130px">
      <lord-icon src="https://cdn.lordicon.com/qtqvorle.json" trigger="hover" delay="2000"
        colors="outline:#121331,primary:#646e78,secondary:#ebe6ef,tertiary:#4cb4fd"
        style="width: 20px; height: 20px">
      </lord-icon>
      &nbsp; Edit Columns
    </button>
  </div>
</div>

<div class="row tabs-col">
  <div class="col-md-12">
    <label class="btn btn-primary btn-rectangular btn-lg mt-3 custom-label">Unassigned Jobs</label>
  </div>
</div>

<div class="row mt-2 tabs-col">
  <div class="col-12">

    <ngx-datatable [rows]="_displayedUnassignedTableData"
      class="bootstrap table-striped my-datatable table-responsive" [columnMode]="ColumnMode"
      [summaryRow]="false" [footerHeight]="40" [limit]="_pageSize" [pagination]="true" (page)="onPageChange($event)"
      [loadingIndicator]="true" [rowHeight]="40" [scrollbarH]="true" [scrollbarV]="false" [count]="_totalRecords" [externalPaging]="true" [pageSize]="_pageSize">

      <!-- <div class="assign-loader-overlay" *ngIf="_unassignLoading">
      <div id="loader">
        <div class="spinner"></div>
      </div>
    </div> -->
      <ngx-datatable-column *ngFor="let column of _tableColumns" [name]="column.header"
        [prop]="column.prop" class="thead " [style]="{ border: '1px solid black' }">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="value !== null">

            <ng-container *ngIf="column.header === 'Item ID' || column.prop === 'itemId'">
              <div draggable="true" class="drag-icon" (dragstart)="onDragStart($event, row)"
                (dragend)="onDragEnd($event, row)">
                {{ row.itemId || '-' }}
              </div>
            </ng-container>
            <ng-container *ngIf="column.header === 'POC' || column.prop === 'pocName'">
              <a [class.clickable-link]="row.pocName !== null"
                (click)="row.pocName !== null ? redirectToAccount(row.pocId) : null">
                {{ row.pocName || '-' }}
              </a>
            </ng-container>
            <ng-container
              *ngIf="column.header === 'Third Party' || column.prop === 'thirdPartyName'">
              <a [class.clickable-link]="row.thirdPartyName !== null"
                (click)="row.thirdPartyName !== null ? redirectToAccount(row.thirdPartyId) : null">
                {{ row.thirdPartyName || '-' }}
              </a>
            </ng-container>
            <ng-container
              *ngIf="column.header === 'Third Party POC' || column.prop === 'thirdPartyPocName'">
              <a [class.clickable-link]="row.thirdPartyPocName !== null"
                (click)="row.thirdPartyPocName !== null ? redirectToAccount(row.thirdPartyPocId) : null">
                {{ row.thirdPartyPocName || '-' }}
              </a>
            </ng-container>
            <ng-container *ngIf="column.header === 'Customer' || column.prop === 'customerName'">
              <a class="clickable-link" (click)="redirectToAccount(row.customerId)">
                {{row.customer || '-'}}</a>
            </ng-container>
            <ng-container *ngIf="column.header === 'Account'" class="clickable-link">
              <ng-container *ngIf="row.account === 'Individual'; else clickableLink">
                N/A
              </ng-container>
              <ng-template #clickableLink>
                <a class="clickable-link" (click)="redirectToAccount(row.accountId)">{{
                  row.account }}</a>
              </ng-template>
            </ng-container>
            <ng-container
              *ngIf="column.header === 'Account Manager' || column.prop === 'accountManager'">
              <a class="clickable-link" (click)="redirectToAccount(row.accountManagerId)">
                {{row.accountManager || '-'}}</a>
            </ng-container>
            <ng-container *ngIf="column.header === 'Origin' || column.prop === 'origin'">
              {{ row.origin || '-'}}
            </ng-container>
            <ng-container *ngIf="column.header === 'Destination' || column.prop === 'destination'">
              {{ row.destination || '-'}}
            </ng-container>
            <ng-container *ngIf="column.header === 'Email' || column.prop === 'email'">
              {{ row.email || '-'}}
            </ng-container>
            <ng-container *ngIf="column.header === 'Phone' || column.prop === 'phone'">
              {{ row.phone || '-'}}
            </ng-container>
            <ng-container *ngIf="column.header === 'Type' || column.prop === 'type'">
              {{ row.itemType || '-'}}
            </ng-container>
            <ng-container *ngIf="column.header === 'Move Type' || column.prop === 'moveType'">
              {{ row.moveTypeLabel || '-'}}
            </ng-container>
            <ng-container
              *ngIf="column.header === 'Move Category' || column.prop === 'moveCategory'">
              {{ row.moveCategoryLabel || '-'}}
            </ng-container>
            <ng-container
              *ngIf="column.header === 'Transport Type' || column.prop === 'moveTransport'">
              {{ row.moveTransportLabel || '-'}}
            </ng-container>
            <ng-container *ngIf="column.header === 'Survey Date' || column.prop === 'surveyDate'">
              {{ row.surveyDate || '-' }}
            </ng-container>
            <ng-container *ngIf="column.header === 'Pack Date' || column.prop === 'packagingDate'">
              {{ row.packagingDate || '-' }}
            </ng-container>
            <ng-container
              *ngIf="column.header === 'Delivery Date' || column.prop === 'deliveryDate'">
              {{ row.deliveryDate || '-' }}
            </ng-container>

            <ng-container *ngIf="column.header === 'Status'">
              <div class="status-text">{{ enummappingService.mapProcessStatus(value) || '-'}}</div>
            </ng-container>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>