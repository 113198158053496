import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { MovdService } from '../../services/movd.service';
import { Shift } from '../../models/shiftTime.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import { allUnAssignedColumns, AssignedDispatchItemByIdDTO, AssignedDispatchItemDTO, columnToProp, DispatchBoardStatsDTO, UnassignDispatchLogResponse, UnassignedDispatchItemDTO } from './dispatch-board.model';
import { GeneralService } from 'src/services/general.service';
import { EnumMappingService } from '../../services/movd.enum.service';
import { NavigationExtras, Router } from '@angular/router';
import { NgbDate, NgbDateStruct, NgbModal, NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, forkJoin, Subject } from 'rxjs';
import { eAssignmentType, UserAssignment } from '../../models/userAssignment.model';
import { AppComponent } from 'src/app/app.component';
import { InventoryService } from 'src/services/inventory.service';
import { eLabourType, Labour } from '../../models/labour.model';
import { eMaterialType } from '../../models/packing-material.model';
import { eVehicleType } from '../../models/vehicle.model';
import { eInventoryType, InventoryAssignment } from '../../models/inventoryAssignment';
import { Breadcrumb, BreadcrumbService } from 'src/services/breadcrumb.service';

@Component({
  selector: 'app-dispatch-board',
  templateUrl: './dispatch-board.component.html',
  styleUrls: ['./dispatch-board.component.scss'],
  providers: [DatePipe]
})
export class DispatchBoardComponent implements OnInit {

  @ViewChild('ngx-datatable') table?: DatatableComponent;
  @ViewChild('detailModal') detailModal: any;
  @ViewChild('assignInventoryModal') assignInventoryModal: any

  columnToggleSubject = new Subject<void>();
  eAssignmentType = eAssignmentType;
  eLabourType = eLabourType;
  eVehicleType = eVehicleType;
  ePackingMaterialType = eMaterialType;

  _selectedDateShow: NgbDate | null = null;

  computeCellKey(time: any, userId: any): string {
    return `${time}-${userId}`;
  }

  _activeTabId = 1;
  _companyUsers: any[] = [];
  _defaultRoles: string[] = ['Survey Manager', 'Packing Manager'];
  _allRoles: string[] = [];
  _selectedRoles: { [role: string]: boolean } = {};
  _shift = new Shift();
  _durationHours: number = 0;
  _timeSlots: { time: string }[] = [];

  _modules: any[] = [
    { name: 'MOVE' },
    { name: 'SURVEY' },
    { name: 'PACKING' },
    { name: 'DELIVERY' }
  ];

  _statuses: any[] = [
    { name: 'New' },
    { name: 'Inprogress' },
    { name: 'Completed' },
    { name: 'Cancelled' },
    { name: 'Total' }
  ];

  _dispatchStats: DispatchBoardStatsDTO[] = [];
  transformedData: any[] = [];

  _selectedDate: string | null | Date = null;
  selectedPeriod: string = 'day'; // Default to 'day'
  isDragging: boolean = false;
  draggedItem: string | null = null;

  _selectedColor = "#7987a1";
  _timeSlot: string | null = null;
  _inventorySection: boolean = false;

  _cellItemIds: { [key: string]: any } = {};

  _userId: number = 0;
  _refId: number = 0;
  _eAssignmentType = null;
  _currentUserId: number = 0;
  _userAssignment = new UserAssignment();
  _inventoryAssignment = new InventoryAssignment();
  _assignedItemById = new AssignedDispatchItemByIdDTO();
  _assignmentType: eAssignmentType = eAssignmentType.Lead;
  _isLoading: boolean = false;
  _unassignLoading: boolean = false;
  highlightedCell: { row: any, column: any } | null = null;

  //inventory Add
  _ngbModalRef: NgbModalRef | undefined;
  _assignedInventory: any[] = [];
  _labourData: any[] = [];
  _vehicleData: any[] = [];
  _packingMaterialData: any[] = [];
  _labourList: any[] = [];
  _vehicleList: any[] = [];
  _packingMaterialList: any[] = [];
  _selectedLabourIds: number[] = [];
  _selectedVehicleIds: number[] = [];
  _selectedpackingMaterialIds: number[] = [];

  // unassigned dispatch items

  _unassignedModel = new UnassignedDispatchItemDTO();
  _unassignedTableData: any[] = [];
  _displayedUnassignedTableData: any[] = [];
  ColumnMode = ColumnMode.force;
  _modalRef: NgbModalRef | undefined;
  isPopoverOpen: boolean = false;
  popoverVisible = false;

  // assigned dispatch items
  _assignedDispatchData: AssignedDispatchItemDTO[] = [];

  _allColumns: string[] = allUnAssignedColumns;
  _defaultColumns: string[] = ['Item ID', 'Customer', 'Account', 'Origin', 'Destination', 'Third Party'];
  _tableColumns: any[] = [];
  _selectedColumns: { [key: string]: boolean } = {};

  _searchTerm: string = '';
  _currentPage = 1;
  _pageSize = 10;
  _totalRecords = 0;

  // permissions
  _userRole: string | null = null;
  _crmContactReadPermission = false;
  breadcrumbs: Breadcrumb[] = [];
  selectPeriod(period: string) {
    this.selectedPeriod = period;
    // You can handle additional logic here based on the selected period
  }

  // for auto scroll
  @ViewChild('containerRef', { static: false }) containerRef!: ElementRef;

  constructor(
    private _authService: AuthService,
    private _movdService: MovdService,
    private _inventoryService: InventoryService,
    private datePipe: DatePipe,
    public generalService: GeneralService,
    public enummappingService: EnumMappingService,
    private _router: Router,
    private _modalService: NgbModal,
    private _appComponent: AppComponent,
    private breadcrumbService: BreadcrumbService,
    private cdr: ChangeDetectorRef
  ) {
    this._selectedDate = this.getCurrentDate();
    // console.log("selected date", this._selectedDate);

    this._currentUserId = Number(localStorage.getItem('currentUserId'));
    this.columnToggleSubject.pipe(
      debounceTime(10) // Adjust this value as needed toggleColumnVisibility() will only be called once per 300 milliseconds 
    ).subscribe(() => this.toggleColumnVisibility());

    this._userRole = localStorage.getItem("role");
  }

  async ngOnInit() {

    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Dispatch Board', url: '/dispatch-board' },

    ]);


    // this._isLoading = true;

    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    await this.getAllData();

    this._crmContactReadPermission = permissions.includes('CRM - Contacts.Read') || this._userRole === 'CompanyAdmin';

    // Load selected columns for this section from localStorage or initialize with defaults
    const storedSelectedColumns = localStorage.getItem('selectedUnassignedColumnsForThisSection');
    if (storedSelectedColumns) {
      this._selectedColumns = JSON.parse(storedSelectedColumns);
    } else {
      for (const column of this._defaultColumns) {
        this._selectedColumns[column] = true;
      }
    }
    // this._isLoading = false;

  }

  getAllData(): Promise<void> {
    this._isLoading = true
    return new Promise<void>((resolve, reject) => {
      forkJoin([
        this.getCompanyUsers(),
        this.getDispatchBoardStats(),
        this.getShiftTime(),
        this.getUnassignedDispatchItems(),
        this.getAssignedDispatchItems(),
      ]);
      resolve();
      (error: any) => {
        reject(error);
        this._isLoading = false
      }
    });
  }
  onChangeTab(tabIndex: number) {
    this._activeTabId = tabIndex;
  }

  onDragStart(event: DragEvent, row: any) {
    if (event.dataTransfer) {
      this.draggedItem = row;
      event.dataTransfer.setData('text', row.id);
      event.dataTransfer.setData('itemId', row.itemId);
      event.dataTransfer.setData('id', row.id);
      event.dataTransfer.setData('type', row.type);
      event.dataTransfer.setData('status', row.status);
      const dragImage = document.createElement('div');
      dragImage.textContent = row.itemId;
      dragImage.style.position = 'absolute';
      dragImage.style.border = '1px solid #7987a1';
      dragImage.style.backgroundColor = '#adb5bd';
      dragImage.style.padding = '5px 10px';
      dragImage.style.boxSizing = 'border-box';
      dragImage.style.textAlign = 'center';
      dragImage.classList.add('drag-image');
      document.body.appendChild(dragImage);
      event.dataTransfer.setDragImage(dragImage, 0, 0);
    }
  }
  onDragOver(event: DragEvent, row: any, column: any, user: any) {
    if (event.dataTransfer) {
      const cellKey = this.computeCellKey(row.time, user.id);
      // Check if the cell already has an item
      if (this._cellItemIds[cellKey] && this._cellItemIds[cellKey].itemDropped) {
        event.dataTransfer.dropEffect = 'none'; // This will change the cursor
      } else {
        this.isDragging = true;
        event.preventDefault();
      }
    }
  }


  onDrop(event: DragEvent, row: any, column: any, user: any) {
    this._userAssignment = new UserAssignment();
    event.preventDefault();
    if (event.dataTransfer) {
      const itemId = event.dataTransfer.getData('itemId');
      const id = Number(event.dataTransfer.getData('id'));
      const assignmentType: string = event.dataTransfer.getData('type');
      const status = Number(event.dataTransfer.getData('status'));

      const { type, refId } = extractTypeAndId(itemId);
      const cellKey = this.computeCellKey(row.time, user.id);
      if (this._cellItemIds[cellKey] && this._cellItemIds[cellKey].itemDropped) {
        return;
      }
      // Update _cellItemIds with the itemId
      this._cellItemIds[cellKey] = itemId;
      // Update the row's cell data with the itemId
      if (this._cellItemIds[cellKey]) {
        this._cellItemIds[cellKey] = {
          itemId: itemId,
          itemDropped: true,
          status: status,
          id: refId,
          type: type
        };
      }

      this.cdr.detectChanges();

      this._assignmentType = eAssignmentType[assignmentType as keyof typeof eAssignmentType];
      this._userId = user.id;
      this._refId = id;
      const startTime = row.time;
      const endTime = (parseInt(startTime.split(':')[0]) + 1).toString().padStart(2, '0') + ':00';
      this._timeSlot = `${startTime} - ${endTime}`;

      this._userAssignment.assignedTimeSlot = this._timeSlot;
      this._userAssignment.userId = this._userId;
      this._userAssignment.refId = this._refId;
      this._userAssignment.assignmentType = type;
      this._userAssignment.assignedDate = this._selectedDate;
      this._userAssignment.createdBy = this._currentUserId;

      this._movdService.saveUserAssignments(this._userAssignment).subscribe({
        next: response => {
          this._appComponent.showSuccessSwal("Assigned", "Task");
          this.getUnassignedDispatchItems();
        },
        error: (error: any) => {
          // Handle error
          console.error("error in saving user assignment", error);
        }
      });
    }
  }

  displayAssignedData(data: AssignedDispatchItemDTO[]) {
    // Iterate over each item in the data array
    for (const item of data) {

      let timeSlot = item?.assignedTimeSlot; // e.g., "08:00 - 09:00"
      if (timeSlot) {
        let parts = timeSlot.split(' - '); // splits the string into ["08:00", "09:00"]
        let startTime = parts[0]; // gets the first part, which is the start time

        // Find the corresponding cell in the table
        const cellKey = this.computeCellKey(startTime, item.userId);  // You need to adjust this line to match your actual data structure

        const status = item.status;
        const id = item.id;
        const type = item.type;

        this._cellItemIds[cellKey] = item.itemId;
        // Update the row's cell data with the itemId
        if (this._cellItemIds[cellKey]) {
          this._cellItemIds[cellKey] = {
            itemId: item.itemId,
            itemDropped: true,
            status: status,
            id: id,
            type: type
          };
        }
      }
    }
  }

  getCellData(row: any, userId: any) {
    const cellKey = this.computeCellKey(row.time, userId);

    // Check if the cell exists in _cellItemIds
    if (this._cellItemIds && this._cellItemIds[cellKey]) {
      // If the cell exists in _cellItemIds, return the cell data
      return this._cellItemIds[cellKey];
    } else {
      // If the cell doesn't exist in _cellItemIds, check row.cells
      const cellId = `${row.time}-${userId}`;
      if (row.cells && row.cells[cellId]) {
        // If the cell exists in row.cells, return the cell data
        return row.cells[cellId];
      } else {
        // If the cell doesn't exist in _cellItemIds or row.cells, return null
        return null; //row.cells && row.cells[cellId]
      }
    }
  }

  async removeItem(event: Event, row: any, column: any, userId: any) {
    event.stopPropagation(); // Prevent triggering other events
    const cellKey = `${row.time}-${userId}`;

    // Show confirmation dialog
    let text = 'You are about to remove the user assigned Task.';
    let confirmButtonText = 'Yes';
    let confirmed = await this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    // Check if removal is confirmed
    if (confirmed === true) {
      // Additional logic if removal is confirmed

      var assingnedItem = this._cellItemIds[cellKey];
      const { type, refId } = extractTypeAndId(assingnedItem.itemId);

      this._userAssignment.userId = userId;
      this._userAssignment.refId = refId;
      this._userAssignment.assignmentType = type;
      this._userAssignment.assignedDate = null;
      this._userAssignment.isDeleted = true;

      // console.log("save user assignment", this._userAssignment);

      // Save the user assignment
      this._movdService.saveUserAssignments(this._userAssignment).subscribe({
        next: response => {
          this._appComponent.showSuccessSwal("Unassigned", "Task");
          this.getUnassignedDispatchItems();
        },
        error: (error: any) => {
          // Handle error
          console.error("error in saving user assignment", error);
        }
      });

      // Remove the cell from _cellItemIds
      if (this._cellItemIds && this._cellItemIds[cellKey]) {
        delete this._cellItemIds[cellKey];
      }
    }
    else {
      return;
    }
  }


  onDragEnd(event: DragEvent, row: any) {
    const index = this._displayedUnassignedTableData.indexOf(row);
    if (index > -1) {
      // this._displayedUnassignedTableData.splice(index, 1);
    }
    this.isDragging = false;
    this.draggedItem = null;
    const dragImage = document.querySelector('.drag-image');

    // Remove the dragImage element from the DOM
    if (dragImage) {
      document.body.removeChild(dragImage);
    }
  }

  onDragEnter(event: DragEvent, row: any, column: any) {
    column.isHighlighted = true;
  }

  onDragLeave(event: DragEvent, row: any, column: any) {
    column.isHighlighted = false;
  }


  // internationl time
  // getCurrentDate(): string {
  //   return new Date().toISOString().split('T')[0];
  // }

  // local time
  getCurrentDate() {
    const now = new Date();
    const gmtPlus5Date = new Date(now.getTime() + 5 * 60 * 60 * 1000); // add 5 hours in milliseconds
    return gmtPlus5Date.toISOString().split('T')[0];
  }

  getCompanyUsers(): void {
    // this._isLoading = true;
    this._authService.getUsersOfCompany().subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          // Filter users to include only those with at least one default role
          this._companyUsers = data.filter((item: any) => {
            const roles = item.roleNames?.split(',').map((role: any) => role.trim()) || [];
            return roles.some((role: any) => this._defaultRoles.includes(role));
          });

          // Log all role names for each user
          this._companyUsers.forEach((user: any) => {
            console.log(`User: ${user.customerName}, Role Names: ${user.roleNames}`);
          });
        } else {
          this._companyUsers = [];
        }
      },
      error => {
        console.error("Error getting company users", error);
      }
    );
  }

  loadAllRoles(): void {
    this._authService.getUsersOfCompany().subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          // Extract all roles from the user data
          const allRolesSet = new Set<string>();

          data.forEach((item: any) => {
            const roles = item.roleNames?.split(',').map((role: any) => role.trim()) || [];
            roles.forEach((role: any) => allRolesSet.add(role));
          });

          this._allRoles = Array.from(allRolesSet);

          // Initialize selected roles with default roles checked
          this._allRoles.forEach(role => {
            this._selectedRoles[role] = this._selectedRoles[role] !== undefined ? this._selectedRoles[role] : this._defaultRoles.includes(role);
          });

          // Initially filter users based on the selected roles
          this.filterUsers();
        } else {
          this._companyUsers = [];
        }
      },
      error => {
        console.error("Error getting company users", error);
      }
    );
  }

  filterUsers(): void {
    this._authService.getUsersOfCompany().subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          // Filter users based on selected roles
          this._companyUsers = data.filter((item: any) => {
            const roles = item.roleNames?.split(',').map((role: any) => role.trim()) || [];
            return roles.some((role: any) => this._selectedRoles[role]);
          });

          this._companyUsers.forEach((user: any) => {
            console.log(`User: ${user.customerName}, Role Names: ${user.roleNames}`);
          });
        } else {
          this._companyUsers = [];
        }
      },
      error => {
        console.error("Error getting company users", error);
      }
    );
  }

  onRoleToggle(role: string, isChecked: boolean): void {
    this._selectedRoles[role] = isChecked;
    this.filterUsers(); // Refresh the user list based on selected roles
  }

  openRoleModal(roleModal: TemplateRef<any>) {
    this.loadAllRoles(); // Ensure roles are loaded before opening the modal
    this._modalService.open(roleModal);
  }

  getLabourData() {
    this._inventoryService.getLabour().subscribe({
      next: (data: any) => {
        this._labourData = data;
      },
      error: (error: any) => {
        console.error("error getting labour data", error);
      }
    });
  }

  inventorySection() {
    this._inventorySection = !this._inventorySection;
  }

  getVehicleData() {
    this._inventoryService.getVehicle().subscribe({
      next: (data: any) => {
        this._vehicleData = data;
      },
      error: (error: any) => {
        console.error("error getting labour data", error);
      }
    });
  }

  getPackingMaterialData() {
    this._inventoryService.getPackingMaterial().subscribe({
      next: (data: any) => {
        this._packingMaterialData = data;
      },
      error: (error: any) => {
        console.error("error getting labour data", error);
      }
    });
  }

  getDispatchBoardStats() {
    // this._isLoading = true;
    this._movdService.getDispatchBoardStats(this._selectedDate).subscribe(
      (data: any) => {
        // console.log("dispatch stats", data);
        this._dispatchStats = data && data.length > 0 ? data.filter((item: DispatchBoardStatsDTO) => item.type !== 0) : [];
      },
      error => {
        console.error("Error getting dispatch data", error);
      }
    );
  }

  getUnassignedDispatchItems() {
    this._unassignLoading = true;
    this._movdService.getUnassignedDispatchItems(this._currentPage, this._pageSize, this._searchTerm).subscribe({
      next: (data: UnassignDispatchLogResponse) => {
        // console.log('unassigned data', data);

        this._tableColumns = Object.keys(this._unassignedModel)
          .filter((key) =>
            key !== 'id' &&
            key !== 'customerId' &&
            key !== 'accountId' &&
            key !== 'pocId' &&
            key !== 'thirdPartyId' &&
            key !== 'thirdPartyPocId'

            //    key === 'itemId'
            // || key === 'customer'
            // || key === 'account'
            // || key === 'pocName'
            // || key === 'accountName'
            // || key === 'thirdPartyName'
            // || key === 'thirdPartyPocName'
            // || key === 'accountManager'
            // || key === 'creationDate'
            // || key === 'status'
            // || key === 'origin'
            // || key === 'destination'
            // || key === 'moveType'
            // || key === 'moveCategory'
            // || key === 'moveTransport'
            // || key === 'packagingDate'
            // || key === 'deliveryDate'
            // || key === 'email'
            // || key === 'phone'
            // || key === 'moveTypeLabel'
            // || key === 'moveTransportLabel'
            // || key === 'moveCategoryLabel'
          )
          .map((key) => {
            let header: string;
            let prop: string = key;
            switch (key) {
              case 'itemId':
                header = 'Item ID';
                break;
              case 'account':
                header = 'Account';
                break;
              case 'customer':
                header = 'Customer';
                break;
              case 'email':
                header = 'Email';
                break;
              case 'phone':
                header = 'Phone';
                break;
              case 'status':
                header = 'Status';
                break;
              case 'origin':
                header = 'Origin';
                break;
              case 'destination':
                header = 'Destination';
                break;
              case 'moveType':
                header = 'Move';
                break;
              case 'moveCategory':
                header = 'Move Category';
                break;
              case 'moveTransport':
                header = 'Transport Type';
                break;
              case 'pocName':
                header = 'POC';
                break;
              case 'thirdPartyName':
                header = 'Third Party';
                break;
              case 'thirdPartyPocName':
                header = 'Third Party POC';
                break;
              case 'packagingDate':
                header = 'Pack Date';
                break;
              case 'deliveryDate':
                header = 'Delivery Date';
                break;
              case 'accountManager':
                header = 'Account Manager';
                break;
              default:
                header = key; // Use the original key if no specific header is defined
                break;
            }
            return { header, prop };
          });

        if (data) {
          this._totalRecords = data.totalRecords;
          this.createUnassignedTableData(data.unassignedDispatchItems);
          this._unassignLoading = false;
        } else {
          this._displayedUnassignedTableData = [];
          this._currentPage = 0;
        }
      },
      error: (error) => {
        console.error("error getting dashboard items", error);
        this._isLoading = false;
      }
    });
  }

  getAssignedDispatchItems() {
    this._cellItemIds = {};

    this._isLoading = true;
    // console.log("selected date", this._selectedDate);

    this._movdService.getAssignedDispatchItems(this._selectedDate).subscribe({
      next: (data: any[]) => {
        // console.log("get Assigned Dispatch Items", data);

        this._assignedDispatchData = data;

        this.displayAssignedData(data);
        this._isLoading = false;

      },
      error: (error) => {
        this._isLoading = false;
        console.error("error getting assigned dashboard items", error);
      }
    });
  }

  onClickPopupShow(itemId: any, userId: number) {
    this._selectedLabourIds = [];
    this._selectedVehicleIds = [];
    this._selectedpackingMaterialIds = [];

    const { type, refId } = extractTypeAndId(itemId);

    this.getAssignedDispatchItemById(itemId);
    this.getLabourData();
    this.getVehicleData();
    this.getPackingMaterialData();
    this.getAssignedInventory(refId, userId, type);
  }

  getAssignedDispatchItemById(itemId: any) {
    const { type, refId } = extractTypeAndId(itemId);

    this._movdService.getAssignedDispatchItemById(refId, type).subscribe({
      next: (data: any) => {
        if (data) {
          //console.log("get Assigned Dispatch Item by Id", data);
          this._assignedItemById = data;
          // this._isLoading = false;
        }
      },
      error: (error) => {
        // this._isLoading = false;
        console.error("error getting assigned dashboard item", error);
      }
    });
  }

  getLabel(item: any): string {
    return `${item.name} - ${item.type}`;
  }

  createUnassignedTableData(data: any) {
    if (data.length > 0) {
      data.forEach((data: any) => {
        data.surveyDate = data.surveyDate ? this.generalService.formatDate(data.surveyDate, null) : this.generalService.formatDate(data.surveryDate, null);
        data.deliveryDate = data.deliveryDate ? this.generalService.formatDate(data.deliveryDate, null) : this.generalService.formatDate(data.deliveryDate, null);
        data.packagingDate = data.packagingDateFrom && data.packagingDateTo ? this.generalService.formatDate(data.packagingDateFrom, null) + ' - ' + this.generalService.formatDate(data.packagingDateTo, null) : this.generalService.formatDate(data.packagingDateFrom, null);

        data.moveTypeLabel = this.enummappingService.mapMoveType(data.moveType);
        data.moveCategoryLabel = this.enummappingService.mapMoveCategoryType(data.moveCategory);
        data.moveTransportLabel = this.enummappingService.mapTransportType(data.moveTransport);
        data.deliveryDateFormatted = this.generalService.formatDate(data.deliveryDate, null);
        data.surveyDateFormatted = this.generalService.formatDate(data.surveyDate, null);

        data.itemType = this.enummappingService.mapAssignmentType(data.type)
      });
    }
    this._unassignedTableData = data;
    this._displayedUnassignedTableData = data;
    // Update tableColumns to include new combined date columns
    this.toggleColumnVisibility();
    // this._isLoading = false;
  }

  toggleColumnVisibility() {
    this._tableColumns = this._allColumns
      .filter(column => this._selectedColumns[column])
      .map(column => ({ header: column }));

    localStorage.setItem('selectedUnassignedColumns', JSON.stringify(this._selectedColumns));
    localStorage.setItem('selectedUnassignedColumnsForThisSection', JSON.stringify(this._selectedColumns));
  }

  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  performSearch() {
    // console.log("selected columns", this._tableColumns);

    // Check if the search term is empty
    if (this._searchTerm === '') {
      // Reset the displayed leads list to the original data
      this._displayedUnassignedTableData = this._unassignedTableData;
    } else {
      // Filter the original data array based on the search term
      const filteredData = this._unassignedTableData.filter((row: any) => {
        // Convert all values of the SELECTED COLUMNS to string and check if any value contains the search term
        return (Object.keys(this._selectedColumns) as Array<keyof typeof columnToProp>).some((column) => {
          if (this._selectedColumns[column]) {
            const prop = columnToProp[column];
            const value = row[prop];
            if (typeof value === 'string') {
              return value.toLowerCase().includes(this._searchTerm.toLowerCase());
            }
          }
          return false; // Skip non-string values or non-selected columns
        });
      });
      this._displayedUnassignedTableData = filteredData;
    }
    if (this.table) {
      this.table.offset = 0;
    }
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }

  openColumnModal(content: TemplateRef<any>) {
    this._modalService.open(content, { size: 'md' }).result.then((result) => {

    }).catch((res) => { });
  }

  openAssignInventoryModal(template: TemplateRef<any>, popover: NgbPopover, type: any, refId: any, row: any, userId: any) {
    this._inventoryAssignment = new InventoryAssignment();
    this._refId = refId;
    this._eAssignmentType = type;
    this._timeSlot = row.time;
    this._userId = userId;
    popover.close();
    // this._inventoryAssignment = new InventoryAssignment();
    this.getAssignedInventory(this._refId, this._userId, this._assignmentType);
    this._ngbModalRef = this._modalService.open(this.assignInventoryModal, { size: 'md', });
  }

  onPageChange(event: any): void {
    this._unassignLoading = true;
    this._currentPage = event.offset + 1;
    this.getUnassignedDispatchItems();
  }
  onSearch(): void {
    this._currentPage = 1;
    this.getUnassignedDispatchItems();
  }
  assignInventory() {
    this._inventoryAssignment.userId = this._userId;
    this._inventoryAssignment.assignedTimeSlot = this._timeSlot;
    this._inventoryAssignment.assignmentType = this._eAssignmentType;
    this._inventoryAssignment.refId = this._refId;
    this._inventoryAssignment.labourIds = this._selectedLabourIds;
    this._inventoryAssignment.vehicleIds = this._selectedVehicleIds;
    this._inventoryAssignment.packingMaterialIds = this._selectedpackingMaterialIds;

    this._inventoryService.saveInventoryAssignment(this._inventoryAssignment).subscribe({
      next: (data: any) => {

        this._appComponent.showSuccessSwal("Assigned", "Inventory");
        this.getAssignedInventory(this._refId, this._userId, this._assignmentType);

      },
      error: (error: any) => {
        console.error('Error assigning inventory', error);
      }
    });
  }

  getAssignedInventory(refId: number, userId: number, assignmentType: number) {
    this._labourList = [];
    this._vehicleList = [];
    this._packingMaterialList = [];
    this._inventoryService.getInventoryAssignment(refId, userId, assignmentType).subscribe({
      next: (data: any) => {

        if (data && data.length > 0) {
          data.forEach((item: InventoryAssignment) => {
            switch (item.inventoryType) {
              case eInventoryType.Labour:
                this._labourList.push(item);
                break;
              case eInventoryType.Vehicle:
                this._vehicleList.push(item);
                break;
              case eInventoryType.PackingMaterial:
                this._packingMaterialList.push(item);
                break;
              default:
                this._assignedInventory.push(item);
            }
          });
          this.getLabourData();

          this._selectedLabourIds = [];
          this._selectedVehicleIds = [];
          this._selectedpackingMaterialIds = [];

          // Update _labourList with matched data or set to empty if no match found
          this._labourList = this._labourList.length > 0 && this._labourData.length > 0 ? this._labourList.map(item => {
            const matchedData = this._labourData.find((data: Labour) => data.id === item.inventoryId);
            if (matchedData) {
              if (!this._selectedLabourIds.includes(matchedData.id)) {
                this._selectedLabourIds.push(matchedData.id);
              }
              return matchedData;
            }
            return null;
          }).filter(item => item !== null) : [];

          // Update _vehicleList with matched data or set to empty if no match found
          this._vehicleList = this._vehicleList.length > 0 && this._vehicleData.length > 0 ? this._vehicleList.map(item => {
            const matchedData = this._vehicleData.find(data => data.id === item.inventoryId);
            if (matchedData) {
              if (!this._selectedVehicleIds.includes(matchedData.id)) {
                this._selectedVehicleIds.push(matchedData.id);
              }
              return matchedData;
            }
            return null;
          }).filter(item => item !== null) : [];

          // Update _packingMaterialList with matched data or set to empty if no match found
          this._packingMaterialList = this._packingMaterialList.length > 0 && this._packingMaterialData.length > 0 ? this._packingMaterialList.map(item => {
            const matchedData = this._packingMaterialData.find(data => data.id === item.inventoryId);
            if (matchedData) {
              if (!this._selectedpackingMaterialIds.includes(matchedData.id)) {
                this._selectedpackingMaterialIds.push(matchedData.id);
              }
              return matchedData;
            }
            return null;
          }).filter(item => item !== null) : [];
        }
      },
      error: (error: any) => {
        console.error('Error getting assigned inventory', error);
      }
    });
  }

  getShiftTime() {
    this._movdService.getShift().subscribe({
      next: (data: any) => {
        if (data) {
          this._shift = data;
          this._durationHours = this.calculateDuration(this._shift.startTime, this._shift.endTime);
          this.generateTimeSlots();
        }
      },
      error: (error: any) => {
        console.error('Error getting shift time', error);
      }
    });
  }

  calculateDuration(startTime: string, endTime: string): number {
    const startDate = new Date('2000-01-01T' + startTime);
    const endDate = new Date('2000-01-01T' + endTime);
    const durationMs = endDate.getTime() - startDate.getTime();
    const durationHours = durationMs / (1000 * 60 * 60);
    return durationHours;
  }
  generateTimeSlots(): void {
    const startHour = parseInt(this._shift.startTime.split(':')[0], 10);
    const endHour = parseInt(this._shift.endTime.split(':')[0], 10);
    this._timeSlots = [];
    for (let i = startHour; i <= endHour; i++) {
      const hour = ('0' + i).slice(-2);
      this._timeSlots.push({ time: `${hour}:00` });
    }
  }

  redirectToAccount(id: any) {
    if (this._crmContactReadPermission === false) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the profile', '');
      return;
    }

    if (!id) {
      return;
    }

    const text = 'company';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this._router.navigate(['/profile'], navigationExtras);
  }

  onDateSelect(date: NgbDate) {
    this._selectedDate = this.convertNgbDateToDate(date);
    this.onChange();
    this.getAssignedDispatchItems();
    this.getDispatchBoardStats();
  }

  convertNgbDateToDate(ngbDate: any): string | null {
    if (ngbDate !== null) {
      const year = ngbDate.year;
      const month = String(ngbDate.month).padStart(2, '0');
      const day = String(ngbDate.day).padStart(2, '0');
      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }

  convertDateToNgbDate(date: string | Date | null): NgbDate | null {
    if (date) {
      let jsDate = new Date(date);
      return new NgbDate(
        jsDate.getFullYear(),
        jsDate.getMonth() + 1,   // NgbDate month is 1-based
        jsDate.getDate() //+ 1
      );
    } else {
      return null;
    }
  }

  isHighlighted(date: NgbDateStruct) {
    let dateToCompare: NgbDate | null;
    dateToCompare = this.convertDateToNgbDate(this._selectedDate);
    if (dateToCompare) {
      return (
        date.year === dateToCompare.year &&
        date.month === dateToCompare.month &&
        date.day === dateToCompare.day
      );
    }
    return false;
  }

  onChange() {
    this._selectedDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._selectedDate, null));
  }

  popoverOpened() {
    // console.log("popover opened");
    // this.getAssignedInventory();
  }
}

function extractTypeAndId(itemId: string) {
  const parts = itemId.split('-');
  let type: eAssignmentType;
  const refId = Number(parts[1]);

  switch (parts[0]) {
    case 'L':
      type = eAssignmentType.Lead;
      break;
    case 'M':
      type = eAssignmentType.Move;
      break;
    case 'S':
      type = eAssignmentType.Survey;
      break;
    case 'P':
      type = eAssignmentType.Packing;
      break;
    case 'D':
      type = eAssignmentType.Delivery;
      break;
    default:
      throw new Error("Unknown ItemType: " + parts[0]);
  }

  return { type, refId };
}
