<div class="center">
  <h4 class="tab-heading">SURVEY</h4>
  <hr>
</div>
<ng-template #basicModal let-modal>
  <div class="modal-dialog custom-modal-dialog">
    <div class="modal-body">
      <app-survey-summary [leadDetailId]="_leadDetail.id"></app-survey-summary>
    </div>
  </div>
</ng-template>
<ng-template #addAreaModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add New Area</h4>
  </div>
  <div class="modal-body">
    <label for="areaName fw-bold">Area Name</label>
    <input type="text" class="form-control" id="areaName" placeholder="Enter Area Name to Add"
      [(ngModel)]="_surveyPlace.title" />
    <div *ngIf="!_surveyPlace.title || (_surveyPlace.title && _surveyPlace.title.trim().length === 0)"
      class="error-message error" style="color: red">* required </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveArea(modal)">
      <i class="mdi mdi-content-save"> </i> &nbsp;Save
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.dismiss()">
      <i class="mdi mdi-close"></i>&nbsp;Close
    </button>
  </div>
</ng-template>
<div class="container">
  <div class="row justify-content-end">
    <div class="col-md-12">
      <button type="button" class="btn btn-primary btn-sm float-end " (click)="generatePdf('Download');">
        Export Survey &nbsp;<i class="mdi mdi-file-export"></i>
      </button>
      <!-- <i class="mdi mdi-file-pdf"> </i> &nbsp; -->
      <button class="btn btn-primary btn-sm float-end export-button" (click)="generatePdf('View');">
        View Survey &nbsp;<i class="mdi mdi-file-eye"></i>
      </button>
    </div>
    <!-- </div> -->
  </div>

  <div class="row mt-3">
    <div class="col-md-2">
      <div class="survey-label">Area</div>
    </div>
    <div class="col-md-4">
      <ng-select [bindValue]="'id'" [searchable]="true" [(ngModel)]="_selectedSurveyPlaceId" [items]="_surveyPlacesList"
        [placeholder]="'Please Select an area...'" [bindLabel]="'title'" (ngModelChange)="onSurveyPlaceChange($event);"
        [disabled]="disabled === true">
      </ng-select>

    </div>
    <div class="col-md-5">
      <ng-container *ngIf="_createPermission">
        <button class="btn btn-primary btn-icon-text" (click)="openAreaModal(addAreaModal)"
          [disabled]="disabled === true">
          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
            colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
          </lord-icon>&nbsp;Add Area
        </button>
      </ng-container>
    </div>
    <div class="col-md-1 text-right">
      <!-- <button class="btn btn-sm me-2 inititate-move">Edit</button> -->
    </div>
  </div>

  <div class="row mt-2 mb-5">
    <div *ngIf="_dbSurveyItemsByPlace.length === 0 && !_selectedSurveyPlaceId">
      No Area Selected.
    </div>
    <div *ngIf="_dbSurveyItemsByPlace.length === 0 && _selectedSurveyPlaceId">
      No Items available for this area.
    </div>
    <div *ngIf="_dbSurveyItemsByPlace.length > 0" class="item-container">
      <div *ngFor="let item of _dbSurveyItemsByPlace" class="mb-2 me-1">
        <button class="btn btn-outline-primary btn-sm p-2" (click)="addItemToTable(item)"
          [disabled]="disabled === true">
          {{ item.title }}
        </button>
      </div>
    </div>
  </div>

  <!-- Column Headers -->
  <div class="row mt-2">
    <div class="col-md-3">
      <label>Item</label>
    </div>
    <div class="col-md-2">
      <label>Quantity</label>
    </div>
    <div class="col-md-2">
      <label>Volume ({{ _volumeUnit }})</label>
    </div>
    <div class="col-md-2">
      <label>Weight ({{ _weightUnit }})</label>
    </div>
    <div class="col-md-3">
      <label>Dimension ({{ _dimensionUnit }})</label>
    </div>
    <div class="col-md-1">
      <!-- Empty column for alignment -->
    </div>
  </div>

  <!-- Data Rows -->
  <ng-container *ngIf="getActiveAreaItems() > 0; else emptyList">
    <ng-container *ngFor="let surveyPlace of getDistinctSurveyPlaces(); let i = index">
      <div class="row mt-2">
        <div class="col-md-12">
          <h5>{{surveyPlace}}</h5>
        </div>
      </div>
      <div class="row mt-2" *ngFor="let item of getAreaItemsBySurveyPlace(surveyPlace); let j = index">
        <ng-container *ngIf="!item.isDeleted">
          <div class="col-md-3">

            <input type="text" class="form-control" [(ngModel)]="item.item" [disabled]="disabled === true" />
          </div>
          <div class="col-md-2">

            <input type="text" class="form-control" [(ngModel)]="item.quantity" [disabled]="disabled === true" />
          </div>
          <div class="col-md-2">

            <input type="number" class="form-control" [(ngModel)]="item.volume" [disabled]="disabled === true" />
          </div>
          <div class="col-md-2">

            <input type="number" class="form-control" [(ngModel)]="item.weight" [disabled]="disabled === true" />
          </div>
          <div class="col-md-2">
            <input type="text" class="form-control" [(ngModel)]="item.dimension" [disabled]="disabled === true" />
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <ng-container *ngIf="_deletePermission">
              <button class="btn feather icon-trash" (click)="removeRow(j, getAreaItemsBySurveyPlace(surveyPlace))"
                [disabled]="disabled === true"></button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="row mt-2">
        <div class="col-md-8">
        </div>
        <div class="col-md-4">
          <div class="input-group">
            <input type="text" class="form-control" title="Total Weight"
              [value]="'Total Weight = ' + calculateTotalWeight(getAreaItemsBySurveyPlace(surveyPlace))" readonly
              [disabled]="disabled === true" />
            <span class="input-group-text">{{ _weightUnit }}</span>
          </div>
        </div>
        <div class="col-md-1">
        </div>
      </div>
    </ng-container>
  </ng-container>



  &nbsp;
  &nbsp;

  <ng-template #emptyList>
    <div class="row mt-2">
      <div class="col-md-12">
        <p>No survey area items have been added yet. Please add items such as chairs, tables, etc.
        </p>
      </div>
    </div>
  </ng-template>


  &nbsp;
  &nbsp;
  &nbsp;
  &nbsp;

  <div class="row mt-4">
    <div class="col-md-11">
      <div class="survey-label">Packing Materials</div>
    </div>
    <div class="col-md-1 text-right">
      <!-- <button class="btn btn-sm me-2 inititate-move">Edit</button> -->
    </div>
  </div>
  <div class="row mt-2" *ngFor="let row of _materials; let first = first; let i = index">
    <ng-container *ngIf="!row.isDeleted">
      <div class="col-md-5">
        <label *ngIf="first">Item</label>
        <input type="text" class="form-control" [(ngModel)]="row.item" [disabled]="disabled === true" />
      </div>
      <div class="col-md-3">
        <label *ngIf="first">Quantity</label>
        <input type="text" class="form-control" [(ngModel)]="row.quantity" [disabled]="disabled === true" />
      </div>
      <div class="col-md-3">
        <label *ngIf="first">Cost/Unit</label>
        <input type="number" class="form-control" [(ngModel)]="row.price" [disabled]="disabled === true" />
      </div>

      <div class="col-md-1  d-flex align-items-center">
        <ng-container *ngIf="_createPermission">
          <button class="btn feather icon-plus" *ngIf="first" (click)="addRow('Material', _materials)"
            [disabled]="disabled === true"></button>
        </ng-container>
        <ng-container *ngIf="_deletePermission">
          <button class="btn feather icon-trash" *ngIf="!first" (click)="removeRow(i, _materials)"
            [disabled]="disabled === true"></button>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="row mt-2">
    <div class="col-md-8">
    </div>
    <div class="col-md-3">
      <div class="input-group">
        <input type="text" class="form-control" title="Materials Total"
          [value]="'Total = ' + calculateTotal(_materials, 'Material')" readonly [disabled]="disabled === true" />
        <span class="input-group-text">{{ _currency }}</span>
      </div>
    </div>
    <div class="col-md-1">
    </div>
  </div>

  &nbsp;
  &nbsp;
  &nbsp;
  &nbsp;

  <div class="row mt-4">
    <div class="col-md-11">
      <div class="survey-label">Labour</div>
    </div>
    <div class="col-md-1 text-right">
      <!-- <button class="btn btn-sm me-2 inititate-move">Edit</button> -->
    </div>
  </div>
  <div class="row mt-2" *ngFor="let row of _labour; let first = first; let i = index">
    <ng-container *ngIf="!row.isDeleted">
      <div class="col-md-5">
        <label *ngIf="first">Item</label>
        <input type="text" class="form-control" [(ngModel)]="row.item" [disabled]="disabled === true" />
      </div>
      <div class="col-md-3">
        <label *ngIf="first">Quantity</label>
        <input type="text" class="form-control" [(ngModel)]="row.quantity" [disabled]="disabled === true" />
      </div>
      <div class="col-md-3">
        <label *ngIf="first">Cost/Unit</label>
        <input type="number" class="form-control" [(ngModel)]="row.price" [disabled]="disabled === true" />
      </div>
      <div class="col-md-1  d-flex align-items-center">
        <ng-container *ngIf="_createPermission">
          <button class="btn feather icon-plus" *ngIf="first" (click)="addRow('Labour', _labour)"
            [disabled]="disabled === true"></button>
        </ng-container>
        <ng-container *ngIf="_deletePermission">
          <button class="btn feather icon-trash" *ngIf="!first" (click)="removeRow(i, _labour)"
            [disabled]="disabled === true"></button>
        </ng-container>
        <!-- <button class="btn btn-primary bt-sm pt-0" *ngIf="first" (click)="addRow()"> +
        </button> -->
      </div>
    </ng-container>
  </div>

  <div class="row mt-2">
    <div class="col-md-8">
      <!-- <label>Total</label> -->
    </div>
    <div class="col-md-3">
      <div class="input-group">
        <input type="text" class="form-control" title="Labour and Vehicles Total"
          [value]="'Total = ' + calculateTotal(_labour, 'Labour')" readonly [disabled]="disabled === true" />
        <span class="input-group-text">{{ _currency }}</span>
      </div>
    </div>
    <div class="col-md-1">

    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-11">
      <div class="survey-label">Vehicles</div>
    </div>
    <div class="col-md-1 text-right">
      <!-- <button class="btn btn-sm me-2 inititate-move">Edit</button> -->
    </div>
  </div>
  <div class="row mt-2" *ngFor="let row of _vehicles; let first = first; let i = index">
    <ng-container *ngIf="!row.isDeleted">
      <div class="col-md-5">
        <label *ngIf="first">Item</label>
        <input type="text" class="form-control" [(ngModel)]="row.item" [disabled]="disabled === true" />
      </div>
      <div class="col-md-3">
        <label *ngIf="first">Quantity</label>
        <input type="text" class="form-control" [(ngModel)]="row.quantity" [disabled]="disabled === true" />
      </div>
      <div class="col-md-3">
        <label *ngIf="first">Cost/Unit</label>
        <input type="number" class="form-control" [(ngModel)]="row.price" [disabled]="disabled === true" />
      </div>
      <div class="col-md-1  d-flex align-items-center">
        <ng-container *ngIf="_createPermission">
          <button class="btn feather icon-plus" *ngIf="first" (click)="addRow('Vehicle', _vehicles)"
            [disabled]="disabled === true"></button>
        </ng-container>
        <ng-container *ngIf="_deletePermission">
          <button class="btn feather icon-trash" *ngIf="!first" (click)="removeRow(i, _vehicles)"
            [disabled]="disabled === true"></button>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="row mt-2">
    <div class="col-md-8">
    </div>
    <div class="col-md-3">
      <div class="input-group">
        <input type="text" class="form-control" title="Vehicles Total"
          [value]="'Total = ' + calculateTotal(_vehicles, 'Vehicle')" readonly [disabled]="disabled === true" />
        <span class="input-group-text">{{ _currency }}</span>
      </div>
    </div>
    <div class="col-md-1">
    </div>
  </div>

  <!-- Container Size -->
  <div class="row mt-4">
    <div class="col-md-11">
      <div class="survey-label">Container Size</div>
    </div>
    <div class="col-md-1 text-right">
      <!-- <button class="btn btn-sm me-2 inititate-move">Edit</button> -->
    </div>
  </div>
  <div class="row mt-2" *ngFor="let row of _containers; let first = first; let i = index">
    <ng-container *ngIf="!row.isDeleted">
      <div class="col-md-4">
        <label *ngIf="first">Size</label>
        <ng-select [(ngModel)]="row.item" [items]="_containerSizes" placeholder="Select container size"
          [disabled]="disabled === true">
        </ng-select>
      </div>
      <div class="col-md-2">
        <label *ngIf="first">Quantity</label>
        <input type="text" class="form-control" [(ngModel)]="row.quantity" [disabled]="disabled === true" />
      </div>
      <div class="col-md-1 d-flex align-items-center">
        <ng-container *ngIf="_createPermission">
          <button class="btn feather icon-plus" *ngIf="first" (click)="addRow('Container', _containers)"
            [disabled]="disabled === true"></button>
        </ng-container>
        <ng-container *ngIf="_deletePermission">
          <button class="btn feather icon-trash" *ngIf="!first" (click)="removeRow(i, _containers)"
            [disabled]="disabled === true"></button>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="row mt-2">
    <div class="col-md-4">
    </div>
    <div class="col-md-2">
      <!-- <div class="input-group">
        <input type="text" class="form-control" title="Vehicles Total"
          [value]="'Total = ' + calculateTotal(_containers, 'Container')" readonly />
        <span class="input-group-text">{{ _currency }}</span>
      </div> -->
    </div>
    <div class="col-md-1">
      <!--1"
      v>-->
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="d-flex justify-content-end mt-4">
    <ng-container *ngIf="_createPermission">
      <button class="btn btn-sm btn-primary submit-info" (click)="saveMoveSurvey()" [disabled]="disabled === true">
        <i class="feather icon-save"></i>&nbsp; SAVE
      </button>
    </ng-container>
  </div>
</div>

<!-- <hr> -->

<div #pdfComponent class="hidden-content">
  <app-survey-summary [leadDetailId]="_leadDetail.id"></app-survey-summary>
</div>