<nav>
  <!-- Other navigation links... -->
  <router-outlet></router-outlet>

  <!-- The modal content -->

  <ng-template #basicModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title container d-flex align-items-center" id="exampleModalLabel">
        <lord-icon src="https://cdn.lordicon.com/uukerzzv.json" trigger="hover"
          colors="primary:#110a5c,secondary:#1ac0a1" style="width: 40px; height: 40px">
        </lord-icon>
        &nbsp; Create Lead
      </h4>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-9"></div>
        <div class="col-md-3">
          <span class="required-input"> * </span>
          <span class="required-label">Required fields</span>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="row mb-3">
          <div class="col-md-6">
            <label for="_lead.firstName" class="col-form-label">
              First Name
              <span class="required-input"> * </span>
            </label>
            <div class="input-group" ngbDropdown #firstNameDropdown="ngbDropdown" placement="bottom"
              [autoClose]="true || _lead.firstName === null">
              <input type="text" class="form-control" [(ngModel)]="_lead.firstName" id="_lead.firstName"
                autocomplete="on" placeholder="Enter first name"
                (keyup)="searchCustomer('firstName'); firstNameDropdown.open()" (focus)="firstNameDropdown.open()" />

              <div class="dropdown-menu scrollable-menu" ngbDropdownMenu *ngIf="_searchedCustomersList.length > 0">
                <div *ngFor="let customer of _searchedCustomersList; let isLast=last">
                  <button class="dropdown-item" (click)="selectCustomer(customer)">
                    <div>
                      <p> {{customer.customerName}}</p>
                    </div>
                    <div>
                      <p>{{customer.phone}}</p>
                    </div>
                    <div>
                      <p>{{customer.email}}</p>
                    </div>
                  </button>
                  <hr *ngIf="!isLast">
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <label for="_lead.lastName" class="col-form-label">Last Name</label>
            <div class="input-group" ngbDropdown #lastNameDropdown="ngbDropdown" placement="bottom"
              [autoClose]="true || _lead.lastName === null">
              <input type="text" class="form-control" [(ngModel)]="_lead.lastName" id="_lead.lastName" autocomplete="on"
                placeholder="Enter first name" (keyup)="searchCustomer('lastName'); lastNameDropdown.open()"
                (focus)="lastNameDropdown.open()" />

              <div class="dropdown-menu scrollable-menu" ngbDropdownMenu *ngIf="_searchedCustomersList.length > 0">
                <div *ngFor="let customer of _searchedCustomersList; let isLast=last">
                  <button class="dropdown-item" (click)="selectCustomer(customer)">
                    <div>
                      <p> {{customer.customerName}}</p>
                    </div>
                    <div>
                      <p>{{customer.phone}}</p>
                    </div>
                    <div>
                      <p>{{customer.email}}</p>
                    </div>
                  </button>
                  <hr *ngIf="!isLast">
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <label for="_lead.phoneNo" class="col-form-label">
              Phone Number
              <span class="required-input"> * </span>
            </label>
            <!-- <input type="text" class="form-control" [(ngModel)]="_lead.phone" id="_lead.phone"
              autocomplete="on" placeholder="Enter phone"
              (keypress)="validatePhoneNumber($event, _lead.phone)" /> -->
            <!-- <div *ngIf="isFieldEmpty(_lead.phoneNo)" class="invalid-input-color">* required</div> -->
            <div class="input-group" ngbDropdown #phoneDropdown="ngbDropdown" placement="bottom"
              [autoClose]="true || _lead.phone === null">
              <input type="text" class="form-control" [(ngModel)]="_lead.phone" id="_lead.phone" autocomplete="on"
                placeholder="Enter phone" (keypress)="validatePhoneNumber($event)"
                (keyup)="searchCustomer('phone');phoneDropdown.open()" (focus)="phoneDropdown.open()" />

              <div class="dropdown-menu scrollable-menu" ngbDropdownMenu *ngIf="_searchedCustomersList.length > 0">
                <div *ngFor="let customer of _searchedCustomersList; let isLast=last">
                  <button class="dropdown-item">
                    <div>
                      <p> {{customer.phone}}</p>
                    </div>
                    <div>
                      <p>{{customer.customerName}}</p>
                    </div>
                    <div>
                      <p>{{customer.email}}</p>
                    </div>
                  </button>
                  <hr *ngIf="!isLast">
                </div>
              </div>
            </div>

          </div>

          <div class="col-md-6">
            <label for="_lead.email" class="col-form-label">
              Email
              <span class="required-input"> * </span>
            </label>
            <!-- <input type="email" class="form-control" [(ngModel)]="_lead.email" id="_lead.email"
              autocomplete="on" placeholder="Enter email" required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              (keyup)="searchCustomer()" /> -->
            <div class="input-group" ngbDropdown #emailDropdown="ngbDropdown" placement="bottom"
              [autoClose]="true || _lead.email === null">
              <input type="email" class="form-control" [(ngModel)]="_leadEmail" id="_lead.email" autocomplete="on"
                placeholder="Enter email" (input)="onEmailChange($event)"
                (keyup)="searchCustomer('email');emailDropdown.open()" (focus)="emailDropdown.open()" required
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" />

              <div class="dropdown-menu scrollable-menu" ngbDropdownMenu *ngIf="_searchedCustomersList.length > 0">
                <div *ngFor="let customer of _searchedCustomersList; let isLast=last">
                  <button class="dropdown-item" (click)="selectCustomer(customer)">
                    <div>
                      <p>{{customer.email}}</p>
                    </div>
                    <div>
                      <p>{{customer.customerName}}</p>
                    </div>
                    <div>
                      <p>{{customer.phone}}</p>
                    </div>
                  </button>
                  <hr *ngIf="!isLast">
                </div>
              </div>
            </div>

            <!-- <div *ngIf="isFieldEmpty(_lead.email)" class="invalid-input-color">* required</div> -->
            <div
              *ngIf="_lead.email && _lead.email.length > 0 && !_lead.email.match('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')"
              class="invalid-input-color">
              Invalid email format.
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <label for="account" class="col-form-label">Account</label>
            <a class="float-end mt-4 small" (click)="openpoc('account')">Add</a>
            <ng-select (change)="filterContacts('contact', _lead.accountId)" [items]=" _companies"
              bindLabel="customerName" bindValue="id" [searchable]="true" [(ngModel)]="_lead.accountId"
              placeholder="Select Account">
            </ng-select>
          </div>

          <div class="col-md-6">
            <label for="poc" class="col-form-label">POC</label>
            <a class="float-end mt-4 small" (click)="openpoc('poc')">Add</a>
            <ng-select [items]="_filteredContactPOCs" bindLabel="customerName" bindValue="id" [searchable]="true"
              [(ngModel)]="_lead.pocId" placeholder="Select POC">
            </ng-select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <label for="thirdParty" class="col-form-label">Third Party</label>
            <a class="float-end mt-4 small" (click)="openpoc('thirdParty')">Add</a>
            <ng-select (change)="filterContacts('thirdParty', _lead.thirdPartyId)" [items]=" _companies"
              bindLabel="customerName" bindValue="id" [searchable]="true" [(ngModel)]="_lead.thirdPartyId"
              placeholder="Select Third Party">
            </ng-select>
          </div>

          <div class="col-md-6">
            <label for="contact" class="col-form-label">POC</label>
            <a class="float-end mt-4 small" (click)="openpoc('thirdPartyPoc')">Add</a>
            <ng-select [items]="_filteredTPPOCs" bindLabel="customerName" bindValue="id" [searchable]="true"
              [(ngModel)]="_lead.thirdPartyPocId" placeholder="Select Third Party POC">
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm" (click)="createLead()">
        <i class="mdi mdi-content-save"> </i> &nbsp;Create Lead
      </button>
      <button type="button" class="btn btn-danger btn-sm" (click)="closeModal()">
        <i class="mdi mdi-close"></i>&nbsp;Close
      </button>
    </div>
  </ng-template>
  <div class="loader-overlay" *ngIf="isLoading === true">
    <div id="loader">
      <div class="spinner"></div>
    </div>
  </div>


  <!-- Form for saving company, POC, third part Company/Contact -->
  <ng-template #pocModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{ _modaltitle }}</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-9"></div>
        <div class="col-md-3">
          <span class="required-input"> * </span>
          <span class="required-label">Required fields</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="_newCustomer.customerName" class="col-form-label">
            Name
            <span class="required-input"> * </span>
          </label>
          <input type="text" class="form-control" [(ngModel)]="_newCustomer.customerName" id="_newCustomer.customerName"
            autocomplete="on" placeholder="Enter name" required />
          <!-- <div *ngIf="!_newCustomer.customerName" class="invalid-input-color">* required</div> -->
        </div>
      </div>


      <div class="row mb-3" *ngIf="_customerType === 0">
        <div class="col-md-12">
          <label for="account" class="col-form-label">
            {{ _chooseAccountTitle }}
          </label>
          <ng-select [items]="_companies" bindLabel="customerName" bindValue="id" [searchable]="true"
            [(ngModel)]="_newCustomer.companyId"
            [placeholder]="_chooseAccountTitle === 'Third Party' ? 'Select Third Party' : 'Select Account'">
            >
          </ng-select>
        </div>
      </div>


      <div class="row mb-3">
        <div class="col-md-12">
          <label for="_newCustomer.phone" class="col-form-label">
            Phone Number
            <span class="required-input"> * </span>
          </label>
          <input type="text" class="form-control" [(ngModel)]="_newCustomer.phone" id="_newCustomer.phone"
            autocomplete="on" placeholder="Enter phone" (keypress)="validatePhoneNumber($event)" />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <label for="_newCustomer.email" class="col-form-label">
            Email
            <span class="required-input"> * </span>
          </label>
          <input type="email" class="form-control" [(ngModel)]="_userEmail" id="_newCustomer.email" autocomplete="on"
            placeholder="Enter email" required (blur)="checkEmailExists(_userEmail)"
            (keyup)="checkEmailExists(_userEmail)" />
          <!-- pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" /> -->
          <div *ngIf="isFieldEmpty(_userEmail)" class="invalid-input-color"></div>
          <div *ngIf="_userEmail && !_userEmail.match(
                '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'
              )
            " class="required-input">
            invalid email format.
          </div>
          <div *ngIf="_emailExists" class="required-input">
            already exists
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm" (click)="createCustomer()">
        <i class="mdi mdi-content-save"> </i> &nbsp; {{ _addBtnTitle }}
      </button>
      <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
        <i class="mdi mdi-close"> </i>&nbsp;Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #leadPreviewModal let-modal>
    <div class="modal-header">
      <div class="modal-title">
        <p>
          {{
          (_leadBoardItem.customer || ' - ') + ' - Lead '
          + (_leadBoardItem.leadDetailId || ' - ')
          }}
        </p>
      </div>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="box-left border border-dark-grey p-3 custom-rounded">
            <div class="row mb-3">
              <div class="col-6">
                <label class="mb-0">Customer:</label> {{ _leadBoardItem.customer || ' - ' }}
              </div>
              <div class="col-6">
                <label class="mb-0">Lead Id: </label> {{ _leadBoardItem.leadDetailId || ' - ' }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <label class="mb-0">Email:</label> {{ _leadBoardItem.email || ' - ' }}
              </div>
              <div class="col-6">
                <label class="mb-0">Account:</label> {{ _leadBoardItem.account || ' - ' }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="mb-0">Phone Number:</label> {{ _leadBoardItem.phone || ' - ' }}
              </div>
              <div class="col-6"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="row mb-3">
          <div class="col-md-6">
            <label for="_leadBoardItem.origin" class="col-form-label">
              Origin
              <span class="required-input"> * </span>
            </label>
            <input type="text" class="form-control" [(ngModel)]="_leadBoardItem.origin" id="_leadBoardItem.origin"
              autocomplete="on" placeholder="Origin" disabled />
          </div>

          <div class="col-md-6">
            <label for="_leadBoardItem.destination" class="col-form-label">
              Destination
              <span class="required-input"> * </span>
            </label>
            <input type="text" class="form-control" [(ngModel)]="_leadBoardItem.destination"
              id="_leadBoardItem.destination" autocomplete="on" placeholder="Destination" disabled />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <label class="col-form-label"> Move Type </label>
            <input type="text" class="form-control" [(ngModel)]="_leadBoardItem.moveTypeLabel" placeholder="Move Type"
              disabled>
          </div>

          <div class="col-md-6">
            <label class="col-form-label"> Move Category </label>
            <input type="text" class="form-control" [(ngModel)]="_leadBoardItem.moveCategoryLabel"
              placeholder="Move Category" disabled>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <label class="col-form-label"> Transport Type </label>
            <input type="text" class="form-control" [(ngModel)]="_leadBoardItem.moveTransportLabel"
              placeholder="Transport Type" disabled>
          </div>

          <div class="col-md-6"></div>
        </div>


        <hr class="flex-grow-1 m-3 border-dark-grey" />
        <div class="row mb-3">
          <div class="col-md-6">
            <label class="form-label fw-bold m-0 mt-3">
              &nbsp; Requested Survey Date
            </label>
            <div class="input-group">
              <input class="form-control clickable" title="Survey Date" name="dp"
                [value]="_leadBoardItem.formattedSurveyDate || ' - '" disabled />
              <button class="input-group-text" type="button" title="date-range">
                <i class="feather icon-calendar icon-md text-muted"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <label class="form-label fw-bold m-0 mt-3">
              &nbsp; Requested Loading Date
            </label>
            <div class="input-group">
              <ng-container *ngIf="_loadingDate; else placeholder">
                <input type="text" class="form-control clickable" placeholder="Select Date Range"
                  [value]="_leadBoardItem.loadingDate || ' - '" disabled />
              </ng-container>
              <ng-template #placeholder>
                <div class="form-control">Select Date</div>
              </ng-template>
              <button class="input-group-text" type="button" title="date-range">
                <i class="feather icon-calendar icon-md text-muted"></i>
              </button>
            </div>
          </div>

          <div class="col-md-6">
            <label class="form-label fw-bold m-0 mt-3">
              &nbsp; Requested Delivery Date
            </label>
            <div class="input-group">
              <input class="form-control clickable" name="dp" title="date-range"
                [value]="_leadBoardItem.formattedDeliveryDate || ' - '" disabled />
              <button class="input-group-text" type="button" title="date-range">
                <i class="feather icon-calendar icon-md text-muted"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-sm cancel-btn" (click)="leadInfoClick(); modal.close()">
        Lead Info Form
      </button>
      <button class="btn btn-sm btn-danger float-end" (click)="modal.close()">
        Close
      </button>
    </div>
  </ng-template>

  <ng-template #columnModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">&nbsp;Edit Columns</h5>
    </div>
    <div class="modal-body">
      <table>
        <ng-container *ngIf="_displayedLeadsList.length > 0">
          <tr *ngFor="let column of _allColumns">
            <td>
              <label>
                <input type="checkbox" class="form-check-input" [(ngModel)]="_selectedColumns[column]"
                  [ngModelOptions]="{ standalone: true }" (ngModelChange)="columnToggleSubject.next()" />
                {{ column }}
              </label>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </ng-template>

  <div class="row">
    <h5 class="dashboard-heading">LEADS DASHBOARD</h5>
  </div>

  <div class="row mt-3">
    <div class="col-3">
      <input type="text" id="_searchTerm" class="form-control" placeholder="Search..." [(ngModel)]="_searchTerm"
        (keyup.enter)="onSearch()" (input)="onInputChange($event)" />
      <small *ngIf="!_searchTerm" class="form-text text-muted">Type to search.</small>
      <small *ngIf="_searchTerm" class="form-text text-muted">Press Enter to search.</small>
    </div>
    <div class="col-5"></div>

    <div class="col-4">

      <ng-container *ngIf="_createPermission">
        <button class="btn btn-xs btn-primary btn-icon-text float-end" (click)="openBasicModal()">
          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
            colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
          </lord-icon>
          &nbsp; Add Lead
        </button>
        &nbsp;
      </ng-container>

      <button class="btn btn-xs btn-secondary btn-icon-text float-end" (click)="openColumnModal(columnModal)"
        style="margin-right: 3px; width: 130px">
        <lord-icon src="https://cdn.lordicon.com/qtqvorle.json" trigger="hover" delay="2000"
          colors="outline:#121331,primary:#646e78,secondary:#ebe6ef,tertiary:#4cb4fd" style="width: 20px; height: 20px">
        </lord-icon>
        &nbsp; Edit Columns
      </button>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-md-12">
      <label class="btn btn-outline-primary btn-rectangular btn-lg mt-3 custom-label"
        [class.btn-primary]="_selectedStatus === 'All'" [class.text-white]="_selectedStatus === 'All'"
        (click)="onClickStatus('All')">All</label>

      <!-- <label *ngFor="let status of _statusList" class="btn btn-outline-primary btn-label-lg btn-rectangular btn-lg mt-3"
        [class.btn-primary]="_selectedStatus === status.title" [class.text-white]="_selectedStatus === status.title"
        (click)="onClickStatus(status.title)">{{ status.title }}</label> -->

      <label *ngFor="let status of ['New', 'In Progress', 'Won', 'Lost']"
        class="btn btn-outline-primary btn-label-lg btn-rectangular btn-lg mt-3 custom-label"
        [class.btn-primary]="_selectedStatus === status" [class.text-white]="_selectedStatus === status"
        (click)="onClickStatus(status)">
        {{
        status
        }}
      </label>

    </div>
  </div>

  <div class="row" *ngIf="_displayedLeadsList.length === 0">
    <div class="col-12">
      <div class="card">
        <div class="card-body text-center">
          <h6>No Leads, currently. Please Add a lead.</h6>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="_displayedLeadsList.length > 0">
    <div class="col-12">
      <div class="my-datatable">
        <ngx-datatable [rows]="_displayedLeadsList" [sorts]="tableColumns"
          class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode"
          [summaryRow]="false" [footerHeight]="40" [limit]="_pageSize" [pagination]="true" (page)="onPageChange($event)"
          [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true" [count]="_totalRecords"
          [externalPaging]="true" [pageSize]="_pageSize">

          <ngx-datatable-column *ngFor="let column of tableColumns" [name]="column.header" [prop]="column.prop"
            class="thead" [style]="{ border: '1px solid black' }" [sortable]="true"
            [width]="column.header === 'Lead ID' ?100 : 190">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
              <ng-container *ngIf="value !== null">
                <ng-container *ngIf="column.header === 'Lead ID'" class="move-id-column">
                  <div class="move-id-container hover" (mouseenter)="onRowMouseEnter(row)"
                    (mouseleave)="onRowMouseLeave(row)">
                    <ng-container *ngIf="!_readPermission">
                      <a (click)="leadPreviewClick(row, 'leadIdClick')" [ngClass]="'primary-link'">
                        {{ row.formattedLeadDetailId}}
                      </a>
                    </ng-container>
                    <ng-container *ngIf="_readPermission">
                      <a [routerLink]="['/lead-detail']" [queryParams]="returnLeadUrl(row)"
                        (click)="leadPreviewClick(row, 'leadIdClick')" [ngClass]="'primary-link'">
                        {{ row.formattedLeadDetailId || ' - '}}
                      </a>
                    </ng-container>

                    <button
                      class="btn btn-secondary button-wrapper btn-icon-text previewClick btn-small-transparent btn-smaller"
                      *ngIf="row.isHovered" [attr.data-id]="row.id" (click)="leadPreviewClick(row, 'previewBtnClick')">
                      Preview
                    </button>
                  </div>

                </ng-container>
                <ng-container *ngIf="column.header === 'POC' || column.prop === 'pocName'">
                  <a [class.clickable-link]="row.pocName !== null"
                    (click)="row.pocName !== null ? redirectToAccount(row.pocId) : null">
                    {{ row.pocName || '-' }}
                  </a>
                </ng-container>
                <ng-container *ngIf="column.header === 'Third Party' || column.prop === 'thirdPartyName'">
                  <a [class.clickable-link]="row.thirdPartyName !== null"
                    (click)="row.thirdPartyName !== null ? redirectToAccount(row.thirdPartyId) : null">
                    {{ row.thirdPartyName || '-' }}
                  </a>
                </ng-container>
                <ng-container *ngIf="column.header === 'Third Party POC' || column.prop === 'thirdPartyPocName'">
                  <a [class.clickable-link]="row.thirdPartyPocName !== null"
                    (click)="row.thirdPartyPocName !== null ? redirectToAccount(row.thirdPartyPocId) : null">
                    {{ row.thirdPartyPocName || '-' }}
                  </a>
                </ng-container>
                <ng-container *ngIf="column.header === 'Customer' || column.prop === 'customerName'">
                  <a class="clickable-link" (click)="redirectToAccount(row.customerId)">
                    {{row.customer || '-'}}
                  </a>
                </ng-container>
                <ng-container *ngIf="column.header === 'Account'" class="clickable-link">
                  <ng-container *ngIf="row.account === 'Individual'; else clickableLink">
                    {{' - '}}
                  </ng-container>
                  <ng-template #clickableLink>
                    <a class="clickable-link" (click)="redirectToAccount(row.accountId)">
                      {{ row.account }}
                    </a>
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="column.header === 'Account Manager' || column.prop === 'accountManager'">
                  <a class="clickable-link" (click)="redirectToAccount(row.accountManagerId)">
                    {{row.accountManager || '-'}}
                  </a>
                </ng-container>
                <ng-container *ngIf="column.header === 'Origin' || column.prop === 'origin'">
                  {{ row.origin || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'Destination' || column.prop === 'destination'">
                  {{ row.destination || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'Move' || column.prop === 'moveType'">
                  {{ row.moveTypeLabel || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'Move Category' || column.prop === 'moveCategory'">
                  {{ row.moveCategoryLabel || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'Transport Type' || column.prop === 'moveTransport'">
                  {{ row.moveTransportLabel || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'POL' || column.prop === 'portOfLoading'">
                  {{ row.portOfLoading || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'POD' || column.prop === 'portOfDischarge'">
                  {{ row.portOfDischarge || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'Place of Delivery' || column.prop === 'placeOfDelivery'">
                  {{ row.placeOfDelivery || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'Warehouse Storage' || column.prop === 'warehouseStorage'">
                  {{ row.warehouseStorage || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'Requested Loading Date' || column.prop === 'loadingDate'">
                  {{ row.loadingDate || '-'}}
                </ng-container>
                <ng-container *ngIf="column.header === 'Requested Survey Date' || column.prop === 'surveyDate'">
                  {{ row.formattedSurveyDate || '-' }}
                </ng-container>
                <ng-container *ngIf="column.header === 'Requested Storage Date' || column.prop === 'storageDate'">
                  {{ row.storageDate || '-' }}
                </ng-container>
                <ng-container *ngIf="column.header === 'Requested Pack Date' || column.prop === 'packagingDate'">
                  {{ row.packagingDate || '-' }}
                </ng-container>
                <ng-container *ngIf="column.header === 'Requested Delivery Date' || column.prop === 'deliveryDate'">
                  {{ row.formattedDeliveryDate || '-' }}
                </ng-container>

                <ng-container *ngIf="column.header === 'Status'">
                  <div class="status-text">{{ value || '-'}}</div>
                  <div class="status-indicator">
                    <ng-container *ngFor="let status of _statusList; let i = index">
                      <!-- <div class="status-dot" [ngClass]="{0
                      ...........................................................................................................................................................................................................................................................................................................             'completed' : _completedStatuses.includes(status.title),
                                   'highlight-dot' : i < _completedStatuses.length}">
                              </div> -->
                      <span *ngIf="i < _statusList.length - 1" class="status-line" [ngClass]="{}">
                      </span>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container *ngIf="_userRole === 'CompanyAdmin' && column.header === 'Action'">
                  <button class="btn btn-danger btn-xs datatable-action-cell ml-2"
                    (click)="deleteLeadDetail(row)">Delete</button>
                </ng-container>

              </ng-container>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</nav>