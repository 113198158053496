import { eMoveType, eMoveCategoryType, eTransportType, eProcessStatus } from "../models/lead-detail.model";

export class LeadBoardDTO {
    constructor(
        public leadId: number | null = null,
        public leadDetailId: number = 0,
        public surveyId: number | null = null,
        public customerId: number | null = null,
        public customer: string | null = null,
        public phone: string | null = null,
        public email: string | null = null,
        public account: string | null = null,
        public accountId: number | null = null,
        public accountName: string | null = null,
        public pocId: number | null = null,
        public pocName: string | null = null,
        public thirdPartyId: number | null = null,
        public thirdPartyName: string | null = null,
        public thirdPartyPocId: number | null = null,
        public thirdPartyPocName: string | null = null,
        public status: string | null = null,
        public origin: string | null = null,
        public destination: string | null = null,
        public originStAddress: string | null = null,
        public destinationStAddress: string | null = null,
        public moveType: eMoveType | null = null,
        public moveCategory: eMoveCategoryType | null = null,
        public moveTransport: eTransportType | null = null,
        public surveyDate: Date | null | string = null,
        public packagingDateFrom: Date | null = null,
        public packagingDateTo: Date | null = null,
        public loadingDateFrom: Date | null = null,
        public loadingDateTo: Date | null = null,
        public storageDateFrom: Date | null = null,
        public storageDateTo: Date | null = null,
        public deliveryDate: Date | null = null,
        public accountManagerId: number | null = null,
        public accountManager: string | null = null,
        public portOfLoading: string | null = null,
        public portOfDischarge: string | null = null,
        public placeOfDelivery: string | null = null,
        public insuranceRequired: boolean | null = null,
        public creationDate: Date | null = null,
        public moveInitiated: boolean | null = null,
        public markLost: boolean | null = null,
        public lostReason: string | null = null,
        public additionalNotes: string | null = null,
        public surveyStatus: eProcessStatus | null = null,
        public selected: boolean | null = null,
        public totalCount: number = 0,

        // not mapped properties
        public warehouseStorage: string | null = null,
        public formattedLeadDetailId: string | null = null,
        public formattedCustomerId: string | null = null,
        public formattedSurveyId: string | null = null,
        public packagingDate: string | null = null,
        public loadingDate: string | null = null,
        public storageDate: string | null = null,
        public moveTypeLabel: string | null = null,
        public moveCategoryLabel: string | null = null,
        public moveTransportLabel: string | null = null,
        public formattedDeliveryDate: string | null = null,
        public surveyStatusLabel: string | null = null,
        public formattedSurveyDate: string | null = null,

        public firstName = null,
        public lastName = null,
    ) { }
}

export interface LeadBoardResponse {
    leads: LeadBoardDTO[];
    totalRecords: number;
}

export const allLeadsColumns: string[] = [
    "Lead ID",
    "Account",
    "Customer",
    "Status",
    "Origin",
    "Destination",
    "Move",
    "POL",
    "POD",
    "Place Of Delivery",
    "POC",
    "Third Party",
    "Third Party POC",
    "Move Category",
    "Transport Type",
    "Warehouse Storage",
    "Requested Survey Date",
    "Requested Pack Date",
    "Requested Loading Date",
    "Requested Storage Date",
    "Requested Delivery Date",
    "Account Manager",
    "Action"
];

export const allSurveyColumns: string[] = [
    "Survey ID",
    "Lead ID",
    "Account",
    "Customer",
    "Origin",
    "Destination",
    "Move",
    "POL",
    "POD",
    "Place Of Delivery",
    "POC",
    "Third Party",
    "Third Party POC",
    "Move Category",
    "Transport Type",
    "Warehouse Storage",
    "Requested Survey Date",
    "Requested Pack Date",
    "Requested Loading Date",
    "Requested Storage Date",
    "Requested Delivery Date",
    "Account Manager"
];
