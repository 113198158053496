import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MovdService } from '../../services/movd.service';
import { MoveSurvey } from '../../models/move-survery.model';
import { SurveyMaterial } from '../../models/survery-material.model';
import { PdfService } from '../../../../../services/pdf.service';
import { NgbDateStruct, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CompanySetting, getCurrencySymbol, getDimensionUnitShortLabel, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../../models/companysetting.model';
import { SurveyItem } from '../../models/survery-item.model';
import { AppComponent } from 'src/app/app.component';
import { LeadDetail } from '../../models/lead-detail.model';
import { EnumMappingService } from '../../services/movd.enum.service';
import { GeneralService } from 'src/services/general.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Address } from 'src/app/views/crm/contact/contact.models';
import { AuthService } from 'src/services/auth.service';
import { AllSurveyPlacesResponse, SurveyPlace } from '../../models/movd-comp-admin.model';
import { LeadBoardDTO } from '../../lead-board/lead-board.model';

@Component({
  selector: 'app-lead-survey',
  templateUrl: './lead-survey.component.html',
  styleUrls: ['./lead-survey.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})

export class LeadSurveyComponent {


  @Input() leadDetailId: any;
  @Input() leadId: any;
  @Input() disabled: any;
  @ViewChild('pdfComponent') pdfContent!: any;

  //variables
  // _moveDetailId: number = 0;
  _isDataLoaded: boolean = false;
  pdfDocGenerator: any;
  kgToLbsConversionFactor: number = 2.20462; // 1 kg = 2.20462 lbs
  cuftToCbmConversionFactor: number = 0.0283168; // 1 cuft = 0.0283168 cbm

  //objects
  _leadDetail: LeadDetail = new LeadDetail();
  _leadSummary = new LeadBoardDTO();
  _moveSurvey = new MoveSurvey();
  _surveyItem = new SurveyItem();

  // lists 
  _surveyMaterialsList!: SurveyMaterial[];
  _basicModalRef: NgbModalRef | undefined;

  // _surveyPlaces: [] = [];
  _areaItems: SurveyMaterial[] = [];
  _materials: SurveyMaterial[] = [];
  _labour: SurveyMaterial[] = [];
  _vehicles: SurveyMaterial[] = [];
  _containers: SurveyMaterial[] = [];
  _originAddresses: Address[] = [];
  _destinationAddresses: Address[] = [];

  _containerSizes: string[] = ['20\'', '40\'', '40\' HC'];
  _selectedContainerSize: string = '';

  _filteredAreaItems: SurveyMaterial[] = [];

  // Initialize the map
  _surveyPlaceToItemMap: Map<string, string> = new Map();
  _selectedSurveyPlace: string | null = null;

  selectedItem: any;
  selectedItems: { name: string; quantity: string; volume: string }[] = [];
  @ViewChild('basicModal') basicModal: any;

  _companySetting = new CompanySetting();
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _dimensionUnit: string = '';
  _currencyUnit: string = '';
  _currency: any;
  _leadBoardItem = new LeadBoardDTO();

  // survey place
  _dbSurveyItemsByPlace: SurveyItem[] = [];
  // Assuming _dbSurveyItemsByPlace is already populated with data
  _surveyPlacesList: SurveyPlace[] = [];
  selectedSurveyPlace!: SurveyPlace | null;
  _selectedSurveyPlaceId!: number | null;
  _selectedItem: SurveyMaterial | null = null;
  _surveyPlace = new SurveyPlace();

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;

  constructor(
    private _elementRef: ElementRef,
    private renderer: Renderer2,
    private _modalService: NgbModal,
    private _pdfService: PdfService,
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    public enumMappingService: EnumMappingService,
    public generalService: GeneralService,
    private _changeDetRef: ChangeDetectorRef,
    private _authService: AuthService,
    private ngZone: NgZone
  ) {
    this._surveyPlaceToItemMap = new Map();
    this._userRole = localStorage.getItem('role');

  }
  ngOnInit() {
    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Lead Info Form - Survey.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Lead Info Form - Survey.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('Lead Info Form - Survey.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Lead Info Form - Survey.Delete');
  }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log("lead survey", changes['leadDetailId'].currentValue);
    if (changes['leadDetailId']) {
      this.leadDetailId = changes['leadDetailId'].currentValue;
      if (this.leadDetailId && this.leadDetailId !== 0) {
        this._areaItems = [];
        this._materials = [];
        this._labour = [];
        this._vehicles = [];
        this._containers = [];
        this._filteredAreaItems = [];
        this._surveyMaterialsList = [];
        this.ngZone.run(() => {
          // Your data update logic here
          this.getMoveSurveys();
          this.getCompanySetting();
          this.getAllSurveyPlaces();
          this.getMoveDetailById(this.leadDetailId);
          this.getLeadSummary();
        });
      }
      this._changeDetRef.detectChanges();
    } else if (changes['disabled']) {
      this.disabled = changes['disabled'].currentValue;
      // console.log("disabled", changes['disabled']);
    }
  }

  getMoveSurveys() {
    this._movdService.getMoveSurvey(this.leadDetailId).subscribe({
      next: (data: any) => {
        // console.log("move survey list", data);
        if (data) {
          this._moveSurvey = data;
          this._surveyMaterialsList = this._moveSurvey.surveyMaterials;
          this._surveyMaterialsList.forEach((material: SurveyMaterial) => {
            switch (material.type) {
              case 'Material':
                this._materials.push(material);
                break;
              case 'Area Item':
                this._areaItems.push(material);
                break;
              case 'Labour':
                this._labour.push(material);
                break;
              case 'Vehicle':
                this._vehicles.push(material);
                break;
              case 'Container':
                this._containers.push(material);
                break;
              // Handle other types if needed
              default:
                break;
            }
          });

          this.initializeMaterialRows();

          if (this._surveyPlaceToItemMap.size > 0) {
            this._selectedSurveyPlace = Array.from(this._surveyPlaceToItemMap.keys())[0];
            this.filterItemsBySurveyPlace(this._selectedSurveyPlace);
          }

          if (this._areaItems.length > 0) {
            this._areaItems.forEach((item) => {
              if (item.surveyPlace !== null && item.item) {
                this._surveyPlaceToItemMap.set(item.surveyPlace, item.item);
              }
            });

            // Get the first key from the map and call filterItemsBySurveyPlace
            const firstSurveyPlace = Array.from(this._surveyPlaceToItemMap.keys())[0];
            if (firstSurveyPlace) {
              this.filterItemsBySurveyPlace(firstSurveyPlace);
            }
          }
        }
        else {
          this._moveSurvey = new MoveSurvey();
          this.initializeMaterialRows();
        }

        this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  getActiveAreaItems() {
    return this._areaItems.filter(item => !item.isDeleted).length;
  }

  // Returns an array of distinct surveyPlace values from _areaItems
  getDistinctSurveyPlaces() {
    const surveyPlaces = this._areaItems
      .filter(item => item.isDeleted === false)
      .map(item => item.surveyPlace);
    return [...new Set(surveyPlaces)];
  }

  // Returns an array of items in _areaItems that have the given surveyPlace value
  getAreaItemsBySurveyPlace(surveyPlace: string | boolean | "" | null) {
    if (surveyPlace === null || surveyPlace === false || surveyPlace === "") {
      return [];
    } else {
      return this._areaItems.filter(item => item.surveyPlace === surveyPlace && item.isDeleted === false);
    }
  }

  initializeMaterialRows() {
    if (this._materials && this._materials.length === 0) {
      this.addRow('Material', this._materials);
    }
    if (this._labour && this._labour.length === 0) {
      this.addRow('Labour', this._labour);
    }
    if (this._vehicles && this._vehicles.length === 0) {
      this.addRow('Vehicle', this._vehicles);
    }
    if (this._containers && this._containers.length === 0) {
      this.addRow('Container', this._containers);
    }

  }

  // generatePDF() {
  //   const contentElement = this.pdfContent.nativeElement;

  //   if (contentElement) {
  //     contentElement.style.visibility = 'visible';
  //     contentElement.offsetHeight;

  //     // Generate the PDF
  //     this._pdfService.ConvertHTMLToPDF(contentElement, 'Move-Quotation-' + this.leadDetailId);
  //     // Revert the visibility back to hidden
  //     contentElement.style.visibility = 'hidden';
  //   } else {
  //     console.error('HTML content not found');
  //   }
  // }



  getDivContent(): string {
    const div = this._elementRef.nativeElement.querySelector('#pdf-content');
    return div.innerHTML;
  }
  openPdfModel() {
    const modalOptions: NgbModalOptions = {
      size: 'lg',       // Set the size of the modal (lg = large)
      centered: true,   // Center the modal vertically and horizontally
    };

    // Open the modal with the specified options
    this._basicModalRef = this._modalService.open(this.basicModal, modalOptions);
  }

  filterItemsBySurveyPlace(selectedSurveyPlace: string | null) {
    if (selectedSurveyPlace) {
      this._filteredAreaItems = this._areaItems.filter((item) => item.surveyPlace === selectedSurveyPlace);
    } else {
      this._filteredAreaItems = [];
    }
  }
  CloseModel() {
    this._basicModalRef?.close();
  }

  calculateTotal(items: any[], type: string): number {
    return items.reduce((total, item) => {
      return total + (item.quantity || 0) * (item.price || 0);
    }, 0);
  }
  calculateTotalWeight(items: any[]): number {
    let totalWeight = 0;
    items.forEach(item => {
      totalWeight += item.weight || 0; // Ensure item.weight is not undefined
    });
    return totalWeight;
  }
  addRow(type: string, targetArray: SurveyMaterial[]): void {
    const newMaterial = new SurveyMaterial();
    newMaterial.leadDetailId = this.leadDetailId;
    newMaterial.type = type;
    targetArray.push(newMaterial);
  }

  // Function to remove a row by index
  removeRow(index: number, targetArray: SurveyMaterial[]): void {
    // console.log("remove row index and target array", index);
    if (index >= 0 && index < targetArray.length) {
      // targetArray.splice(index, 1);
      targetArray[index].isDeleted = true;
    }
  }

  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.dimensionUnit) {
            this._dimensionUnit = getDimensionUnitShortLabel(response.dimensionUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', this._currency);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }

  onSurveyPlaceChange(selectedSurveyPlaceId: number) {
    // console.log('Selected Area Changed:', selectedSurveyPlaceId);
    this.SurveyPlaceChanged();
  }

  getSurveyItemOnNgInit() {
    this._movdService.getSurveyItemsBySurveyPlaceId(this._surveyPlacesList[0].id).subscribe({
      next: (data: any) => {
        // console.log("survey items ngoninit", data);
        if (data && data.length > 0) {
          this._dbSurveyItemsByPlace = data.filter((item: { isDeleted: boolean }) => !item.isDeleted);

        } else {
          this._dbSurveyItemsByPlace
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  SurveyPlaceChanged() {
    // console.log("survey place changed id  ", this._selectedSurveyPlaceId);
    if (!this._selectedSurveyPlaceId) {
      return;
    }

    this._movdService.getSurveyItemsBySurveyPlaceId(this._selectedSurveyPlaceId).subscribe({
      next: (data: any) => {
        // console.log("survey place changed: survey items by id", data);
        if (data && data.length > 0) {
          this._dbSurveyItemsByPlace = data.filter((item: { isDeleted: boolean }) => !item.isDeleted);
        } else {
          this._dbSurveyItemsByPlace = [];
        }
      },

      error: (error) => {
        console.error(error);
      }
    });
  }

  addItemToTable(surveyItem: SurveyItem): void {
    if (this._createPermission === false) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to add the items', '');
      return;
    }
    // console.log("add item to table", surveyItem);
    if (this._selectedSurveyPlaceId && this._surveyPlacesList.length > 0) {
      const surveyPlace = this._surveyPlacesList.find(item => item.id === this._selectedSurveyPlaceId);

      const existingItem = this._areaItems.find(item => item.item === surveyItem.title);

      // if (existingItem && existingItem.isDeleted === false) {
      //   // existingItem.quantity++;
      // } else if (existingItem && existingItem.isDeleted === true) {
      //   existingItem.quantity++;
      //   existingItem.isDeleted = false;
      // } else {
      //   const newItem: SurveyMaterial = {
      //     id: 0,
      //     leadDetailId: this.leadDetailId,
      //     surveyPlace: surveyPlace?.title ?? '',
      //     item: surveyItem.title,
      //     quantity: 1,
      //     price: null,
      //     type: 'Area Item',
      //     weight: surveyItem.weight,
      //     volume: surveyItem.estimatedVolume,
      //     isDeleted: false,
      //   };
      //   this._areaItems.push(newItem);
      // }

      // console.log('surveyItem.dimensions:', surveyItem.dimension);
      const newItem: SurveyMaterial = {
        id: 0,
        leadDetailId: this.leadDetailId,
        surveyPlace: surveyPlace?.title ?? '',
        item: surveyItem.title,
        quantity: 1,
        price: null,
        type: 'Area Item',
        weight: surveyItem.weight,
        volume: surveyItem.estimatedVolume,
        isDeleted: false,
        dimension: surveyItem.dimension ?? null, // Use dimensions from SurveyItem
      };
      // console.log('newItem:', newItem);
      this._areaItems.push(newItem);
    }
  }

  getLeadSummary() {
    this._movdService.getLeadSummary(this.leadDetailId).subscribe({
      next: (response: any) => {
        // this.getMoveSurveys();
        // this.getMoveDetail(); // not needed now as getting dates from move summary
        if (response && response.length > 0) {
          this._leadSummary = response[0];
          // console.log("move summary", this._moveSummary);
        }
        // console.log("getMoveSummary", response);
      },
      error: (error: any) => {
        console.error("error getting lead summary", error);
      }
    });
  }

  getAllSurveyPlaces(): void {
    // const companyId = Number(localStorage.getItem('companyId'));
    this._movdService.getAllSurveyPlaces(null, null, null).subscribe({
      next: (data: AllSurveyPlacesResponse) => {
        // console.log("survey places", data);
        if (data && data.surveyPlaces.length > 0) {
          this._surveyPlacesList = data.surveyPlaces;
          this._selectedSurveyPlaceId = this._surveyPlacesList[0].id;
          this.getSurveyItemOnNgInit();
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  saveMoveSurvey() {
    // console.log("selected move detail id ", this._moveDetailId);

    const companyId = Number(localStorage.getItem('companyId'));
    const currentUserId = Number(localStorage.getItem('currentUserId'));

    this._moveSurvey.leadDetailId = this.leadDetailId;
    this._moveSurvey.createdBy = currentUserId;

    // const surveyAreaItems: SurveyMaterial[] = this._areaItems.map((areaItem) => {
    //   const surveyMaterial = new SurveyMaterial();
    //   surveyMaterial.id = areaItem.id
    //   surveyMaterial.moveDetailId = this._moveDetailId;
    //   surveyMaterial.item = areaItem.item;
    //   surveyMaterial.quantity = areaItem.quantity;
    //   surveyMaterial.surveyPlace = areaItem.surveyPlace;
    //   surveyMaterial.price = null;
    //   surveyMaterial.type = areaItem.type;
    //   surveyMaterial.weight = areaItem.weight;
    //   surveyMaterial.volume = areaItem.volume;

    //   return surveyMaterial;
    // });

    const materials = this._materials.map((material, index) => {
      if (index === 0) {
        material.leadDetailId = this.leadDetailId;
        material.type = 'Material';
      }
      return material;
    });

    // Map and add moveDetailId and type to _labourAndVehicles array
    const labour = this._labour.map((labour, index) => {
      if (index === 0) {
        labour.leadDetailId = this.leadDetailId;
        labour.type = 'Labour';
      }
      return labour;
    });

    const vehicles = this._vehicles.map((vehicle, index) => {
      if (index === 0) {
        vehicle.leadDetailId = this.leadDetailId;
        vehicle.type = 'Vehicle';
      }
      return vehicle;
    });

    const containers = this._containers.map((container, index) => {
      if (index === 0) {
        container.leadDetailId = this.leadDetailId;
        container.type = 'Container';
      }
      return container;
    });

    const surveyMaterials = [...this._areaItems, ...materials, ...labour, ...vehicles, ...containers];
    this._moveSurvey.surveyMaterials = surveyMaterials;

    // const surveyAttachments: MoveAttachment[] = [];

    // for (const attachment of this._attachments) {
    //   attachment.id = attachment.id;
    //   attachment.moveDetailId = this.moveDetailId;
    //   attachment.title = attachment.title;
    //   attachment.imageData = attachment.imageData;
    //   attachment.isValid = attachment.isValid;
    //   attachment.type = attachment.type;
    //   surveyAttachments.push(attachment);
    // }
    // this._moveSurvey.surveyAttachments = surveyAttachments;

    // console.log("save _moveSurvey", this._moveSurvey);

    this._movdService.saveMoveSurvey(this._moveSurvey).subscribe({
      next: (response: any) => {
        // console.log("save _moveSurvey respnse", response);
        this._appComponent.showSuccessSwal("saved", "survey");
        this._pdfService.createSurveyPdf();
      },
      error: (error: any) => {
        // this._appComponent.showErrorSwal("saving", "survey");
        console.error("error saving company", error);
      }
    });
  }

  calculateVolume(surveyPlace: string, unit: string, type: string): string | number {
    let totalVolume;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalVolume = items.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    } else {
      totalVolume = this._areaItems.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalVolume.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'cuft' && this._volumeUnit === 'cuft') {
      // Convert from cuft to cbm
      const convertedVolume = totalVolume * this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cbm`;
    } else if (unit === 'cbm' && this._volumeUnit === 'cbm') {
      // Convert from cbm to cuft
      const convertedVolume = totalVolume / this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cuft`;
    }

    return result;
  }

  getItemCount(surveyPlace: string): number {
    const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  calculateWeight(surveyPlace: string, unit: string, type: string): string | number {
    let totalWeight;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalWeight = items.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    } else {
      totalWeight = this._areaItems.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalWeight.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'kg' && this._weightUnit === 'kg') {
      // Convert from kg to lbs
      const convertedWeight = totalWeight * this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} lbs`;
    } else if (unit === 'lbs' && this._weightUnit === 'lbs') {
      // Convert from lbs to kg
      const convertedWeight = totalWeight / this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} kg`;
    }

    return result;
  }

  getMoveDetailById(leadDetailId: any) {
    this._movdService.getLeadDetailById(leadDetailId).subscribe({
      next: (data: any) => {
        // console.log("one move detail", data);
        if (data) {
          this._leadDetail = data;
          if (this._leadDetail && this._leadDetail.addresses !== null) {
            const originAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 0));
            const destAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 1));
            // console.log("origin address", originAdrress);
            // console.log("dest address", destAdrress);

            if (originAdrress.length > 0) {
              this._originAddresses = originAdrress;
            }

            if (destAdrress.length > 0) {
              this._destinationAddresses = destAdrress;
            }
          }
        } else {
          this._leadDetail = new LeadDetail();
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  openAreaModal(content: any): void {
    // this._survey.title = '';
    this._surveyPlace = new SurveyPlace();
    this._modalService.open(content, { centered: true });
  }
  saveArea(modal: any): void {
    const companyId = Number(localStorage.getItem('companyId'));

    if (this._surveyPlace.title.trim().length === 0) {
      return;
    }
    this._surveyPlace.ownedBy = companyId;

    this._movdService.saveSurveyPlace(this._surveyPlace).subscribe({
      next: (response: any) => {
        // console.log("save survey place response", response);
        this._appComponent.showSuccessSwal("created", "record");
        this.getAllSurveyPlaces();
        modal.close();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("creating", "record");
        console.error("error", error);
      }
    });
  }
  generatePdf(type: any) {
    const pdfType = type;
    this._pdfService.generateSurveyPdf(this.leadDetailId, this.leadId, pdfType);
  }
}
