import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
/*import { LeadBoardDTO } from '';*/
import { ActivatedRoute } from '@angular/router';
import { MovdService } from '../../services/movd.service';
import { StatusWithMoveStatus } from '../../models/move-status.model';
import { eStatusType } from '../../models/status.model';
import { EnumMappingService } from '../../services/movd.enum.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import SignaturePad from 'signature_pad';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CompanySetting, getCurrencySymbol, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../../models/companysetting.model';
import { MoveTypeOptions, MoveCategoryTypeOptions, TransportTypeOptions, eTransportType, eMoveCategoryType } from '../../models/lead-detail.model';
import { PdfService } from 'src/services/pdf.service';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from 'src/app/app.component';
import { eAppraisalType } from '../../models/appraisal.model';
import { GeneralService } from '../../../../../services/general.service';
import { ePackingType, PackagingInventoryItem } from '../../models/packaging-inventory-item.model';
import { PackagingInventory } from '../../models/packaging-inventory.model';
import { PackagingWeightCertItem } from '../../models/packaging-weight-cert-item.model';
import { PackagingWeightCert } from '../../models/packaging-weight-cert.model';
import { BreadcrumbService } from '../../../../../services/breadcrumb.service';
import { LeadBoardDTO } from '../../lead-board/lead-board.model';
import { PackagingBoardListDTO, PackingListResponse } from '../packing-list/packing-board.model';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss']
})

export class DeliveryDetailsComponent implements OnInit {

  // _ePackingTypeOptions = ePackingTypeOptions;
  packagingTypeOptions = Object.keys(ePackingType)
    .map((key: any) => ({ value: +key, label: ePackingType[key] }))
    .filter((option: any) => !isNaN(option.value));
  _leadBoardDTOList: LeadBoardDTO[] = [];
  // _packingDetail = new PackagingDetail();
  _moveDetail = 'moveDetail';
  _assignmentType = 'moveDetail';
  _appraisalType = eAppraisalType.Delivery;
  _packingDetail = new PackagingBoardListDTO();
  _leadId: number = 0;
  defaultNavActiveId: number = 1;
  defaultNavActiveIdForType: number = 1;
  _statusVersion: number = 0;
  _statusType = eStatusType.Lead;
  _isDataLoaded: boolean = false;
  _moveDetailId: number = 0;
  // _moveDetail = new MoveDetail();
  _leadStatuses: StatusWithMoveStatus[] = [];
  _packingBoardDTOList: PackagingBoardListDTO[] = [];
  _firstStatus: any;
  _lastStatus: any;
  inventoryItems: any[] = [];
  packagingInventoryId: number = 1;

  _inventoryList: any[] = []

  //weightCertificateList: any[] = [
  //  { number: '', grossLbs: '', tareLbs: '', netLbs: '', volume: '', dimensions: '', sealNo: '' },
  //  // Add more items as needed
  //];
  weightCertItem = new PackagingWeightCertItem();

  weightCertsList: any[] = []

  _packagingWeightCertId: number | null = null;
  _externalNotesWeight!: string | null;
  _externalNotesInvent!: string | null;

  _packagingInventoryId: number | null = null;
  _packagingDetailId: number | null = null;

  // objects
  _packagingInventory = new PackagingInventory();
  _packagingWeightCert = new PackagingWeightCert();

  // signature pads
  @ViewChild('customerSingatureCanvasInvent') customerSingatureCanvasInvent!: ElementRef<HTMLCanvasElement>;
  @ViewChild('surveyMgrSingatureCanvasInvent') surveyMgrSingatureCanvasInvent!: ElementRef<HTMLCanvasElement>;
  customerSignaturePadInvent!: SignaturePad;
  surveyMgrSignaturePadInvent!: SignaturePad;

  @ViewChild('customerSingatureCanvasWeight') customerSingatureCanvasWeight!: ElementRef<HTMLCanvasElement>;
  @ViewChild('surveyMgrSingatureCanvasWeight') surveyMgrSingatureCanvasWeight!: ElementRef<HTMLCanvasElement>;
  customerSignaturePadWeight!: SignaturePad;
  surveyMgrSignaturePadWeight!: SignaturePad;

  @ViewChild('pdfContent') pdfContent!: any;

  _activeTab = 1;
  // export pdfs
  // export inventory list
  @ViewChild('pdfContentInventory') pdfContentInventory!: any;
  _companySetting = new CompanySetting();
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _currencyUnit: string = '';
  _currency: any;
  pdfDocGenerator: any;
  _moveBoardItem: any;
  _moveBoardDTOList: any[] = [];
  /* generalService: any;*/
  _cd: any;
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;

  _packagingDetailDTO: any;

  _weightCertFile: any;
  modalRef: NgbModalRef | null = null;
  isLoading = false;
  _showWeightCertificate: boolean = false;
  _weightCertTitle!: string;


  // export inventory list
  @ViewChild('pdfContentWeight') pdfContentWeight!: any;
  _assembleDisassembleList: PackagingInventoryItem[] = [];
  _cratedItemList: PackagingInventoryItem[] = [];
  _electronicList: PackagingInventoryItem[] = [];
  _highValueList: PackagingInventoryItem[] = [];
  _filteredInventoryList: PackagingInventoryItem[] = [];
  _selectedPackingType: ePackingType | number | null = null;
  ePackagingType = ePackingType;
  _displayInventoryList: any[] = [];


  kgToLbsConversionFactor: number = 2.20462; // 1 kg = 2.20462 lbs
  cuftToCbmConversionFactor: number = 0.0283168; // 1 cuft = 0.0283168 cbm

  constructor(
    public enumMappingService: EnumMappingService,
    public generalService: GeneralService,
    private _route: ActivatedRoute,
    private _movdService: MovdService,
    private _pdfService: PdfService,
    private _http: HttpClient,
    private _appComponent: AppComponent,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Delivery List', url: '/delivery-list' },
      { text: 'Delivery Form', url: '/delivery-details' },

    ]);

    this._route.queryParams.subscribe(
      params => {
        this._packagingDetailId = Number(params['packagingDetailId']);
        this._leadId = Number(params['leadId']);
        this._moveDetailId = Number(params['moveDetailId']);
        // console.log("params ", params);
        if (this._leadId && this._leadId !== 0) {
          this.getPackagingDetailsByLeadId();
          this.getCompanySetting();
        }
      });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this._inventoryList = [];
    if (changes['packagingDetailId']) {
      this._packagingDetailId = changes['packagingDetailId'].currentValue;
      this._packagingInventory.packagingDetailId = changes['packagingDetailId'].currentValue;
      this._packagingWeightCert.packagingDetailId = changes['packagingDetailId'].currentValue;
      // console.log(" getting packingdetail is this s", this._packagingInventory.packagingDetailId);
      // console.log(" getting packingwieght id is ", this._packagingWeightCert.packagingDetailId);
      this.getPackagingDetailsByLeadId();
    }
  }

  ngAfterViewInit() {
    this.initializeInventorySignaturePads();
  }

  initializeInventorySignaturePads() {
    // console.log("this.customerSingatureCanvasInvent", this.customerSingatureCanvasInvent);
    this.customerSignaturePadInvent = new SignaturePad(this.customerSingatureCanvasInvent.nativeElement);
    this.surveyMgrSignaturePadInvent = new SignaturePad(this.surveyMgrSingatureCanvasInvent.nativeElement);
  }

  getPackagingDetailsByLeadId() {
    this._movdService.getPackagingDetailsByLeadId(this._leadId).subscribe({
      next: (data: PackingListResponse) => {
        //onsole.log("packaging details by leadId", data);
        if (data && data.packingList.length > 0) {
          //console.log('packaginggg by lead id', data);
          this._packingBoardDTOList = data.packingList;
          this._isDataLoaded = true;
          const filteredData = data.packingList.find((item) => item.packagingDetailId === this._packagingDetailId);
          this._packingDetail = filteredData || new PackagingBoardListDTO();
          this._packagingDetailDTO = filteredData;
          // console.log('this._packingDetail = filteredData;', filteredData);
          this.weightCertificateTitle();
          this._showWeightCertificate = this.weightCertificateTitle();
          this.getPackagingInventoryByPackagingDetailId();
        } else {

        }

      },
      error: (error) => {
        console.error("error getting packingdetail", error);
      }
    });
  }

  updateBingo(item: PackagingInventoryItem): void {
    // Toggle the value of BingoBit
    item.deliveryBingoBit = !item.deliveryBingoBit;

    const currentUserId = localStorage.getItem('currentUserId');
    item.createdBy = Number(currentUserId);
    this._movdService.savePackagingInventoryItem(item).subscribe({
      next: (data: any) => {
        this.getPackagingInventoryItemsByPackagingInventoryId(item);
      },
      error: (error: any) => {
        //this._appComponent.showErrorSwal("Error!", "Error in updating bingo.");
      }
    });
  }

  showMoveById(packagingDetail: any) {
    this._packagingDetailId = packagingDetail.packagingDetailId;
    // console.log("selected packingid ", packagingDetail);
    this._moveDetailId = packagingDetail.moveDetailId;
    this._leadId = packagingDetail.leadId;
    this.getPackagingDetailsByLeadId();
    // this._packingDetail.moveDetailId = this._moveDetailId
  }

  onChangeTab(tabNo: any) {
    this._activeTab = tabNo;
  }

  viewSignature(): void {
    // console.log('Viewing Signature');
  }

  getPackagingInventoryByPackagingDetailId() {
    if (!this._packagingDetailId) {
      return;
    }
    this._movdService.getPackagingInventoryByPackagingDetailId(this._packagingDetailId).subscribe({
      next: (data: any) => {
        // console.log('Packaging inventory get', data);
        if (data) {
          this._packagingInventory = data;
          this._externalNotesInvent = this._packagingInventory.externalNotes;
          this._packagingInventoryId = this._packagingInventory.id;

          if (this._packagingInventory.customerSignature) {
            this.renderCustomerSignature(this._packagingInventory.customerSignature, 'inventory');
          }

          if (this._packagingInventory.managerSignature) {
            this.renderManagerSignature(this._packagingInventory.managerSignature, 'inventory');
          }
          this.getPackagingInventoryItemsByPackagingInventoryId(null);
        } else {
          this._packagingInventory = new PackagingInventory();
          this._externalNotesInvent = null;
          this._packagingInventoryId = 0;
          this.initializeInventorySignaturePads();
          this._inventoryList = [];
        }
      },
      error: (error: any) => {
        console.error("error getting packaging inventory", error);
      }
    });
  }

  // getPackagingInventoryItemsByPackagingInventoryId() {
  //   this._packagingInventoryId = this._packagingInventory.id;
  //   this._movdService.getPackagingInventoryItemsByPackagingInventoryId(this._packagingInventoryId).subscribe({
  //     next: (data: any) => {
  //       // console.log("getPackagingInventoryItemsByPackagingInventoryId", data);
  //       if (data) {
  //         this._inventoryList = data;
  //       }
  //     },
  //     error: (error: any) => {
  //       console.error("error getting inventory items", error);
  //     }
  //   });
  // }

  // addInventoryItem() {
  //   this._inventoryList.push({
  //     itemNo: null,
  //     description: '',
  //     weight: null,
  //     isDeleted: false
  //   });
  //   // console.log("addInventoryItem", this.inventoryList);
  // }


  renderCustomerSignature(signatureDataURL: string, type: string) {
    const img = new Image();
    img.onload = () => {
      if (type === 'inventory') {
        // Check if customerSignaturePadInvent is defined before trying to clear it
        if (this.customerSignaturePadInvent) {
          // Clear the customer signature canvas (optional)
          this.customerSignaturePadInvent.clear();

          // Draw the customer signature
          this.customerSignaturePadInvent.fromDataURL(signatureDataURL);
        } else {

          console.error('customerSignaturePadInvent is not defined');
        }
      }
    };

    img.src = signatureDataURL;
  }

  renderManagerSignature(signatureDataURL: string, type: string) {
    const img = new Image();

    img.onload = () => {
      if (type === 'inventory') {
        if (this.surveyMgrSignaturePadInvent) {
          this.surveyMgrSignaturePadInvent.clear();

          this.surveyMgrSignaturePadInvent.fromDataURL(signatureDataURL);
        } else {
          console.error('surveyMgrSignaturePadInvent is not defined');
        }
      }
    };

    img.src = signatureDataURL;
  }

  // pdfs

  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }

  calculateTotal(itemList: PackagingInventoryItem[]): { totalItems: number, totalWeight: number } {
    let totalItems = 0;
    let totalWeight = 0;

    for (let item of itemList) {
      if (!item.isDeleted && item.itemNo) {
        totalItems++;
        totalWeight += item.weight ?? 0;
      }
    }

    return { totalItems, totalWeight };
  }

  getItemCount(surveyPlace: string, itemList: PackagingInventoryItem[]): number {
    const items = itemList.filter((item) => !item.isDeleted);
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  calculateWeight(unit: string, type: string, itemList: PackagingInventoryItem[]): string | number {
    let totalWeight = 0;

    if (type === 'bySurveyPlace') {
      const items = itemList.filter((item) => !item.isDeleted);
      totalWeight = items.reduce((acc, item) => acc + (item.weight || 0), 0);
    } else {
      totalWeight = itemList.reduce((acc, item) => acc + (item.weight || 0), 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalWeight.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'kg' && this._weightUnit === 'kg') {
      // Convert from kg to lbs
      const convertedWeight = totalWeight * this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} lbs`;
    } else if (unit === 'lbs' && this._weightUnit === 'lbs') {
      // Convert from lbs to kg
      const convertedWeight = totalWeight / this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} kg`;
    }

    return result;
  }

  // generateInventoryPDF() {
  //   if (this.pdfContentInventory && this.pdfContentInventory.nativeElement) {
  //     const contentElement = this.pdfContentInventory.nativeElement;

  //     if (contentElement) {
  //       contentElement.style.visibility = 'visible';
  //       contentElement.offsetHeight;

  //       // Call your PDF conversion service here
  //       this._pdfService.ConvertHTMLToPDF(contentElement, 'Inventory List');

  //       // Set visibility back to hidden after generating PDF
  //       contentElement.style.visibility = 'hidden';
  //     } else {
  //       console.error('Div not found');
  //     }
  //   }
  // }

  // generateWeightCertificatePDF() {
  //   if (this.pdfContentWeight && this.pdfContentWeight.nativeElement) {
  //     const contentElement = this.pdfContentWeight.nativeElement;

  //     if (contentElement) {

  //       this._pdfService.ConvertHTMLToPDF(contentElement, 'Weight Certificate');
  //     } else {
  //       console.error('Div not found');
  //     }
  //   }
  // }
  weightCertificateTitle(): boolean {
    // console.log('moveTransport', this._packingDetail.moveTransport);
    if (this._packingDetail.moveTransport === eTransportType.Air) {
      this._weightCertTitle = 'AIR WEIGHT CERTIFICATE';
      return true;
    } else if (this._packingDetail.moveTransport === eTransportType.Sea) {
      this._weightCertTitle = 'SEA WEIGHT CERTIFICATE';
      return true;
    } else if (this._packingDetail.moveTransport === eTransportType.SeaLCL) {
      this._weightCertTitle = 'SEA(LCL) WEIGHT CERTIFICATE';
      return true;
    } else {
      // this._weightCertTitle = 'WEIGHT CERTIFICATE';
      return false;
    }
  }
  onChangeTabForType(packingType: ePackingType | number | null) {
    // console.log('selected packing Type', packingType);

    if (packingType !== 0 && packingType) {
      switch (packingType) {
        case ePackingType.AssembleDisassemble:
          this._filteredInventoryList = this._assembleDisassembleList.filter((item) => item.isDeleted === false);
          break;
        case ePackingType.CratedItem:
          this._filteredInventoryList = this._cratedItemList.filter((item) => item.isDeleted === false);
          break;
        case ePackingType.Electronic:
          this._filteredInventoryList = this._electronicList.filter((item) => item.isDeleted === false);
          break;
        case ePackingType.HighValue:
          this._filteredInventoryList = this._highValueList.filter((item) => item.isDeleted === false);
          break;
        default:
          this._filteredInventoryList = [];
          break;
      }

      this._selectedPackingType = packingType;
    } else {
      // Handle the case when no specific packaging type is selected
      this._filteredInventoryList = [];
      this._selectedPackingType = null;
    }

    // console.log('filtered inventory list', this._filteredInventoryList);
  }
  addInventoryItem() {
    let packingType: number | null = null;
    if (this._selectedPackingType) {
      packingType = this._selectedPackingType;
    }
    // console.log('packing type', packingType);

    let newItem = {
      itemNo: null,
      description: '',
      weight: null,
      isDeleted: false,
      id: 0,
      packagingInventoryId: 0,
      packagingType: packingType,
      make: null,
      model: null,
      remarks: null,
      value: null,
      dimensions: null,
      cuft: null,
      packingBingoBit: false,
      deliveryBingoBit: false,
      createdBy: null,
      modificationDate: null,
      modifiedBy: null,
      deletionDate: null,
      quantity: 0,
    };
    // this._inventoryList.push(newItem);
    switch (packingType) {
      case ePackingType.AssembleDisassemble:
        this._assembleDisassembleList.push(newItem);
        // console.log(' _assembleDisassembleList list', this._assembleDisassembleList);
        break;
      case ePackingType.CratedItem:
        this._cratedItemList.push(newItem);
        // console.log(' _cratedItemList list', this._cratedItemList);
        break;
      case ePackingType.Electronic:
        this._electronicList.push(newItem);
        // console.log(' _electronicList list', this._electronicList);
        break;
      case ePackingType.HighValue:
        this._highValueList.push(newItem);
        // console.log(' _highValueList list', this._highValueList);
        break;
      default:
        newItem.packagingType = null;
        this._inventoryList.push(newItem);
        // this._inventoryList.sort((a, b) => (a.itemNo ?? Infinity) - (b.itemNo ?? Infinity));
        // console.log('all inventory list', this._inventoryList);
        break;
    }

    this.onChangeTab(packingType);
    // console.log("addInventoryItem", newItem);
  }

  deleteInventoryItem(rowIndex: number, itemList: any[]) {
    // console.log('rowIndex', rowIndex, itemList)
    if (itemList[rowIndex]) {
      const deletedItem = itemList.splice(rowIndex, 1)[0]; // Remove and get the deleted item

      if (deletedItem?.id) {
        deletedItem.isDeleted = true;
        this._movdService.savePackagingInventoryItem(deletedItem).subscribe({
          next: (data: any) => {
            this._appComponent.showSuccessSwal('Delete', 'Item');
            this.getPackagingInventoryItemsByPackagingInventoryId(deletedItem);
          },
          error: (error: any) => {
            this._appComponent.showErrorSwal('Deleting', 'record');
            // console.log("Error", error);
          },
        });
      }
    }

  }

  saveInventoryItem(rowIndex: number, activeTab: number) {
    const currentUserId = localStorage.getItem('currentUserId');
    const packagingInventoryId = this._packagingInventory.id;

    // Choose the correct list based on the active tab
    let currentList: PackagingInventoryItem[] = [];

    switch (activeTab) {
      case 1:
        currentList = this._inventoryList;
        break;
      case 2:
        currentList = this._assembleDisassembleList;
        break;
      case 3:
        currentList = this._cratedItemList;
        break;
      case 4:
        currentList = this._electronicList;
        break;
      case 5:
        currentList = this._highValueList;
        break;
      default:
        break;
    }

    const item: PackagingInventoryItem = currentList[rowIndex];
    item.createdBy = Number(currentUserId);
    item.packagingInventoryId = packagingInventoryId;
    // Set the correct packagingType based on the active tab
    switch (activeTab) {

      case 2:
        item.packagingType = ePackingType.AssembleDisassemble;
        break;
      case 3:
        item.packagingType = ePackingType.CratedItem;
        break;
      case 4:
        item.packagingType = ePackingType.Electronic;
        break;
      case 5:
        item.packagingType = ePackingType.HighValue;
        break;
      default:
        break;
    }

    if (!item.itemNo) {
      this._appComponent.showErrorDialog('Error', 'Item details cannot be null / Zero');
      return;
    }

    // console.log('Packaging inventory item before saved:', item);
    this._movdService.savePackagingInventoryItem(item).subscribe({
      next: (data: any) => {
        this._appComponent.showSuccessSwal('Save', 'Item');
        this.getPackagingInventoryItemsByPackagingInventoryId(item);
        // this.createPdf();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('Saving', 'record');
        // console.log("Error", error);
      },
    });
  }


  getPackagingInventoryItemsByPackagingInventoryId(item: any | null) {
    this._inventoryList = [];
    this._assembleDisassembleList = [];
    this._cratedItemList = [];
    this._electronicList = [];
    this._highValueList = [];

    this._packagingInventoryId = this._packagingInventory.id;
    // console.log("_packagingInventoryId", this._packagingInventoryId);
    this._movdService.getPackagingInventoryItemsByPackagingInventoryId(this._packagingInventoryId).subscribe({
      next: (data: any) => {
        let filteredData = data.filter((item: any) => item.isDeleted === false);
        if (filteredData && filteredData.length > 0) {
          this._inventoryList = filteredData;
          // console.log('inveeentory list', this._inventoryList);
          this.filterAndAssignItemsByPackagingType();
          if (item && item.id) {
            // console.log('item', item);
            let mappedList = this._displayInventoryList.map((listItem, index) => {
              if (listItem.id !== item.id) {
                return this._inventoryList[index];
              } else {
                return item;
              }
            });
            // console.log('mapped list', mappedList);
            this._displayInventoryList = mappedList;
          } else {

            this._displayInventoryList = this._inventoryList;
          }

          // if (this.inventoryList.length === 0) {
          //   this.addInventoryItem();
          // }
          // } else {
          //   if (this.inventoryList.length === 0) {
          //     this.addInventoryItem();
          //   }
        }
        //console.log('Packaging inventory items get', data);
        //console.log('filteredData', this._packagingInventory.id);
      },
      error: (error: any) => {
        console.error("error gettting packing inventory items", error);
        if (error && error.status === 404 && this._inventoryList.length === 0) {
          this.addInventoryItem();
        }
      }
    });
  }
  filterAndAssignItemsByPackagingType() {
    // Clear the lists
    // this._inventoryList = [];
    this._assembleDisassembleList = [];
    this._cratedItemList = [];
    this._electronicList = [];
    this._highValueList = [];

    // Filter and assign items to the corresponding lists
    this._inventoryList.forEach(item => {
      switch (item.packagingType) {
        case ePackingType.AssembleDisassemble:
          this._assembleDisassembleList.push(item);
          break;
        case ePackingType.CratedItem:
          this._cratedItemList.push(item);
          break;
        case ePackingType.Electronic:
          this._electronicList.push(item);
          break;
        case ePackingType.HighValue:
          this._highValueList.push(item);
          break;
        default:
          this._inventoryList.push(item);
          break;
      }
    });
  }

  createPdf() {
    return new Promise<void>((resolve, reject) => {
      // Include your docDefinition code here
      var docDefinition: any = {
        content: [
          this._companySetting.logo && this._companySetting.logo.startsWith('data:image') ? {
            image: this._companySetting.logo,
            width: 110,
            alignment: 'center',
          } : {
            text: 'Company Logo',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10],
          },
          {
            text: 'Delivery List',
            style: 'header',
            alignment: 'left',
            margin: [0, 10, 0, 10] // [left, top, right, bottom]
          },
          {
            table: {
              layout: 'lightHorizontalLines',
              widths: ['50%', '50%'], // Two columns
              body: []
            },
            margin: [0, 10, 0, 20] // [left, top, right, bottom]
          }
        ],
        styles: {
          header: {
            fontSize: 20,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          columnText: {
            fontSize: 11,
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          smallHeadings: {
            bold: true,
            fontSize: 14,
            color: 'black'
          }
        },
        footer: function (currentPage: any, pageCount: any) {
          return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 10, alignment: 'right', margin: [0, 5, 40, 0] };
        }
      };

      // Your dynamic data for the second table
      var firstTableData = [];

      // Check conditions and add data accordingly
      //firstTableData.push(
      //  [{ text: 'Move Id:', style: 'columnText', alignment: 'left' },
      //  { text: '#' + this._packagingDetailDTO?.moveDetailId || ' - ', style: 'columnText', alignment: 'left' }]
      //);
      firstTableData.push(
        [{ text: 'Move Id:', style: 'columnText', alignment: 'left' },
        { text: (this._packagingDetailDTO?.moveDetailId ? this.generalService.formatId(this._packagingDetailDTO.moveDetailId, 'move') : '-'), style: 'columnText', alignment: 'left' }]
      );


      //firstTableData.push(
      //  [{ text: 'Customer Id:', style: 'columnText', alignment: 'left' },
      //  { text: '#' + this._packagingDetailDTO?.customerId || ' - ', style: 'columnText', alignment: 'left' }]
      //);

      firstTableData.push(
        [{ text: 'Customer Id:', style: 'columnText', alignment: 'left' },
        { text: (this._packagingDetailDTO?.customerId ? this.generalService.formatId(this._packagingDetailDTO.customerId, 'customer') : ' - '), style: 'columnText', alignment: 'left' }]
      );



      firstTableData.push(
        [{ text: 'Customer Name:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.customerName || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Account Name:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.accountName || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Move Type:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveType(this._packagingDetailDTO?.moveType || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Move Category:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveCategoryType(this._packagingDetailDTO?.moveCategory || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Transport Type:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapTransportType(this._packagingDetailDTO?.moveTransport || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Origin:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.origin || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Destination:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.destination || ' - ', style: 'columnText', alignment: 'left' }]
      );
      if (this._packagingDetailDTO.categoryType == eMoveCategoryType.DoorToDoorExport || this._packagingDetailDTO.categoryType == eMoveCategoryType.DoorToPort && this._packagingDetailDTO.transportType == eTransportType.Sea || this._packagingDetailDTO.transportType == eTransportType.SeaLCL || this._packagingDetailDTO.transportType == eTransportType.Air) {
        firstTableData.push(
          [{ text: 'POL (Port of Loading)', style: 'columnText', alignment: 'left' },
          { text: this._packagingDetailDTO?.portOfLoading || ' - ', style: 'columnText', alignment: 'left' }]
        );
        firstTableData.push(
          [{ text: 'POD (Port of Discharge)', style: 'columnText', alignment: 'left' },
          { text: this._packagingDetailDTO?.portOfDischarge || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      firstTableData.push(
        [{ text: 'Place Of Delivery', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.placeOfDelivery || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Client Notes:', style: 'columnText', alignment: 'left' },
        { text: this._externalNotesInvent || ' - ', style: 'columnText', alignment: 'left' }]
      );

      if (docDefinition && docDefinition.content && docDefinition.content[2] && docDefinition.content[2].table && docDefinition.content[2].table.body) {
        // Add dynamic data to the second table
        firstTableData.forEach(data => {
          docDefinition.content[2].table.body.push(data);
        });
      }
      // console.log('first table data', firstTableData);

      // Inventory Items List
      if (this._inventoryList.length > 0) {
        docDefinition.content.push(
          { text: 'Inventory Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] }
        );

        var inventoryListData = [];
        for (let row of this._inventoryList) {
          if (row.isDeleted === false) {
            const categoryLabel = this.packagingTypeOptions.find(option => option.value === row.packagingType)?.label || '-';
            inventoryListData.push([row.itemNo, row.description, row.weight, categoryLabel]);
          }
        }

        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['15%', '35%', '20%', '30%'],
            body: [
              [
                { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
                { text: 'Category', style: 'tableHeader', alignment: 'left' }
              ],
              // Dynamic data goes here
              ...inventoryListData,
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });

        const totalItems = this.calculateTotal(this._inventoryList).totalItems;
        const totalWeight = this.calculateWeight(this._weightUnit, 'total', this._inventoryList);

        docDefinition.content.push(
          { text: `Total Items: ${totalItems !== undefined ? totalItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' },
          { text: `Total Weight: ${totalWeight !== undefined ? totalWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
        );
      }
      docDefinition.content.push({ text: '', pageBreak: 'after' });
      // Assemble / disassemble Items Table
      if (this._assembleDisassembleList.length > 0) {
        docDefinition.content.push(
          { text: 'Assemble/ Disassemble Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] }
        );

        var assembleListData = [];
        for (let row of this._assembleDisassembleList) {
          if (row.isDeleted === false) {
            assembleListData.push([row.itemNo, row.description, row.weight]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['33%', '33%', '34%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                  { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' }
                ],
                // Dynamic data goes here
                ...assembleListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          }
        );

        if (docDefinition && docDefinition.content && docDefinition.content[3] && docDefinition.content[3].table && docDefinition.content[3].table.body) {
          // Add dynamic data to the second table
          assembleListData.forEach(data => {
            docDefinition.content[3].table.body.push(data);
          });
        }

        const totalItems = this.calculateTotal(this._assembleDisassembleList).totalItems;
        const totalWeight = this.calculateWeight(this._weightUnit, 'total', this._assembleDisassembleList);



        docDefinition.content.push(
          [
            { text: `Total Items: ${totalItems !== undefined ? totalItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
        docDefinition.content.push(
          [
            { text: `Total Weight: ${totalWeight !== undefined ? totalWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
      }


      // Crated Items Table
      if (this._cratedItemList.length > 0) {
        docDefinition.content.push(
          { text: 'Crated Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        );
        var cratedListData = [];
        for (let row of this._cratedItemList) {
          if (row.isDeleted === false) {
            cratedListData.push([row.itemNo, row.description, row.dimensions, row.cuft, row.weight, row.remarks]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['16%', '17%', '17%', '16%', '16%', '18%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                  { text: 'Dimensions', style: 'tableHeader', alignment: 'left' },
                  { text: 'Cuft', style: 'tableHeader', alignment: 'left' },
                  { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
                  { text: 'Remarks', style: 'tableHeader', alignment: 'left' }
                ],
                // Dynamic data goes here
                ...cratedListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          },
        );

        if (docDefinition && docDefinition.content && docDefinition.content[6] && docDefinition.content[6].table && docDefinition.content[6].table.body) {
          // Add dynamic data to the second table
          cratedListData.forEach(data => {
            docDefinition.content[6].table.body.push(data);
          });
        }
        const totalcratedItems = this.calculateTotal(this._cratedItemList).totalItems;
        const totalcratedItemsWeight = this.calculateWeight(this._weightUnit, 'total', this._cratedItemList);

        docDefinition.content.push(
          [
            { text: `Total Items: ${totalcratedItems !== undefined ? totalcratedItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
        docDefinition.content.push(
          [
            { text: `Total Weight: ${totalcratedItemsWeight !== undefined ? totalcratedItemsWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
      }
      //Electronic Items List
      if (this._electronicList.length > 0) {
        docDefinition.content.push(
          { text: 'Electronic Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        );
        var electronicListData = [];
        for (let row of this._electronicList) {
          if (row.isDeleted === false) {
            electronicListData.push([row.itemNo, row.description, row.make, row.model, row.weight, row.remarks]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['16%', '17%', '17%', '16%', '16%', '18%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                  { text: 'Make', style: 'tableHeader', alignment: 'left' },
                  { text: 'Model/Serial', style: 'tableHeader', alignment: 'left' },
                  { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
                  { text: 'Remarks', style: 'tableHeader', alignment: 'left' }
                ],
                // Dynamic data goes here
                ...electronicListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          },
        );

        if (docDefinition && docDefinition.content && docDefinition.content[6] && docDefinition.content[6].table && docDefinition.content[6].table.body) {
          // Add dynamic data to the second table
          electronicListData.forEach(data => {
            docDefinition.content[6].table.body.push(data);
          });
        }
        const totalElectroniItems = this.calculateTotal(this._electronicList).totalItems;
        const totalElectroniItemsWeight = this.calculateWeight(this._weightUnit, 'total', this._electronicList);

        docDefinition.content.push(
          [
            { text: `Total Items: ${totalElectroniItems !== undefined ? totalElectroniItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
        docDefinition.content.push(
          [
            { text: `Total Weight: ${totalElectroniItemsWeight !== undefined ? totalElectroniItemsWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
      }
      //High Values Items
      if (this._highValueList.length > 0) {
        docDefinition.content.push(
          { text: 'High Value Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        );
        var highValueListData = [];
        for (let row of this._highValueList) {
          if (row.isDeleted === false) {
            highValueListData.push([row.itemNo, row.description, row.dimensions, row.weight, row.value]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['15%', '25%', '25%', '15%', '20%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                  { text: 'Dimensions', style: 'tableHeader', alignment: 'left' },
                  { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
                  { text: 'Value ' + 'in ' + '(' + (this._currency) + ')', style: 'tableHeader', alignment: 'left' }
                ],
                // Dynamic data goes here
                ...highValueListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          },
        );

        if (docDefinition && docDefinition.content && docDefinition.content[6] && docDefinition.content[6].table && docDefinition.content[6].table.body) {
          // Add dynamic data to the second table
          highValueListData.forEach(data => {
            docDefinition.content[6].table.body.push(data);
          });
        }
        const totalHighItems = this.calculateTotal(this._highValueList).totalItems;
        const totalHighItemsWeight = this.calculateWeight(this._weightUnit, 'total', this._highValueList);

        docDefinition.content.push(
          [
            { text: `Total Items: ${totalHighItems !== undefined ? totalHighItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
        docDefinition.content.push(
          [
            { text: `Total Weight: ${totalHighItemsWeight !== undefined ? totalHighItemsWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
      }
      docDefinition.content.push({
        columns: [
          {
            stack: [
              {
                text: 'Customer Signature:', style: 'columnText', alignment: 'left',
                margin: [0, 30, 0, 0]
              },
              this._packagingInventory.customerSignature ? {
                image: this._packagingInventory.customerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0]
              } : null
            ]
          },
          {
            stack: [
              {
                text: 'Manager Signature:', style: 'columnText', alignment: 'right',
                margin: [0, 30, 0, 0]
              },
              this._packagingInventory.managerSignature ? {
                image: this._packagingInventory.managerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0],
                alignment: 'right'
              } : null
            ]
          }
        ]
      });
      // console.log('docDefinition:', docDefinition);

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      this.pdfDocGenerator = pdfDocGenerator;
      resolve();
    });
  }

  async viewPdf() {
    try {
      // Always create a new PDF
      await this.createPdf();

      // Open the newly created PDF
      await this.pdfDocGenerator.open();
    } catch (error) {
      this._appComponent.showAlertMessageDialog('View PDF', 'An error occurred while creating or opening the PDF. Please try again.');
    }
  }

  async downloadPdf() {
    try {
      // Always create a new PDF
      await this.createPdf();
      // Download the newly created PDF
      await this.pdfDocGenerator.download();
    } catch (error) {


      this._appComponent.showAlertMessageDialog('Export PDF', 'An error occurred while creating or downloading the PDF. Please try again.');
    }
  }

}
