<ng-template #tasksModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="tasksModalLabel">
      <div class="TitleIcon">
        <lord-icon src="https://cdn.lordicon.com/winbdcbm.json" trigger="hover" colors="primary:#5f606c"
          class="solid_icons"></lord-icon>
        <span class="TitleText">Task</span>
      </div>
    </h5>
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row">
      <input type="hidden" id="_task.id" />
      <div class="col-md-12">
        <input type="text" class="form-control no-border" [(ngModel)]="_task.title" id="_task.title" autocomplete="on"
          placeholder="Enter your task" required #taskTitle="ngModel" />
        <div *ngIf="taskTitle.invalid && (taskTitle.dirty || taskTitle.touched)" class="error-message error"
          style="color: red">
          <div *ngIf="taskTitle.errors?.['required']">* required.</div>
        </div>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-6">
        <label for="_task.dueDate" class="form-label no-border">Due Date</label>
        <input type="date" class="form-control" [(ngModel)]="_task.dueDate" id="_task.dueDate" autocomplete="on"
          value="{{ _task.dueDate }}" placeholder="{{ _task.dueDate }}" required />
      </div>
      <div class="col-md-6" *ngIf="_taskUpdate">
        <label for="_task.label" class="form-label no-border">Mark as Done</label>
        <!-- <input type="checkbox" [(ngModel)]="_task.done" id="_task.done"
          autocomplete="on" value="{{ _task.done }}"  (change)="toggleTaskDone(_task)" /> -->
        <ng-select class="left-align input-color" [(ngModel)]="_task.done" [bindLabel]="_task.done ? 'Done' : 'None'"
          (ngModelChange)="toggleTaskDone(_task)" id="_task.done">
          <ng-option [value]="true">Done</ng-option>
          <ng-option [value]="false">None</ng-option>
        </ng-select>

        <span class="checkmark" [ngClass]="{ 'task-done': _task.done, 'overdue': isOverdue(_task) }"></span>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-6">
        <label for="_task.type" class="input-form-labels">Type</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.type" id="_task.type">
          <ng-option value="To-do" selected>To-do</ng-option>
          <ng-option value="Call">Call</ng-option>
          <ng-option value="Email">Email</ng-option>
        </ng-select>
      </div>
      <div class="col-md-6">
        <label for="_task.priority" class="input-form-labels">Priority</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.priority" id="_task.priority">
          <ng-option value="None" selected>None</ng-option>
          <ng-option value="Low">Low</ng-option>
          <ng-option value="Medium">Medium</ng-option>
          <ng-option value="High">High</ng-option>
        </ng-select>
      </div>
      <!-- <div class="col-md-4">
        <label for="_task.queue" class="input-form-labels">Queue</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.queue" id="_task.queue">
          <ng-option value="None" selected>None</ng-option>
          <ng-option value="Create a new queue">Create a new queue</ng-option>
        </ng-select>
      </div> -->
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-12">
        <textarea class="form-control no-border" [(ngModel)]="_task.details" d="_task.details" autocomplete="on"
          value="{{ _task.details }}" placeholder="Notes..." required></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveTask()">
      <i class="mdi mdi-content-save"> </i> &nbsp;{{_saveTaskTitle}}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<div class="row" *ngIf="isDataLoaded">
  <div class="col-md-9">
    <h5>DASHBOARD</h5>
  </div>
  <div class="col-md-3">
    <div class="input-group range-selection">
      <input type="text" class="form-control clickable custom-border" placeholder="Select Date Range" name="datepicker"
        ngbDatepicker #datepicker="ngbDatepicker" [value]="generalService.formatDate(_statsFromDate,_statsToDate)"
        (dateSelect)="onDateSelect($event)" [autoClose]="'outside'" [displayMonths]="2" [dayTemplate]="daysTemplate"
        outsideDays="hidden" [startDate]="fromDate!" (click)="datepicker.toggle()" tabindex="-1">

      <button class="input-group-text custom-border" type="button" title="icon-calendar" (click)="datepicker.toggle()">
        <i class="feather icon-calendar icon-md" [style.color]="'#1AC0A1'"></i>
      </button>

      <ng-template #daysTemplate let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="
          isHovered(date) ||
          isInside(date)
        " (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>


  </div>
  <div class="col-12 col-xl-12 stretch-card mt-4">
    <div class="row flex-grow-1">
      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-baseline">
              <h6 class="card-title mb-0">In Progress-Leads</h6>

            </div>
            <div class="row" *ngIf="_dashboardStats">
              <div class="col-6 col-md-12 col-xl-5">
                <h3 class="mb-2">{{_dashboardStats.leadsInProgress}}</h3>
                <div class="d-flex align-items-baseline">
                  <p class="text-success">
                    <!-- <span>{{_dashboardStats.leadsInProgress}}%</span> -->
                    <!-- <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i> -->
                  </p>
                </div>
              </div>
              <div class="col-6 col-md-12 col-xl-7">
                <div class="mt-md-3 mt-xl-0">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-baseline">
              <h6 class="card-title mb-0">Total Moves</h6>

            </div>
            <div class="row" *ngIf="_dashboardStats">
              <div class="col-6 col-md-12 col-xl-5">
                <h3 class="mb-2">{{_dashboardStats.totalMoves}}</h3>
                <div class="d-flex align-items-baseline">
                  <p class="text-success">
                    <!-- <span>{{_dashboardStats.totalMoves}}%</span> -->
                    <!-- <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i> -->
                  </p>
                </div>
              </div>
              <div class="col-6 col-md-12 col-xl-7">
                <div class="mt-md-3 mt-xl-0">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-baseline">
              <h6 class="card-title mb-0">Lead to Move Conversion Rate</h6>

            </div>
            <div class="row" *ngIf="_dashboardStats">
              <div class="col-6 col-md-12 col-xl-5">
                <h3 class="mb-2">{{_dashboardStats.leadToMoveConversionRate}}%</h3>
                <div class="d-flex align-items-baseline">
                  <p class="text-success">
                    <!-- <span>{{_dashboardStats.leadToMoveConversionRate}}%</span> -->
                    <!-- <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i> -->
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-4 col-xl-4 grid-margin stretch-card">
    <div class="card">
      <div class="card-body text-center">
        <h6 class="card-title">LEADS</h6>
        <apx-chart [series]="donutChartOptionsLeads.series" [chart]="donutChartOptionsLeads.chart"
          [colors]="donutChartOptionsLeads.colors" [stroke]="donutChartOptionsLeads.stroke"
          [legend]="donutChartOptionsLeads.legend" [dataLabels]="donutChartOptionsLeads.dataLabels"
          [labels]="donutChartOptionsLeads.labels"></apx-chart>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-xl-4 grid-margin stretch-card">
    <div class="card">
      <div class="card-body text-center">
        <h6 class="card-title">MOVES</h6>
        <apx-chart [series]="donutChartOptionsMoves.series" [chart]="donutChartOptionsMoves.chart"
          [colors]="donutChartOptionsMoves.colors" [stroke]="donutChartOptionsMoves.stroke"
          [legend]="donutChartOptionsMoves.legend" [dataLabels]="donutChartOptionsMoves.dataLabels"
          [labels]="donutChartOptionsMoves.labels"></apx-chart>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-xl-4 grid-margin stretch-card">
    <div class="card" style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); transition: 0.3s;">
      <div class="card-body blurred-container" style="padding: 2em; background-color: #f8f9fa; position: relative;">
        <h6 class="card-heading mb-0" style="text-align: center; font-size: 20px;">
          <strong>Labour Availability</strong><br />
          <span class="coming-soon-overlay">Coming Soon</span>
        </h6>
        <hr>
        <div class="blurred-content">
          <apx-chart [series]="barChartOptions.series" [chart]="barChartOptions.chart" [colors]="barChartOptions.colors"
            [grid]="barChartOptions.grid" [xaxis]="barChartOptions.xaxis" [yaxis]="barChartOptions.yaxis"
            [plotOptions]="barChartOptions.plotOptions"></apx-chart>
        </div>

      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col-xl-6 grid-margin stretch-card">
    <div class="tasks-container card-body">
      <div class="card-body text-center">
        <h6 class="card-title">CSAT For MOVERS</h6>

        <apx-chart [series]="lineChartOptions.series" [chart]="lineChartOptions.chart"
          [colors]="lineChartOptions.colors" [grid]="lineChartOptions.grid" [xaxis]="lineChartOptions.xaxis"
          [yaxis]="lineChartOptions.yaxis" [markers]="lineChartOptions.markers" [legend]="lineChartOptions.legend"
          [stroke]="lineChartOptions.stroke"></apx-chart>

      </div>
    </div>
  </div>
  <div class="col-xl-6 grid-margin stretch-card">
    <div class="tasks-container card-body" style="position: relative;">
      <div class="card-body text-center">
        <h6 class="card-title">Average Cost Of Packing Material Per Transport Type</h6>
        <span class="coming-soon-overlay-packing">Coming Soon</span>
        <div class="blurred-content">
          <apx-chart [series]="pieChartOptions.series" [chart]="pieChartOptions.chart" [colors]="pieChartOptions.colors"
            [stroke]="pieChartOptions.stroke" [legend]="pieChartOptions.legend"
            [dataLabels]="pieChartOptions.dataLabels"></apx-chart>
        </div>

      </div>
    </div>
  </div>

</div>




<div class="mt-3">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="mt-2 mb-3">
            <h4 class="task-title">Tasks</h4>
          </div>

          <div class="calendar-container mt-2 p-0">
            <app-task [customerId]="_localCustomerId" [isDeal]="false" type="dashboard"></app-task>
            <button class="btn btn-sm btn-primary float-end" (click)="navigateToTaskBoard()">
              <i class="mdi mdi-file-eye"></i> &nbsp; More Tasks
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="calendar-container mt-2 p-0">
            <full-calendar class="calendar" [options]="calendarOptions"></full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>