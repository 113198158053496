import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from 'src/app/app.component';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { MoveAttachment, eAttachmentType } from '../../models/move-attachments.model';
import { LeadDetail, eProcessStatus } from '../../models/lead-detail.model';
import { MoveSurvey } from '../../models/move-survery.model';
import { SurveyItem } from '../../models/survery-item.model';
import { SurveyMaterial } from '../../models/survery-material.model';
import { MovdService } from '../../services/movd.service';
import SignaturePad from 'signature_pad';
import { PdfService } from 'src/services/pdf.service';
import { CompanySetting, getCurrencySymbol, getDimensionUnitShortLabel, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../../models/companysetting.model';
import { EnumMappingService } from '../../services/movd.enum.service';
import { GeneralService } from 'src/services/general.service';
import { Address } from 'src/app/views/crm/contact/contact.models';
import { AuthService } from 'src/services/auth.service';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { LeadBoardDTO, LeadBoardResponse } from '../../lead-board/lead-board.model';
import { AllSurveyPlacesResponse, SurveyPlace } from '../../models/movd-comp-admin.model';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

@Component({
  selector: 'app-add-survey',
  templateUrl: './add-survey.component.html',
  styleUrls: ['./add-survey.component.scss'],
})

export class AddSurveyComponent implements OnInit {
  // selectedInventory: { id: number; title: string; quantity: number; weight: number; estVolume: number, area: string | null }[] = [];
  // _filteredAreaItems: { id: number; title: string; quantity: number; weight: number; estVolume: number, area: string | null }[] = [];

  _isDataLoaded: boolean = true;
  _typeForInfoHeader = "leadDetail";
  _assignmentType = "leadDetail";
  _contentDisable: boolean = false;


  _leadId: number = 0;
  _leadDetailId: number = 0;
  _selectedSurveyPlace!: SurveyPlace | null;
  _selectedSurveyPlaceId!: number | null;
  _selectedItem: SurveyMaterial | null = null;
  _selectedMoveDetailId: any;
  kgToLbsConversionFactor: number = 2.20462; // 1 kg = 2.20462 lbs
  cuftToCbmConversionFactor: number = 0.0283168; // 1 cuft = 0.0283168 cbm

  // objects
  _leadDetail: LeadDetail = new LeadDetail();
  _moveSurvey = new MoveSurvey();
  _surveyItem = new SurveyItem();
  _surveyMaterial = new SurveyMaterial();
  _moveAttachment = new MoveAttachment();
  _surveyPlace = new SurveyPlace();
  _surveyAreaItems: SurveyMaterial = new SurveyMaterial();

  // lists
  _surveyMaterials: any[] = [];
  _dbSurveyItemsByPlace: SurveyItem[] = [];
  _surveyPlacesList: SurveyPlace[] = [];

  _areaItems: SurveyMaterial[] = [];
  _materials: SurveyMaterial[] = [new SurveyMaterial()];
  _labour: SurveyMaterial[] = [new SurveyMaterial()];
  _vehicles: SurveyMaterial[] = [new SurveyMaterial()];
  _containers: SurveyMaterial[] = [new SurveyMaterial()];
  _attachments: MoveAttachment[] = [];

  _containerSizes: string[] = ['20\'', '40\'', '40\' HC'];
  _selectedContainerSize: string = '';

  _materialRows: number[] = [0];
  _labourRows: number[] = [0];

  // variable 
  _imageToDisplay: any;
  _imagePreviewTitle: string = '';

  @ViewChild('customerSingatureCanvas', { static: true }) customerSingatureCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('surveyMgrSingatureCanvas', { static: true }) surveyMgrSingatureCanvas!: ElementRef<HTMLCanvasElement>;
  customerSignaturePad!: SignaturePad;
  surveyMgrSignaturePad!: SignaturePad;


  // pdf service
  @ViewChild('pdfComponent') pdfContent!: any;
  @ViewChild('basicModal') basicModal: any;
  _basicModalRef: NgbModalRef | undefined;

  _totalWeight = 0;
  _currentDate: string | null = null;
  _validTillDate: string | null = null;

  _companySetting = new CompanySetting();
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _dimensionsUnit: string = '';
  _currency: any;
  generatingPDF = false;

  _originAddresses: Address[] = [];
  _destinationAddresses: Address[] = [];
  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;
  _leadBoardDTOList: LeadBoardDTO[] = [];
  customerId: number = 0;
  _selectedLeadDetailId: number = 0;
  previousUrl: string = '';
  constructor(
    private _router: Router,
    private _modalService: NgbModal,
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    private _route: ActivatedRoute,
    private _pdfService: PdfService,
    public enumMappingService: EnumMappingService,
    public generalService: GeneralService,
    private _authService: AuthService,
    private breadcrumbService: BreadcrumbService,
  ) {
    this._userRole = localStorage.getItem('role');

  }

  ngOnInit(): void {
    this.previousUrl = this.breadcrumbService.getPreviousUrl();
    this.updateBreadcrumbs();

    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();
    // Set permission variables
    this._readPermission = permissions.includes('Survey Form.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Survey Form.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('Survey Form.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Survey Form.Delete');
    this._route.queryParams.subscribe(
      params => {
        this._leadDetailId = Number(params['leadDetailId']);
        this._leadId = Number(params['leadId']);
        if (this._leadId && this._leadId !== 0) {
          this.getLeadDetailsByLeadId();
        }
        if (this._leadDetailId && this._leadDetailId !== 0) {
          // setTimeout(() => {
          this.getAllSurveyPlaces();
          this.getLeadDetailById(this._leadDetailId);
          this.getMoveSurveys();
          this.getCompanySetting();



          // }, 0);
        }
      });
  }

  ngAfterViewInit() {
    this.customerSignaturePad = new SignaturePad(this.customerSingatureCanvas.nativeElement);
    this.surveyMgrSignaturePad = new SignaturePad(this.surveyMgrSingatureCanvas.nativeElement);
  }
  private updateBreadcrumbs(): void {
    if (this.previousUrl.includes('/moves-board')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Moves Dashboard', url: '/moves-board' },
        { text: 'Survey Form', url: '' },
      ]);
    }
    else if (this.previousUrl.includes('/survey-list')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Survey List', url: '/survey-list' },
        { text: 'Survey Form', url: '' },
      ]);
    }
  }

  clearSingature(type: string) {
    if (type === 'cust') {
      this.customerSignaturePad.clear();
    } else {
      this.surveyMgrSignaturePad.clear();
    }
  }
  async showUnauthorizedDialog() {
    await this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to Add Item', '');
    // console.log("go to dashboard. no read permission");
  }
  saveSingature() {
    const custSignatureDataURL = this.customerSignaturePad.toDataURL();
    const surveyMgrSignatureDataURL = this.surveyMgrSignaturePad.toDataURL();

    this._moveSurvey.customerSignature = custSignatureDataURL;
    this._moveSurvey.managerSignature = surveyMgrSignatureDataURL;

    // console.log("custSignatureDataURL url", custSignatureDataURL);
    // console.log("surveyMgrSignatureDataURL url", surveyMgrSignatureDataURL);
    this.saveMoveSurvey();
  }

  getAllSurveyPlaces(): void {
    // const companyId = Number(localStorage.getItem('companyId'));
    this._movdService.getAllSurveyPlaces(null, null, null).subscribe({
      next: (data: AllSurveyPlacesResponse) => {
        // console.log("survey places", data);
        if (data && data.surveyPlaces.length > 0) {
          this._surveyPlacesList = data.surveyPlaces;
          this._selectedSurveyPlaceId = this._surveyPlacesList[0].id;
          this.getSurveyItemOnNgInit();
        }
      },
      error: (error) => {

        console.error("error", error);
      }
    });
  }

  onSurveyPlaceChange(selectedSurveyPlaceId: number) {
    // console.log('Selected Area Changed:', selectedSurveyPlaceId);
    this.SurveyPlaceChanged();
  }

  checkContentDisable() {
    if (this._leadDetail && this._leadDetail.markLost === true || this._leadDetail.moveInitiated === true) {
      this._contentDisable = true;
    } else {
      this._contentDisable = false;
    }
  }

  getSurveyItemOnNgInit() {
    this._movdService.getSurveyItemsBySurveyPlaceId(this._surveyPlacesList[0].id).subscribe({
      next: (data: any) => {
        // console.log("survey items ngoninit", data);
        if (data && data.length > 0) {
          this._dbSurveyItemsByPlace = data.filter((item: { isDeleted: boolean }) => !item.isDeleted);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  SurveyPlaceChanged() {
    // console.log("survey place changed id  ", this._selectedSurveyPlaceId);
    if (!this._selectedSurveyPlaceId) {
      return;
    }

    this._movdService.getSurveyItemsBySurveyPlaceId(this._selectedSurveyPlaceId).subscribe({
      next: (data: any) => {
        // console.log("survey place changed: survey items by id", data);
        if (data && data.length > 0) {
          this._dbSurveyItemsByPlace = data.filter((item: { isDeleted: boolean }) => !item.isDeleted);
        } else {
          this._dbSurveyItemsByPlace = [];
        }
      },

      error: (error) => {
        console.error(error);
      }
    });
  }

  addItemToTable(surveyItem: SurveyItem): void {
    // Check if both survey place and selected item are valid
    // console.log("add item to table", surveyItem);
    if (this._selectedSurveyPlaceId && this._surveyPlacesList.length > 0) {
      // Find an existing item with the same survey place, item name, and area items
      const surveyPlace = this._surveyPlacesList.find(item => item.id === this._selectedSurveyPlaceId);

      // const existingItem = this._areaItems.find(
      //   item =>
      //     item.item === surveyItem.title &&
      //     item.surveyPlace === surveyPlace?.title
      // );

      // if (existingItem) {
      // If the item already exists, increase its quantity
      // existingItem.quantity++;
      // now commenting it as we want to save items with different volume/weight
      // } else {
      // If the item doesn't exist, add a new row with quantity 1

      const newItem: SurveyMaterial = {
        id: 0,
        leadDetailId: this._leadDetailId,
        surveyPlace: surveyPlace?.title ?? '',
        item: surveyItem.title,
        quantity: 1,
        price: null,
        type: 'Area Item',
        weight: surveyItem.weight,
        volume: surveyItem.estimatedVolume,
        isDeleted: false,
        dimension: surveyItem.dimension ?? null,
      };
      this._areaItems.push(newItem);
      this.saveMoveSurvey();
    }
  }


  activateButton(option: string): void {
    // this._survey.activeOptions[option] = true;
  }

  deactivateButton(option: string): void {
    // this._survey.activeOptions[option] = false;
  }

  changeQuantity(index: number, action: 'increase' | 'decrease'): void {
    if (action === 'increase') {
      if (this._areaItems[index] && this._areaItems[index].isDeleted) {
        // console.log("this condition:", this._areaItems);
        this._areaItems[index].quantity++;
        this._areaItems[index].isDeleted = false; // Set isDeleted to false if you want to "undelete" the item
      } else {
        this._areaItems[index].quantity++;
      }
    } else if (action === 'decrease' && this._areaItems[index].quantity > 0) {
      this._areaItems[index].quantity--;

      if (this._areaItems[index].quantity === 0) {
        this._areaItems[index].isDeleted = true;
        // this._areaItems.splice(index, 1); // Remove the item at the specified index
      }
    }
    this.saveMoveSurvey();
  }

  openAreaModal(content: any): void {
    // this._survey.title = '';
    this._surveyPlace = new SurveyPlace();
    this._modalService.open(content, { centered: true });
  }

  openItemModal(content: any): void {
    // this._survey.title = '';
    this._surveyItem = new SurveyItem();
    this._modalService.open(content, { centered: true });
  }

  openMaterialModal(content: any): void {
    // this._survey.title = '';
    this._modalService.open(content, { centered: true });
  }

  openLabourModal(content: any): void {
    // this._survey.title = '';
    this._modalService.open(content, { centered: true });
  }

  openVehicleModal(content: any): void {
    // this._survey.title = '';
    this._modalService.open(content, { centered: true });
  }

  saveArea(modal: any): void {
    const companyId = Number(localStorage.getItem('companyId'));

    if (this._surveyPlace.title.trim().length === 0) {
      return;
    }
    this._surveyPlace.ownedBy = companyId;

    this._movdService.saveSurveyPlace(this._surveyPlace).subscribe({
      next: (response: any) => {
        // console.log("save survey place response", response);
        this._appComponent.showSuccessSwal("created", "record");
        this.getAllSurveyPlaces();
        modal.close();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("creating", "record");
        console.error("error", error);
      }
    });
  }

  saveItem(modal: any): void {
    const companyId = Number(localStorage.getItem('companyId'));
    if (this._surveyItem.title.trim().length === 0) {
      return;
    }
    this._surveyItem.ownedBy = companyId;
    // this._survey.ownedBy = companyId;
    // this._survey.surveyPlaceId = this._selectedPlaceId;
    // console.log("Survey Item to be saved : ", JSON.stringify(this._surveyItem));

    this._movdService.saveSurveyItem(this._surveyItem).subscribe({
      next: (response: any) => {
        // console.log("save survey item response", response);
        this._appComponent.showSuccessSwal("created", "record");
        // const selectedValue = this._survey.selectedAreaId;
        this.SurveyPlaceChanged();
        this.getAllSurveyPlaces();
        modal.close();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("creating", "record");
        console.error("error saving survey item", error);
      }
    });
  }

  handlePictureUpload(event: any) {
    const files: FileList = event.target.files;

    // Check if files were selected
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        const file = files[i];

        reader.onload = (e: any) => {
          const newAttachment: MoveAttachment = {
            id: 0,
            imageData: e.target.result,
            title: file.name,
            refId: this._leadDetailId,
            type: eAttachmentType.SurveyImages,
            url: null,
            isValid: null,
            creationDate: new Date(),
            isDeleted: false,
            modificationDate: null,
            dueDate: null
          };

          // Push the new attachment object to the _attachments array
          this._attachments.push(newAttachment);
        };
        reader.readAsDataURL(files[i]);
      }
    }
  }

  addRow(type: string, targetArray: SurveyMaterial[]): void {
    const newMaterial = new SurveyMaterial();
    newMaterial.leadDetailId = this._leadDetailId;
    newMaterial.type = type;
    targetArray.push(newMaterial);
    // this.saveMoveSurvey();
  }

  // Function to remove a row by index
  removeRow(index: number, targetArray: SurveyMaterial[]): void {
    if (index >= 0 && index < targetArray.length) {
      // targetArray.splice(index, 1);
      targetArray[index].isDeleted = true;
    }
  }

  removeImage(index: number) {
    if (index >= 0 && index < this._attachments.length) {
      // this._attachments.splice(index, 1);
      this._attachments[index].isDeleted = true;
    }
  }

  saveMoveSurvey() {
    // console.log("selected move detail id ", this._moveDetailId);

    const companyId = Number(localStorage.getItem('companyId'));
    const currentUserId = Number(localStorage.getItem('currentUserId'));

    // this._moveSurvey.leadDetailId = this._leadDetailId;
    this._moveSurvey.leadDetailId = this._leadDetailId;
    this._moveSurvey.createdBy = currentUserId;
    this._leadDetail.id = this._selectedLeadDetailId;
    const materials = this._materials
      .filter(material => material.item !== null)
      .map((material, index) => {
        if (index === 0) {
          material.leadDetailId = this._leadDetailId;
          material.type = 'Material';
        }
        return material;
      });

    // Map and add moveDetailId and type to _labourAndVehicles array
    const labour = this._labour
      .filter(material => material.item !== null)
      .map((labour, index) => {
        if (index === 0) {
          labour.leadDetailId = this._leadDetailId;
          labour.type = 'Labour';
        }
        return labour;
      });

    const vehicles = this._vehicles
      .filter(material => material.item !== null)
      .map((vehicle, index) => {
        if (index === 0) {
          vehicle.leadDetailId = this._leadDetailId;
          vehicle.type = 'Vehicle';
        }
        return vehicle;
      });

    const containers = this._containers
      .filter(material => material.item !== null)
      .map((container, index) => {
        if (index === 0) {
          container.leadDetailId = this._leadDetailId;
          container.type = 'Container';
        }
        return container;
      });

    const surveyMaterials = [...this._areaItems, ...materials, ...labour, ...vehicles, ...containers];

    this._moveSurvey.surveyMaterials = surveyMaterials;

    const surveyAttachments: MoveAttachment[] = [];

    for (const attachment of this._attachments) {
      attachment.id = attachment.id;
      attachment.refId = this._leadDetailId;
      attachment.title = attachment.title;
      attachment.imageData = attachment.imageData;
      attachment.isValid = attachment.isValid;
      attachment.type = attachment.type;
      attachment.isDeleted = attachment.isDeleted;
      surveyAttachments.push(attachment);
    }
    this._moveSurvey.surveyAttachments = surveyAttachments;

    // console.log("before saveing _moveSurvey", this._moveSurvey);

    this._movdService.saveMoveSurvey(this._moveSurvey).subscribe({
      next: (response: any) => {
        // console.log("save _moveSurvey respnse", response);
        this._appComponent.showSuccessSwal("saved", "survey");
        this.getMoveSurveys();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("saving", "survey");
        console.error("error", error);
      }
    });
  }

  saveAttachment() {
    const companyId = Number(localStorage.getItem('companyId'));
    const currentUserId = Number(localStorage.getItem('currentUserId'));
    this._moveAttachment.refId = this._leadDetailId;
    // this._moveSurvey
    // this._moveAttachment.createdBy = currentUserId;

    // console.log("save _moveSurvey", this._moveAttachment);

    // this._movdService.save(this._moveSurvey).subscribe({
    //   next: (response: any) => {
    //     console.log("save _moveSurvey respnse", response);
    //     this._appComponent.showSuccessSwal("created", "record");
    //   },
    //   error: (error: any) => {
    //     this._appComponent.showErrorSwal("creating", "record");
    //     console.log("error", error);
    //   }
    // });
  }


  downloadImage(base64String: string, fileName: string) {
    // Convert the base64 string to a Blob object
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: 'image/png' });

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  // temp method for selecting move detail to add survey
  getLeadDetailById(leadDetailId: any) {
    this._movdService.getLeadDetailById(leadDetailId).subscribe({
      next: (data: any) => {
        // console.log("one move detail", data);
        if (data) {
          this._leadDetail = data;

          if (this._leadDetail && this._leadDetail.addresses !== null) {
            const originAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 0));
            const destAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 1));
            // console.log("origin address", originAdrress);
            // console.log("dest address", destAdrress);

            if (originAdrress.length > 0) {
              this._originAddresses = originAdrress;
            }

            if (destAdrress.length > 0) {
              this._destinationAddresses = destAdrress;
            }
          }
        }
        this.checkContentDisable();
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  getLeadDetailsByLeadId() {
    // console.log('lead id', this._leadId);
    this._movdService.getLeadDetailsByLeadId(this._leadId, true).subscribe({
      next: (data: LeadBoardResponse) => {
        // console.log("lead details by leadId", data);
        if (data.leads) {
          if (data.leads.length > 0) {
            this._leadBoardDTOList = data.leads;
            // console.log('lead board survey status', this._leadBoardDTOList);
            this._isDataLoaded = true;
            // const filteredData = data.filter(((item: LeadBoardDTO) => item.leadDetailId === this._leadDetail.id))[0];
            this._leadDetail.id = this._leadDetailId;
            this._selectedLeadDetailId = this._leadDetail.id;
            this.getLeadDetailById(this._leadDetail.id);
          }

        }
      },
      error: (error) => {
        console.error("error getting lead detail", error);
      }
    });
  }
  getMoveSurveys() {
    this._movdService.getMoveSurvey(this._leadDetailId).subscribe({
      next: (data: any) => {
        this._areaItems = [];
        this._materials = [];
        this._labour = [];
        this._vehicles = [];
        this._containers = [];
        this._surveyMaterials = [];
        this._attachments = [];
        this._moveSurvey = new MoveSurvey();
        // this._moveSurvey = data;
        // console.log("move survey list", data);
        if (data) {

          // this._filteredAreaItems = [];
          this._moveSurvey = data;
          this._surveyMaterials = this._moveSurvey.surveyMaterials; // fix for displaying survey materials as earlier list was being initialize and empty after assigning survey materials to the list

          this._surveyMaterials.forEach((material: SurveyMaterial) => {
            switch (material.type) {
              case 'Material':
                this._materials.push(material);
                break;
              case 'Area Item':
                this._areaItems.push(material);
                break;
              case 'Labour':
                this._labour.push(material);
                break;
              case 'Vehicle':
                this._vehicles.push(material);
                break;
              case 'Container':
                this._containers.push(material);
                break;
              // Handle other types if needed
              default:
                break;
            }

          });

        }
        // console.log("materials", this._materials);
        if (this._materials.length === 0) {
          this._materials = [new SurveyMaterial()];
        }
        if (this._labour.length === 0) {
          this._labour = [new SurveyMaterial()];
        }
        if (this._vehicles.length === 0) {
          this._vehicles = [new SurveyMaterial()];
        }
        if (this._containers.length === 0) {
          this._containers = [new SurveyMaterial()];
        }

        if (this._moveSurvey.surveyAttachments && this._moveSurvey.surveyAttachments !== null) {
          for (const attachment of this._moveSurvey.surveyAttachments) {
            if (attachment.imageData) {
              const newAttachment: MoveAttachment = {
                id: attachment.id,
                refId: attachment.refId,
                title: attachment.title || '',
                type: attachment.type,
                url: attachment.url,
                isValid: attachment.isValid,
                imageData: attachment.imageData,
                creationDate: new Date(),
                isDeleted: false,
                modificationDate: null,
                dueDate: null
              };

              this._attachments.push(newAttachment);
            }
          }
        }
        // console.log("get move survey", this._attachments);

        if (this._moveSurvey.customerSignature) {
          this.renderCustomerSignature(this._moveSurvey.customerSignature);
        } else {
          this.customerSignaturePad.clear();
        }

        if (this._moveSurvey.managerSignature) {
          this.renderManagerSignature(this._moveSurvey.managerSignature);
        } else {
          this.surveyMgrSignaturePad.clear();
        }

        if (this._areaItems.length > 0) {
          this._areaItems.forEach((item) => {
            if (item.surveyPlace !== null && item.item) {
              // this._surveyPlaceToItemMap.set(item.surveyPlace, item.item);
            }
          });
        }
        this.checkContentDisable();
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  renderCustomerSignature(signatureDataURL: string) {
    const img = new Image();
    img.src = signatureDataURL;

    img.onload = () => {
      // Clear the customer signature canvas (optional)
      this.customerSignaturePad.clear();

      // Draw the customer signature
      this.customerSignaturePad.fromDataURL(signatureDataURL);
    };
  }

  renderManagerSignature(signatureDataURL: string) {
    const img = new Image();
    img.src = signatureDataURL;

    img.onload = () => {
      // Clear the manager signature canvas (optional)
      this.surveyMgrSignaturePad.clear();

      // Draw the manager signature
      this.surveyMgrSignaturePad.fromDataURL(signatureDataURL);
    };
  }

  openImageModal(template: TemplateRef<any>, image: any, imageName: any) {
    this._imageToDisplay = image;
    this._imagePreviewTitle = imageName;

    this._modalService.open(template);

  }

  generatePDF() {
    this.generatingPDF = true;
    if (this.pdfContent && this.pdfContent.nativeElement) {
      const contentElement = this.pdfContent.nativeElement;

      // console.log("generate pdf");
      //const content = this.pdfContent.contentElement?.nativeElement;
      if (contentElement) {
        contentElement.style.visibility = 'visible';
        contentElement.offsetHeight;
        // Revert the visibility back to hidden

        this._pdfService.ConvertHTMLToPDF(contentElement, 'Survey-Summary-' + this._leadDetailId);
        contentElement.style.visibility = 'hidden';
      } else {
        console.error('Div not found');
      }
    }
    // this.generatingPDF = false;
  }

  openPdfModel() {
    const modalOptions: NgbModalOptions = {
      size: 'lg',       // Set the size of the modal (lg = large)
      centered: true,   // Center the modal vertically and horizontally
    };

    // Open the modal with the specified options
    this._basicModalRef = this._modalService.open(this.basicModal, modalOptions);
  }

  calculateTotal(): number {
    let total = 0;
    for (const item of this._areaItems) {
      if (item.quantity && item.weight) {
        total += item.quantity * item.weight;
      }
    }
    return total;
  }

  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.dimensionUnit) {
            this._dimensionsUnit = getDimensionUnitShortLabel(response.dimensionUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        } else {
          this._companySetting = new CompanySetting();
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
    this.checkContentDisable();
  }

  closeViewModal() {
    // console.log("close modal");
  }

  getItemCount(surveyPlace: string): number {
    const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  calculateWeight(surveyPlace: string, unit: string, type: string): string | number {
    let totalWeight;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalWeight = items.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    } else {
      totalWeight = this._areaItems.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalWeight.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'kg' && this._weightUnit === 'kg') {
      // Convert from kg to lbs
      const convertedWeight = totalWeight * this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} lbs`;
    } else if (unit === 'lbs' && this._weightUnit === 'lbs') {
      // Convert from lbs to kg
      const convertedWeight = totalWeight / this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} kg`;
    }

    return result;
  }

  // Define a function to calculate the total volume for a specific survey place in the selected unit
  calculateVolume(surveyPlace: string, unit: string, type: string): string | number {
    let totalVolume;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalVolume = items.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    } else {
      totalVolume = this._areaItems.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalVolume.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'cuft' && this._volumeUnit === 'cuft') {
      // Convert from cuft to cbm
      const convertedVolume = totalVolume * this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cbm`;
    } else if (unit === 'cbm' && this._volumeUnit === 'cbm') {
      // Convert from cbm to cuft
      const convertedVolume = totalVolume / this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cuft`;
    }

    return result;
  }

  // Define a function to calculate the total for the entire table
  calculateTotal1(): number {
    const totalWeight = this._areaItems.reduce((acc, item) => acc + (item.weight || 0), 0);
    return totalWeight;
  }


  formatSurveyDate(date: NgbDateStruct): string {
    return date
      ? `${date.day.toString().padStart(2, '0')}/${date.month.toString().padStart(2, '0')}/${date.year}`
      : '';
  }

  formatDeliveryDate(date: NgbDateStruct) {
    return date
      ? `${date.day.toString().padStart(2, '0')}/${date.month.toString().padStart(2, '0')}/${date.year}`
      : '';
  }
  saveAndAddToTable(item: SurveyItem) {
    if (!this._createPermission) {
      this.showUnauthorizedDialog();
      return;
    }

    this.addItemToTable(item);
  }
  // onButtonClick(leadBoardDTO: LeadBoardDTO) {
  //   this.showMoveById(leadBoardDTO.leadDetailId);
  //   this.navigateToAddSurvey(leadBoardDTO);
  // }
  async showMoveById(leadBoardDTO: LeadBoardDTO) {
    if (leadBoardDTO.surveyStatus === eProcessStatus.New) {
      let text = 'You are about to start the survey process.';
      let confirmButtonText = 'Yes, please start!';

      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        text,
        confirmButtonText
      );

      if (await confirmed === true) {
        this.saveSurveyStatus(leadBoardDTO.leadDetailId);
        this._selectedLeadDetailId = leadBoardDTO.leadDetailId;
        this._leadDetailId = this._selectedLeadDetailId;
        this._leadDetail.id = this._leadDetailId;
        this.getLeadDetailById(this._leadDetailId);
        this.getAllSurveyPlaces();
        this.getMoveSurveys();
      }
      else {
        return;
      }
    }

    else {
      // console.log("selected lead end");
      this._selectedLeadDetailId = leadBoardDTO.leadDetailId;
      this._leadDetailId = this._selectedLeadDetailId;
      this._leadDetail.id = this._leadDetailId;
      // console.log("selected lead id", this._leadDetail.id);
      this.getLeadDetailById(this._leadDetailId);
      this.getAllSurveyPlaces();
      this.getMoveSurveys();
    }
  }

  saveSurveyStatus(leadDetailId: number) {
    this._movdService.saveSurveyStatus(leadDetailId, eProcessStatus.InProgress).subscribe({
      next: (response: any) => {
        // console.log("saved survey status resonse", response);

      },
      error: (error) => {
        console.error("error saving survey status", error);
      }
    });
  }
  generatePdf(type: any) {
    if (this._areaItems.length === 0) {
      this._appComponent.showErrorDialog('Error', 'Please Add an Area Item');
    } else {
      const pdfType = type;
      this._pdfService.generateSurveyPdf(this._leadDetailId, this._leadId, pdfType);
    }
  }
}

