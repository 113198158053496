import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuditLogService } from '../../../services/audit-log.service';
import { AuditLogModel, AuditLogResponse } from './audit-log.models';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { GeneralService } from 'src/services/general.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from '../../../services/breadcrumb.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {

  _logs: AuditLogModel[] = [];
  _logColumns: any[] = [];

  _currentPage = 1;
  _pageSize = 10;
  _totalRecords = 0;
  _totalPages = 0;
  _searchTerm = '';

  _log = new AuditLogModel();
  _dataDict: any = {}; // New property to hold the dictionary

  // data table 
  ColumnMode = ColumnMode;

  // viewAuditLogModal
  @ViewChild('viewAuditLogModal') viewAuditLogModal: any;
  _ngbModalRef: NgbModalRef | undefined;

  constructor(
    private _auditLogService: AuditLogService,
    public generalService: GeneralService,
    private _cdr: ChangeDetectorRef,
    private _modalService: NgbModal,
    private breadcrumbService: BreadcrumbService

  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Audit Log', url: '/audit-logs' },


    ]);
    this.getAuditLogs();
  }

  getAuditLogs(): void {
    this._auditLogService.getAuditLogs(this._currentPage, this._pageSize, this._searchTerm)
      .subscribe({
        next: (data: AuditLogResponse) => {
          // console.log("get audit logs response", data);
          if (data) {
            this._logs = data.logs;
            this._cdr.detectChanges();
            if (this._logs && this._logs.length > 0) {

              this._logColumns = Object.keys(this._logs[0])
                .filter((key) =>
                  key === 'creationDate' ||
                  key === 'tableName' ||
                  key === 'action' ||
                  key === 'oldData' ||
                  key === 'newData')

                .map((key) => {
                  let header: string;
                  if (key == 'creationDate') {
                    header = 'Date';
                  } else if (key == 'tableName') {
                    header = 'Category';
                  } else if (key == 'action') {
                    header = 'Action';
                  } else if (key == 'oldData') {
                    header = 'Old Data';
                  } else if (key == 'newData') {
                    header = 'New Data';
                  } else {
                    header = key;
                  }
                  return { header };
                });
            }
            this._totalRecords = data.totalRecords;
          }
        },
        error: (error) => {
          // Handle error here. For example, you can call your error service.
          console.error("error getting logs", error);
        }
      });

  }
  storeDataInDictionary(oldData: string | null | undefined, newData: string): any {
    // Initialize oldObj to an empty object if oldData is null or undefined
    const oldObj = oldData ? JSON.parse(oldData)[0] || {} : {};
    const newObj = JSON.parse(newData)[0] || {}; // Always expect newData to be present
  
    const dataDict: any = {};
  
    // Extract all unique keys from oldObj and newObj
    const allKeys = new Set([...Object.keys(oldObj), ...Object.keys(newObj)]);
  
    allKeys.forEach(key => {
      const oldValue = oldObj[key] !== undefined ? oldObj[key] : '-';
      const newValue = newObj[key] !== undefined ? newObj[key] : '-';
      dataDict[key] = {
        old_value: oldValue,
        new_value: newValue,
        hasChanged: oldValue !== newValue
      };
    });
  
    console.log("Data Dictionary:", dataDict);
    return dataDict;
  }
  
  // Method to truncate base64 string
  truncateBase64(base64String: string, maxLength: number = 40): string {
    if (base64String.length > maxLength) {
      return base64String.substring(0, maxLength) + '...'; // Truncate and add ellipsis
    }
    return base64String;
  }

  getDictionaryKeys(): string[] {
    return Object.keys(this._dataDict);
  }

  onPageChange(event: any) {
    this._currentPage = event.offset + 1;
    this.getAuditLogs();
  }
  onSearch(): void {
    this._currentPage = 1;
    this.getAuditLogs();
  }

  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  previewAudit(logRow: AuditLogModel) {
    this._log = logRow;
  
    // Always use logRow.newData; if oldData is missing, default it to '{}'
    const oldData = logRow.oldData || '{}'; // Handle missing oldData
    const newData = logRow.newData; // Always expect newData
  
    // Call storeDataInDictionary with oldData and newData
    this._dataDict = this.storeDataInDictionary(oldData, newData);
  
    this._ngbModalRef = this._modalService.open(this.viewAuditLogModal, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
  
    this._ngbModalRef.hidden.subscribe(() => {
      this.closeModal(null); // Ensure data is cleared when modal is hidden
    });
  }

  closeModal(modal: any) {
    console.log('Closing modal and clearing data'); // Debugging line
    this._log = new AuditLogModel(); // Reset the audit log model
    this._dataDict = {}; // Clear the data dictionary
  }
}
