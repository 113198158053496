import { ContactService } from "src/services/contact.service";
import {
  Component,
  ChangeDetectorRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  ViewChildren,
  QueryList,
  Inject,
  Renderer2
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTable } from "simple-datatables";
import { Router, NavigationExtras } from '@angular/router';
import { AppComponent } from '../../../../app.component';
import { DOCUMENT } from '@angular/common';
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { _compColumns, _contactColumns, ContactsResponse, Customer, eSalesType, eSalesTypeOptions } from "../contact.models";
import { AuthService } from "src/services/auth.service";
import { Country, CountryOptions } from "src/app/views/movd/models/address.model";
import { EnumMappingService } from "src/app/views/movd/services/movd.enum.service";
import { CustomerDTO } from "../../profile/profile.models";
import { BreadcrumbService } from "src/services/breadcrumb.service";

interface TableColumn {
  header: string;
}

@Component({
  selector: 'app-show-contact',
  templateUrl: './show-contact.component.html',
  styleUrls: ['./show-contact.component.scss']
})
export class ShowContactComponent implements OnInit, AfterViewChecked {
  @ViewChild('contactsTbl') contactsTable!: ElementRef;
  //@ViewChild('previewButton')
  //  previewButton!: ElementRef;
  isRowHovered: boolean = false;

  //#region local veriables
  selectedDataItem: any;
  _contacts: any = [];
  _companies: any = [];
  _modalTitle: any;
  _contactDT: any;
  _companiesDT: any;
  defaultNavActiveId: any;
  _singleContact: any;
  _currentUserId: number = 0;
  // _types: any = null;
  //_allColumns used in displaying custom coloumns.
  _allContColumns: string[] = _contactColumns;
  _selectedContColumns: { [key: string]: boolean } = {};

  _allCompColumns: string[] = _compColumns;
  _selectedCompColumns: { [key: string]: boolean } = {};
  _popupVisible = false;
  _contact: any = {
    customerName: "",
    Id: 0,
    phone: "",
    email: "",
    fax: "",
    dateOfBirth: null,
    details: "",
    contactType: 0,
    salesType: 0,
    openPositions: 0,
    companyURL: '',
    linkedInURL: '',
    noOfEmployees: '',
    creationDate: null,
    createdBy: this._currentUserId,
    modificationDate: null,
    modifiedBy: null,
    deletionDate: null,
    isDeleted: false,
    address: {
      Id: 0,
      customerId: 0,
      phoneNo: "",
      email: "",
      fax: "",
      streetAddress: "",
      zipCode: "",
      city: "",
      state: "",
      country: "",
      creationDate: null,
      createdBy: null,
      modificationDate: null,
      modifiedBy: null,
      deletionDate: null,
      isDeleted: false
    }
  };
  @ViewChildren('contactloopItem') contactloopItem!: QueryList<any>;
  @ViewChildren('comploopItem') comploopItem!: QueryList<any>;
  @ViewChild('xlModal') xlModal: any;
  _customerType: number = 0;

  _profileClickCounter: number = 0;
  _previewClickCounter: number = 0;

  //for datatable
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  selectedColumns: string[] = ['NAME', 'EMAIL', 'PHONE', 'CITY', 'COUNTRY', 'ACCOUNT', 'POSITION'];

  contactTableData: any[] = []; // Array to hold the table data
  companyTableData: any[] = []; // Array to hold the table data
  contactTableColumns: TableColumn[] = [];
  companyTableColumns: TableColumn[] = [];
  _contactDataList: any[] = [];
  loadingIndicator = true;
  ColumnMode = ColumnMode;
  reorderable = true;

  _customerDTOModel = new CustomerDTO();

  //#endregion local veriables

  //#region lifecycle hooks

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;

  // for countries
  _eCountries = CountryOptions;

  _currentPage = 1;
  _pageSize = 10;
  _totalRecords = 0;
  _searchTerm = '';
  eSalesTypeOptions = eSalesTypeOptions;
  constructor(private _sharedService: ContactService,
    private _modalService: NgbModal,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private _appComponent: AppComponent,
    private cdr: ChangeDetectorRef,
    private _authService: AuthService,
    public enumMappingService: EnumMappingService,
    private breadcrumbService: BreadcrumbService,
    @Inject(DOCUMENT) private document: Document) {
    this.selectedDataItem = null; // Initial value
    // this.toggleSidebar(new Event('tempEvent')); // commenting this code as it disrupts the layout of the application
    this._userRole = localStorage.getItem('role');

  }

  ngOnInit(): void {
    let role = localStorage.getItem('role');
    this._sharedService.setCustomerType(0); // as default tab is Contact Tab

    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('CRM - Contacts.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('CRM - Contacts.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('CRM - Contacts.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('CRM - Contacts.Delete') || this._userRole === 'CompanyAdmin';
    // this._types = [
    //   { id: 0, value: 'None' },
    //   { id: 1, value: 'Sales' },
    //   { id: 2, value: 'Partner' },
    //   { id: 3, value: 'Vendor' }
    // ];
    if (this._readPermission === false) {
      this.checkPermissions();
    }
    else {
      const currentUserId = localStorage.getItem('currentUserId');
      this._currentUserId = Number(currentUserId);
      //console.log("currentUserId", this._currentUserId)

      this.refreshContactList();
      this.setDefaultSelectedColumns();
      this._contact.contactType = this._sharedService.getCustomerType();
      this._modalTitle = "Add Contact";
    }
  }

  async checkPermissions() {
    await this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to visit see the contacts', 'read');
    // console.log("go to dashboard. no read permission");
  }

  ngAfterViewChecked(): void {
    if (this.contactloopItem && this.contactloopItem.last) {
      this._contactDT = new DataTable("#contactsTbl");  //uncoment
      this.hideColumns('#contactsTbl', this._selectedContColumns); //uncoment
      // console.log("_selectedContColumns", this._selectedContColumns);

    }
    if (this.comploopItem && this.comploopItem.last) {
      this._companiesDT = new DataTable("#companiesTbl");
      this.hideColumns('#companiesTbl', this._selectedCompColumns);
    }

    const profileClick = this.elementRef.nativeElement.querySelectorAll('.profileClick');
    profileClick.forEach((profileClick: any) => {
      this.renderer.listen(profileClick, 'click', (event) => {
        this._profileClickCounter++;
        if (this._profileClickCounter === 1) {
          this.profileClick('contact', Number(event.currentTarget.getAttribute('data-id')));
        }
      });
    });

    // Add event listener for edit click
    const previewClick = this.elementRef.nativeElement.querySelectorAll('.previewClick');
    previewClick.forEach((editClick: any) => {
      this.renderer.listen(editClick, 'click', (event) => {
        const id = event.currentTarget.getAttribute('data-id');
        this._previewClickCounter++;
        if (this._previewClickCounter === 1) {
          this.getSingleContactAndOpenModal(Number(id));
        }
      });
    });

  }
  //#endregion lifecycle hooks

  //#region private functions
  // Properties

  // Function to toggle column visibility
  //toggleColumnVisibility() {
  //  this.contactTableColumns = this.contactTableColumns.filter(column => this._selectedContColumns[column.header]);
  //  this.companyTableColumns = this.companyTableColumns.filter(column => this._selectedCompColumns[column.header]);
  //}

  toggleColumnVisibility() {
    // console.log("togglecolumn");
    // console.log("type", this._sharedService.getCustomerType());
    if (this._sharedService.getCustomerType() == 0) {
      this.contactTableColumns = this._allContColumns
        .filter(column => this._selectedContColumns[column])
        .map(column => ({ header: column }));
    } else if (this._sharedService.getCustomerType() == 1) {
      this.companyTableColumns = this._allCompColumns
        .filter(column => this._selectedCompColumns[column])
        .map(column => ({ header: column }));
    }
  }

  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.add('sidebar-folded');
  }
  getSingleContactAndOpenModal(id: number): void {

    var contact = this._contactDataList.length > 0 ? this._contactDataList.find(item => item.id === id) : new Customer();
    this.openEditModal(contact);
    this._previewClickCounter = 0;

  }

  //#region Edit Columns
  hasSelectedContColumns(): boolean {
    //console.log(JSON.stringify(this._selectedContColumns));
    return Object.values(this._selectedContColumns).some((value) => value);
  }

  hasSelectedCompColumns(): boolean {
    //console.log(JSON.stringify(this._selectedCompColumns));
    return Object.values(this._selectedCompColumns).some((value) => value);
  }

  setDefaultSelectedColumns() {
    this._allContColumns.forEach((column) => {
      this._selectedContColumns[column] = true;
    });

    this._allCompColumns.forEach((column) => {
      this._selectedCompColumns[column] = true;
    });
  }

  hideColumns(tableId: string, selectedColumns: { [key: string]: boolean }) {
    const table = document.querySelector(tableId);
    if (table) {
      const headers = Array.from(table.querySelectorAll('thead th'));
      const hasSelectedColumn = Object.values(selectedColumns).some((value) => value);

      headers.forEach((header: Element) => {
        const columnName = header.textContent?.trim();
        if (columnName) {
          if (!hasSelectedColumn || (hasSelectedColumn && !selectedColumns[columnName])) {
            (header as HTMLElement).style.display = 'none';
          } else {
            (header as HTMLElement).style.display = ''; // Reset the display to its default value
          }
        }
      });

      const tbodyRows = Array.from(table.querySelectorAll('tbody tr'));
      tbodyRows.forEach((row: Element) => {
        const cells = Array.from(row.querySelectorAll('td'));
        cells.forEach((cell: Element, index: number) => {
          const columnName = headers[index].textContent?.trim();
          if (columnName) {
            if (!hasSelectedColumn || (hasSelectedColumn && !selectedColumns[columnName])) {
              (cell as HTMLElement).style.display = 'none';
            } else {
              (cell as HTMLElement).style.display = ''; // Reset the display to its default value
            }
          }
        });
      });
    }
  }

  // Inside your component
  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  openColumnModal(content: TemplateRef<any>) {
    this._modalService.open(content, { size: 'md' }).result.then((result) => {

    }).catch((res) => { });
  }
  //#endregion Edit Columns

  closePopup() {
    this._popupVisible = false;
    this._previewClickCounter = 0;

  }

  onChangeType(_type: any) {

    this._searchTerm = '';
    //console.log("customer type", _type);
    this._sharedService.setCustomerType(_type);
    this._contact.contactType = _type;
    this.refreshContactList();
  }

  parentFunction(): void {
    this._modalService.dismissAll('by: calling closeModal()');
    this._previewClickCounter = 0;
    this.refreshContactList();
  }

  openBasicModal(content: TemplateRef<any>) {
    this._contact.salesType = 0;

    if (this._sharedService.getCustomerType() == 0) {
      this._modalTitle = "Add Contact";
    } else {
      this._modalTitle = "Add Company";
    }

    this._contact.Id = 0;

    this._modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    }).result.then((result) => {
      // Handle modal close
    }).catch((res) => {
      // Handle modal dismiss
    });

    this.onChangeType(this._sharedService.getCustomerType());
  }

  onInputChange(event: any) {

    if (event && event.data === null) {
      // console.log("condition checked", event && event.data === null);
      this.refreshContactList();
    }
  }

  openEditModal(dataItem: any) {
    // console.log("open edit modal", dataItem);
    if (this._sharedService.getCustomerType() == 0) {
      this._modalTitle = "Update Contact";
    }
    else {
      this._modalTitle = "Update Company";
    }
    this._contact = { ...dataItem };

    this._modalService.open(this.xlModal, { size: 'md' }).result.then((result) => {
      //this.basicModalCloseResult = "Modal closed" + result
      this._previewClickCounter = 0;
    }).catch((res) => { });

    this.onChangeType(this._sharedService.getCustomerType());
  }

  onPageChange(event: any) {
    this._currentPage = event.offset + 1;
    this.refreshContactList();
  }

  onSearch(): void {
    this._currentPage = 1;
    this.refreshContactList();
  }

  refreshContactList() {
    this._sharedService.getContacts(this._currentPage, this._pageSize, this._searchTerm, this._contact.contactType).subscribe({
      next: (data: ContactsResponse) => {
        // console.log("contact list", data);
        if (data.contacts && data.contacts.length > 0) {
          this._contactDataList = data.contacts;
          this._totalRecords = data.totalRecords;
          this._contacts = this._contactDataList.filter((item: { contactType: number; }) => item.contactType === 0);
          this._companies = this._contactDataList.filter((item: { contactType: number; }) => item.contactType === 1);
          this.contactTableData = this._companies.map((contact: { salesType: any; }) => ({
            ...contact,
            salesType: this.eSalesTypeOptions.find((type: { id: any; }) => type.id === contact.salesType)?.value
          }));
          //new data table
          this.contactTableData = data.contacts.filter((item) => item.contactType === 0);
          this.companyTableData = data.contacts.filter((item) => item.contactType === 1 && item.companyId !== 0);

          // console.log("company table data", this.companyTableData);

          this.contactTableData = this._contacts.map((contact: { salesType: any; }) => ({
            ...contact,
            salesType: this.eSalesTypeOptions.find((type: { id: any; }) => type.id === contact.salesType)?.value
          }));
          // console.log("contact table data", this.contactTableData);
        }
        // if (this.contactTableData && this.contactTableData.length > 0) {
        this.contactTableColumns = Object.keys(this._customerDTOModel)
          .filter((key) => {
            return (
              key === 'customerName' ||
              key === 'email' ||
              key === 'phone' ||
              key === 'city' ||
              key === 'country' ||
              key === 'companyName' ||
              key === 'role' ||
              key === 'salesType'
            );
          })
          .map((key) => {
            let header: string;

            switch (key) {
              case 'customerName':
                header = 'NAME';
                break;
              case 'email':
                header = 'EMAIL';
                break;
              case 'phone':
                header = 'PHONE';
                break;
              case 'city':
                header = 'CITY';
                break;
              case 'country':
                header = 'COUNTRY';
                break;
              case 'role':
                header = 'POSITION';
                break;
              case 'companyName':
                header = 'ACCOUNT';
                break;
              case 'salesType':
                header = 'TYPE';
                break;
              default:
                header = key; // Use the original key if no specific header is defined
                break;
            }

            return { header } as TableColumn; // Use 'as TableColumn' to inform TypeScript about the type
          });

        // }

        // if (this.companyTableData && this.companyTableData.length > 0) {
        this.companyTableColumns = Object.keys(this._customerDTOModel)
          .filter((key) => {
            return (
              key === 'customerName' ||
              key === 'email' ||
              key === 'phone' ||
              key === 'city' ||
              key === 'country' ||
              key === 'ownerName'
            );
          })
          .map((key) => {
            let header: string;

            switch (key) {
              case 'customerName':
                header = 'NAME';
                break;
              case 'email':
                header = 'EMAIL';
                break;
              case 'phone':
                header = 'PHONE';
                break;
              case 'city':
                header = 'CITY';
                break;
              case 'country':
                header = 'COUNTRY';
                break;
              case 'ownerName':
                header = 'OWNER';
                break;
              default:
                header = key; // Use the original key if no specific header is defined
                break;
            }

            return { header } as TableColumn;
          });
      },
      error: (error: any) => {
        console.error("Error getting contacts", error);
      }
    });

  }

  performCompSearch() {
    // Check if the search term is empty
    if (this._searchTerm === '') {
      this.companyTableData = this._companies;
      return; // Exit the function to avoid further processing
    }

    // Filter the original data array based on the search term
    const filteredData = this._companies.filter((row: any) => {
      // Convert all values of the row to string and check if any value contains the search term
      return Object.values(row).some((value: any) => {
        return String(value).toLowerCase().includes(this._searchTerm.toLowerCase());
      });
    });

    // Assign the filtered data to the displayedDeals array
    this.companyTableData = filteredData;

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }

  exportData(exportType: string, section: string): void {


    if (section === 'companies') {
      if (this._companies) {
        let cmpany = [];
        for (let i = 0; i < this._companies.length; i++) {
          let newItem = {
            'NAME': this._companies[i].customerName,
            'PHONE': "'" + this._companies[i].phone.toString(),
            'EMAIL': this._companies[i].email,
            'CITY': this._companies[i].city,
            'COUNTRY': this.getCountryName(this._companies[i].country),
            'OWNER': this._companies[i].ownerName
          }
          cmpany.push(newItem);
        }
        let Headers = this.companyTableColumns.map(column => column.header);
        this._sharedService.Export(cmpany, Headers, 'Company', exportType);
      }
    }
    else {
      if (this._contacts) {
        let cntct = [];
        for (let i = 0; i < this._contacts.length; i++) {
          let newItem = {
            'NAME': this._contacts[i].customerName,
            'PHONE': "'" + this._contacts[i].phone.toString(),
            'EMAIL': this._contacts[i].email,
            'CITY': this._contacts[i].city,
            'COUNTRY': this.getCountryName(this._contacts[i].country),
            'POSITION': this._contacts[i].role,
            'ACCOUNT': this._contacts[i].companyName,
            'TYPE': this.getSalesTypeLabel(this._contacts[i].salesType),

          }
          cntct.push(newItem);
        }
        let Headers = this.contactTableColumns.map(column => column.header);
        this._sharedService.Export(cntct, Headers, 'Contact', exportType);
      }
    }
  }

  //let Headers = ['Name', 'Phone', 'Email', 'City', 'Country', 'Account', 'Role'];
  //'Role': this._contacts[i].role,

  addContact() {
    this._contact = {
      Id: 0,
      CustomerName: "",
      Phone: "",
      Email: "",
      role: "",
      Fax: "",
      DateOfBirth: "",
      Details: "",
      ContactType: 0,
      CreatedBy: this._currentUserId,
      SalesType: 0,
      openPositions: 0,
      companyURL: '',
      linkedInURL: '',
      noOfEmployees: '',
    }

  }

  editContact(item: any) {
    this._contact = item;
    //console.log("edit contact", this._contact)
    if (this._sharedService.getCustomerType() == 0) {
      this._modalTitle = "Update Contact";
    }
    else {
      this._modalTitle = "Update Company";
    }
  }

  profileClick(text: string, id: any) {
    const customerType = this._sharedService.getCustomerType();
    // console.log("customerType", customerType);

    if (customerType === 1) {
      text = 'company';
    }
    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Contacts & Companies', url: '/contact' },
      { text: 'Profile', url: '' },
    ]);

    this.router.navigate(['/profile'], navigationExtras);
    this._profileClickCounter = 0;
  }

  closeClick() {
    this.refreshContactList();
  }

  getCountryName(country: Country) {
    const _country = this._eCountries.find(c => c.id === country);
    return _country ? _country.value : ' - ';
  }

  getSalesTypeLabel(salesType: eSalesType) {
    const _salesType = this.eSalesTypeOptions.find(c => c.id === salesType);
    return _salesType ? _salesType.value : ' - ';
  }

  //#endregion private functions
}
