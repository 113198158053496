// export interface CompanySetting {
//   id: number;
//   themeColor: string;
//   weightUnit: number;
//   logo: Uint8Array; // Assuming logo is stored as a binary array
//   currency: number;
//   ownedBy: number;
//   creationDate: Date;
//   createdBy: number | null;
//   modificationDate: Date | null;
//   modifiedBy: number | null;
//   deletionDate: Date | null;
//   isDeleted: boolean;
// }

export class CompanySetting {
  id: number;
  themeColor: string;
  weightUnit: WeightUnit | null;
  volumeUnit: VolumeUnit | null;
  dimensionUnit: DimensionUnit | null;
  logo: string;
  currency: Currency;
  ownedBy: number;
  creationDate: Date;
  createdBy: number;
  modificationDate: Date;
  modifiedBy: number;
  deletionDate: Date;
  isDeleted: boolean;
  companyName: string;
  email: string;
  contact: string;
  streetAddress: string;
  city: string;
  country: string;
  branchName: string | null;
  constructor() {
    this.id = 0;
    this.themeColor = '';
    this.weightUnit = null;
    this.volumeUnit = null;
    this.dimensionUnit = null;
    this.logo = '';
    this.currency = Currency.USD;
    this.ownedBy = 0;
    this.creationDate = new Date();
    this.createdBy = 0;
    this.modificationDate = new Date();
    this.modifiedBy = 0;
    this.deletionDate = new Date();
    this.isDeleted = false;
    this.companyName = '';
    this.email = '';
    this.contact = '';
    this.streetAddress = '';
    this.city = '';
    this.country = '';
    this.branchName = null;

  }
}

export enum VolumeUnit {
  CubicFeet = 10,
  CubicMeters = 20,
}

export enum WeightUnit {
  Kilograms = 10,
  Pounds = 20,
}

export enum DimensionUnit {
  Meters = 40,
}


export enum Currency {
  USD = 10,
  EUR = 20,
  GBP = 30,
  JPY = 40,
  AUD = 50,
  CAD = 60,
  PKR = 70,
  SAR = 80,
}
export interface EnumOption {
  id: number;
  value: string;
}
export const WeightUnitOptions: EnumOption[] = [
  { id: WeightUnit.Kilograms, value: 'Kilograms' },
  { id: WeightUnit.Pounds, value: 'Pounds' },
];

export const VolumeUnitOptions: EnumOption[] = [
  { id: VolumeUnit.CubicFeet, value: 'Cubic Feet' },
  { id: VolumeUnit.CubicMeters, value: 'Cubic Meters' },
];

export const DimensionUnitOptions: EnumOption[] = [
  { id: DimensionUnit.Meters, value: 'Meters' },

];

export const CurrencyOptions: EnumOption[] = [
  { id: Currency.USD, value: 'USD' },
  { id: Currency.EUR, value: 'EUR' },
  { id: Currency.GBP, value: 'GBP' },
  { id: Currency.JPY, value: 'JPY' },
  { id: Currency.AUD, value: 'AUD' },
  { id: Currency.CAD, value: 'CAD' },
  { id: Currency.PKR, value: 'PKR' },
  { id: Currency.SAR, value: 'SAR' },

];


export function getCurrencySymbol(currency: Currency): string {
  switch (currency) {
    case Currency.USD:
      return '$';
    case Currency.EUR:
      return '€';
    case Currency.GBP:
      return '£';
    case Currency.JPY:
      return '¥';
    case Currency.AUD:
      return 'A$';
    case Currency.CAD:
      return 'C$';
    case Currency.PKR:
      return 'Rs.';
    case Currency.SAR:
      return 'SR';
    default:
      return ''; // Handle any unknown values with a default symbol
  }
}

export function getWeightUnitShortLabel(unit: WeightUnit): string {
  switch (unit) {
    case WeightUnit.Kilograms:
      return 'kg';
    case WeightUnit.Pounds:
      return 'lbs';
    default:
      return '';
  }
}

export function getVolumeUnitShortLabel(unit: VolumeUnit): string {
  switch (unit) {
    case VolumeUnit.CubicFeet:
      return 'cuft';
    case VolumeUnit.CubicMeters:
      return 'cbm';
    default:
      return '';
  }
}

export function getDimensionUnitShortLabel(unit: DimensionUnit): string {
  switch (unit) {
    case DimensionUnit.Meters:
      return 'm';
    default:
      return 'm';
  }
}
