import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/services/auth.service';
import { ContactService } from 'src/services/contact.service';
import { AppComponent } from 'src/app/app.component';
import { MovdService } from '../../services/movd.service';
import { RolePermission } from '../../models/role-permission.model';
import { Permission, PermissionDTO, PermissionDetailsDTO } from '../../models/permission.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-role-permissions',
  templateUrl: './role-permissions.component.html',
  styleUrls: ['./role-permissions.component.scss']
})

export class RolePermissionsComponent implements OnInit {
  _isDataLoaded: boolean = false;

  ColumnMode = ColumnMode;
  @ViewChild('ngx-datatable') table?: DatatableComponent;

  // _roles: RoleModel[] = [];
  _roleId: number = 0;
  _permissionId: number = 0;
  _users: any = null;
  _userRoleId: number = 0;
  _companyId: number = 1;
  _permission = new Permission();
  _moduleName!: string;
  _rolesPermissions: PermissionDTO[] = [];
  _permissionsList: PermissionDetailsDTO[] = [];
  _moduleNames: string[] = [];

  actionIcons: { [action: string]: { icon: string, title: string } } = {
    'C': { icon: 'fas fa-plus-circle', title: 'Create' },
    'R': { icon: 'far fa-eye', title: 'Read' },
    'U': { icon: 'fas fa-pencil-alt', title: 'Update' },
    'D': { icon: 'fas fa-trash-alt', title: 'Delete' }
  };

  actions: string[] = ['C', 'R', 'U', 'D']; //['C', 'D', 'R', 'U']

  checkboxStates: { [key: string]: boolean } = {};

  constructor(
    private _contactService: ContactService,
    private _authService: AuthService,
    private _movdService: MovdService,
    private _modalService: NgbModal,
    private _appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.getAllPermissions();
  }

  getAllPermissions() {
    this._rolesPermissions = [];
    this._permissionsList = [];
    this._moduleNames = [];
    this._isDataLoaded = false;

    this._authService.getAllPermissions().subscribe({
      next: (response: PermissionDTO[]) => {
        //console.log('Permissions get successfully:', response);
        if (response.length > 0) {
          // this._rolesPermissions = response.filter(item => item.roleName !== 'CompanyAdmin'); // Assign the response to _rolesPermissions
          this._rolesPermissions = response;
          response.forEach(rolePermissions => { // Change here: loop over each PermissionDTO in the response
            if (rolePermissions.permissions && rolePermissions.permissions.length > 0) {
              this._permissionsList = rolePermissions.permissions;
              let moduleNames = this._permissionsList.map(permission => this.getModuleName(permission.permissionName));
              this._moduleNames = [...new Set(moduleNames)];

              // Store the checked state of each checkbox in checkboxStates
              this._permissionsList.forEach(permission => {
                let key = `${rolePermissions.roleId}.${this.getModuleName(permission.permissionName)}`;
                this.checkboxStates[key] = permission.isSelected;

                // console.log("this.checkboxStates", this.checkboxStates);
              });
            }
          });
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
          this._isDataLoaded = true;

        }
        // this._isDataLoaded = true;
      },
      error: (error: any) => {
        console.error('Error getting all permissions', error);
        this._isDataLoaded = true;
      }
    });
  }

  getAction(permissionName: string): string {
    let parts = permissionName.split('.');
    return parts.length > 1 ? parts[1] : '';
  }
  
  getIconClass(action: string): string {
    return this.actionIcons[action]?.icon;
  }
  
  getIconTitle(action: string): string {
    return this.actionIcons[action]?.title;
  }
  

  getPermissionId(roleId: number, moduleName: string, action: string): number | null {
    let rolePermissions = this._rolesPermissions.find(r => r.roleId === roleId); // _rolesPermissions is an array of PermissionDTO
    if (rolePermissions) {
      let permission = rolePermissions.permissions.find(p => this.getModuleName(p.permissionName) === moduleName && p.permissionName.endsWith(action));
      return permission ? permission.permissionId : null;
    }
    return null;
  }


  getModuleName(fullName: string): string {
    return fullName.split('.')[0];
  }

  getModuleAndAction(fullName: string): { moduleName: string, action: string } {
    let parts = fullName.split('.');
    return {
      moduleName: parts[0],
      action: parts.length > 1 ? parts[1] : ''
    };
  }

  handleCheckboxChange(roleId: number, permissionId: number, event: any): void {
    let rolePermission = new RolePermission();
    rolePermission.permissionId = permissionId;
    rolePermission.roleId = roleId;
    rolePermission.isSelected = event.target.checked;

    // console.log("saving role permission", rolePermission);
    this._authService.saveRolePermission(rolePermission).subscribe({
      next: (response) => {
        // Handle success if needed
        // console.log('Permission saved successfully', response);
        // this.getAllPermissions();
      },
      error: (error) => {
        // Handle error if needed
        console.error('Error saving permission', error);
      }
    });

  }

  saveChanges(): void {
    this.getAllPermissions();
  }

  openModuleModal(content: any): void {
    this._modalService.open(content, { centered: true, size: 'md' });
  }

  savePermission(module: string, action: string) {

    let permission = new Permission();
    permission.module = module;
    permission.operation = action;

    // console.log("saving permission", permission);

    this._authService.savePermission(permission).subscribe({
      next: (response: any) => {
        // console.log('Permission saved successfully:', response);
        if (response) {
          this._permissionId = response.id;
          // console.log('_permission Id', this._permissionId);
          this._modalService.dismissAll();
          this._appComponent.showSuccessSwal('created', 'record');
          this.getAllPermissions();
          this._moduleName = '';
        }
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('creating', 'record');
        console.error('Error', error);
      }
    });
  }



  saveRolePermission() {

  }
}
