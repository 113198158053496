import { eMoveType, eMoveCategoryType, eTransportType } from "../models/lead-detail.model";

export class MoveBoardDTO {
    constructor(
        public leadId: number | null = null,
        public leadDetailId: number | null = null,
        public moveDetailId: number | null = null,
        public surveyId: number | null = null,
        public packingId: number | null = null,
        public customerId: number | null = null,
        public customer: string | null = null,
        public phone: string | null = null,
        public email: string | null = null,
        public account: string | null = null,
        public accountId: number | null = null,
        public accountName: string | null = null,
        public pocId: number | null = null,
        public pocName: string | null = null,
        public thirdPartyId: number | null = null,
        public thirdPartyName: string | null = null,
        public thirdPartyPocId: number | null = null,
        public thirdPartyPocName: string | null = null,
        public status: string | null = null,
        public origin: string | null = null,
        public destination: string | null = null,
        public moveType: eMoveType | null = null,
        public moveCategory: eMoveCategoryType | null = null,
        public moveTransport: eTransportType | null = null,
        public surveyDate: Date | null = null,
        public packagingDateFrom: Date | null = null,
        public packagingDateTo: Date | null = null,
        public loadingDateFrom: Date | null = null,
        public loadingDateTo: Date | null = null,
        public storageDateFrom: Date | null = null,
        public storageDateTo: Date | null = null,
        public deliveryDate: Date | null = null,
        public accountManagerId: number | null = null,
        public accountManager: string | null = null,
        public portOfLoading: string | null = null,
        public portOfDischarge: string | null = null,
        public placeOfDelivery: string | null = null,
        public scheduledArrivalDate: Date | null = null,
        public arrivalDate: Date | null = null,
        public scheduledDepartureDate: Date | null = null,
        public departureDate: Date | null = null,
        public scheduledDispatchDate: Date | null = null,
        public dispatchDate: Date | null = null,
        public bLNo: string | null = null,
        public awbNo: string | null = null,
        public carrierName: string | null = null,
        public flightNo: string | null = null,
        public vesselName: string | null = null,
        public insuranceRequired: boolean | null = null,
        public creationDate: Date | null = null,
        public shipperInfo: string | null = null,
        public consigneeInfo: string | null = null,
        public notifyParty: string | null = null,
        public cancelled: boolean | null = null,
        public completed: boolean | null = null,
        public cancelReason: string | null = null,

        // properties being used for formatting
        public warehouseStorage: string | null = null,
        public formattedMoveDetailId: string | null = null,
        public formattedLeadDetailId: string | null = null,
        public formattedSurveyId: string | null = null,
        public formattedPackingId: string | null = null,
        public packagingDate: string | null = null,
        public loadingDate: string | null = null,
        public storageDate: string | null = null,
        public moveTypeLabel: string | null = null,
        public moveCategoryLabel: string | null = null,
        public moveTransportLabel: string | null = null,
        public formattedDeliveryDate: string | null = null,
        public formattedSurveyDate: string | null = null
    ) { }
}
export interface MovesListResponse {
    movesList: MoveBoardDTO[];
    totalRecords: number;
}

export const allMovesColumns: string[] = [
    "Move ID",
    "Survey ID",
    "Packing ID",
    "Account",
    "Customer",
    "Status",
    "Origin",
    "Destination",
    "Move",
    "POL",
    "POD",
    "Place Of Delivery",
    "POC",
    "Third Party",
    "Third Party POC",
    "Move Category",
    "Transport Type",
    "Warehouse Storage",
    "Survey Date",
    "Pack Date",
    "Loading Date",
    "Storage Date",
    "Delivery Date",
    "Account Manager",
    "Action"
];