import { Injectable } from '@angular/core';
import { jsPDF, Html2CanvasOptions } from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';
import { HttpClient } from '@angular/common/http';
import { CompanySetting, getCurrencySymbol, getDimensionUnitShortLabel, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../app/views/movd/models/companysetting.model';
import { MovdService } from '../app/views/movd/services/movd.service';
import { GeneralService } from './general.service';
import { EnumMappingService } from '../app/views/movd/services/movd.enum.service';
import { MoveSurvey } from '../app/views/movd/models/move-survery.model';
import { Address } from '../app/views/crm/contact/contact.models';
import { LeadDetail, eMoveCategoryType, eTransportType } from '../app/views/movd/models/lead-detail.model';
import { SurveyMaterial } from '../app/views/movd/models/survery-material.model';
import { PackagingInventoryItem, ePackingType } from '../app/views/movd/models/packaging-inventory-item.model';
import { PackagingInventory } from '../app/views/movd/models/packaging-inventory.model';
import { PackagingBoardListDTO, PackingListResponse } from 'src/app/views/movd/survey-manager/packing-list/packing-board.model';
import { LeadBoardDTO, LeadBoardResponse } from 'src/app/views/movd/lead-board/lead-board.model';
import { Country } from 'src/app/views/movd/models/address.model';

@Injectable({
  providedIn: 'root',
})
export class PdfService {

  packagingTypeOptions = Object.keys(ePackingType)
    .map((key: any) => ({ value: +key, label: ePackingType[key] }))
    .filter((option: any) => !isNaN(option.value));
  pdfDocGenerator: any;
  _companySetting = new CompanySetting();
  _leadDetail: LeadDetail = new LeadDetail();
  _moveSurvey = new MoveSurvey();
  _originAddresses: Address[] = [];
  _destinationAddresses: Address[] = [];
  _areaItems: SurveyMaterial[] = [];
  _materials: SurveyMaterial[] = [];
  _labour: SurveyMaterial[] = [];
  _vehicles: SurveyMaterial[] = [];
  _containers: SurveyMaterial[] = [];
  _surveyMaterialsList!: SurveyMaterial[];
  _filteredAreaItems: SurveyMaterial[] = [];
  _surveyPlaceToItemMap: Map<string, string> = new Map();
  _selectedSurveyPlace: string | null = null;
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _dimensionUnit: string = '';
  _currency: string = '';
  _leadData = new LeadBoardDTO();
  _leadDetailId: any;
  _leadId: any;
  kgToLbsConversionFactor: number = 2.20462;
  cuftToCbmConversionFactor: number = 0.0283168;
  _packagingDetailId: number = 0;
  _packagingInventoryId: number = 0;
  _packagingInventory = new PackagingInventory();
  _packingDetail = new PackagingBoardListDTO();
  _packagingDetailDTO = new PackagingBoardListDTO();
  _inventoryList: PackagingInventoryItem[] = [];
  _assembleDisassembleList: PackagingInventoryItem[] = [];
  _cratedItemList: PackagingInventoryItem[] = [];
  _electronicList: PackagingInventoryItem[] = [];
  _highValueList: PackagingInventoryItem[] = [];
  _filteredInventoryList: PackagingInventoryItem[] = [];


  constructor(
    private _movdService: MovdService,
    private _http: HttpClient,
    public generalService: GeneralService,
    public enumMappingService: EnumMappingService,
  ) {

  }
  ConvertToPDF(jsonData: any[], displayNames: { [key: string]: string }, title: string, filename: string) {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
    });

    // Set font styles
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');

    // Calculate the width and height for each cell
    const cellWidth = 50;
    const cellHeight = 10;

    // Calculate the available width on the page
    const availableWidth = doc.internal.pageSize.width - 20;

    // Calculate the starting X position for centering
    const startX = (availableWidth - (Object.keys(displayNames).length * cellWidth)) / 2;

    // Set the position for the title
    const titleX = availableWidth / 2;
    const titleY = 20;

    // Set the position for the data
    let dataX = startX;
    let dataY = 40;

    // Draw the title
    doc.text(title, titleX, titleY, { align: 'center' });

    // Draw headers
    let currentX = startX;
    for (const header of Object.keys(displayNames)) {
      doc.rect(currentX, dataY, cellWidth, cellHeight, 'S'); // Draw cell border
      doc.text(displayNames[header], currentX + cellWidth / 2, dataY + cellHeight / 2, { align: 'center' }); // Center align header
      currentX += cellWidth;
    }

    // Draw data rows
    for (const item of jsonData) {
      let currentX = startX;
      let currentY = dataY + cellHeight; // Move to the next row

      for (const header of Object.keys(displayNames)) {
        const cellContent = item[header].toString();
        const adjustedContent = cellContent.length > 15 ? cellContent.substring(0, 15) + '...' : cellContent; // Adjust content length
        doc.rect(currentX, currentY, cellWidth, cellHeight, 'S'); // Draw cell border
        doc.text(adjustedContent, currentX + cellWidth / 2, currentY + cellHeight / 2, { align: 'center' }); // Center align the text
        currentX += cellWidth;
      }

      dataY += cellHeight; // Move to the next row
    }

    // Save the PDF with the specified filename
    doc.save(`${filename}.pdf`);
  }



  ConvertHTMLToPDF(content: HTMLElement, filename: string) {
    // Increase the resolution (DPI)
    const dpi = 300; // Adjust as needed, higher DPI will result in better quality but larger file size

    // Calculate the scale factor based on the desired DPI
    const scaleFactor = dpi / 96; // 96 DPI is the default screen DPI

    html2canvas(content, { scale: scaleFactor }).then((canvas) => {
      // Convert the canvas to a data URL with higher resolution
      const imgData = canvas.toDataURL('image/jpeg', 1.0); // Use JPEG for better quality

      // Calculate the size of the PDF page based on DPI
      const pageSize = {
        width: canvas.width * 0.75, // 1 unit in jsPDF = 0.75 px
        height: canvas.height * 0.75,

      };

      const marginValue = 50;

      // Create a new jsPDF instance
      const pdf = new jsPDF({
        orientation: 'portrait', // or 'landscape'
        unit: 'px',
        format: [pageSize.width, pageSize.height], // Set the page size based on the canvas size

      });

      const contentWidth = pageSize.width - 2 * marginValue;
      const contentHeight = pageSize.height - 2 * marginValue;
      const imageX = marginValue;
      const imageY = marginValue;
      // Add an image to the PDF (the content captured as an image)
      pdf.addImage(imgData, 'JPEG', imageX, imageY, contentWidth, contentHeight);

      // Save or display the PDF
      pdf.save(`${filename}.pdf`);
    });
  }
  async getCompanySetting(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const companyId = Number(localStorage.getItem('companyId'));
      this._companySetting = new CompanySetting();
      this._movdService.getCompanySettings().subscribe({
        next: (response: CompanySetting) => {
          if (response) {
            this._companySetting = response;
            if (response.weightUnit) {
              this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
            }
            if (response.volumeUnit) {
              this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
            }
            if (response.dimensionUnit) {
              this._dimensionUnit = getDimensionUnitShortLabel(response.dimensionUnit);
            }
            if (response.currency) {
              this._currency = getCurrencySymbol(response.currency);
            }
          }
          resolve(); // Resolve the promise after successful completion
        },
        error: (error: any) => {
          console.error('Error getting settings:', error);
          reject(error); // Reject the promise in case of an error
        }
      });
    });
  }
  createSurveyPdf() {
    return new Promise<void>((resolve, reject) => {
      // Include your docDefinition code here
      var docDefinition: any = {
        content: [
          this._companySetting.logo && this._companySetting.logo.startsWith('data:image') ? {
            image: this._companySetting.logo,
            width: 110,
            alignment: 'center',
          } : {
            text: 'Company Logo',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10],
          },
          {
            text: 'Survey Summary',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10] // [left, top, right, bottom]
          },
          {
            text: 'General Info',
            style: 'smallHeadings',
            alignment: 'left',
            margin: [0, 10, 0, 5] // [left, top, right, bottom]
          },
        ],
        styles: {
          header: {
            fontSize: 20,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          columnText: {
            fontSize: 11,
          },
          simpleText: {
            bold: true,
            fontSize: 12,
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          smallHeadings: {
            bold: true,
            fontSize: 14,
            color: 'black'
          }
        },
        footer: function (currentPage: any, pageCount: any) {
          return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 10, alignment: 'right', margin: [0, 5, 40, 0] };
        }
      };
      docDefinition.content.push(
        {
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        },
      );

      var firstTableData = [];

      // Check conditions and add data accordingly
      // firstTableData.push(
      //   [{ text: 'Lead Id', style: 'columnText', alignment: 'left' },
      //   { text: '#' + this._leadData?.leadDetailId || ' - ', style: 'columnText', alignment: 'left' }]
      // );

      firstTableData.push([
        { text: 'Lead Id', style: 'columnText', alignment: 'left' },
        { text: this._leadData.formattedLeadDetailId || ' - ', style: 'columnText', alignment: 'left' }
      ]);

      firstTableData.push(
        [{ text: 'Customer Id', style: 'columnText', alignment: 'left' },
        { text: this._leadData.formattedCustomerId || ' - ', style: 'columnText', alignment: 'left' }]
      );


      firstTableData.push(
        [{ text: 'Customer Name', style: 'columnText', alignment: 'left' },
        { text: this._leadData?.customer || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Account Name', style: 'columnText', alignment: 'left' },
        { text: this._leadData?.accountName || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Move Type', style: 'columnText', alignment: 'left' },
        { text: this._leadData.moveTypeLabel || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Move Category', style: 'columnText', alignment: 'left' },
        { text: this._leadData.moveCategoryLabel || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Transport Type', style: 'columnText', alignment: 'left' },
        { text: this._leadData.moveTransportLabel || 0 || ' - ', style: 'columnText', alignment: 'left' }]
      );
      if (this._leadDetail.categoryType == eMoveCategoryType.DoorToDoorExport || this._leadDetail.categoryType == eMoveCategoryType.DoorToPort && this._leadDetail.transportType == eTransportType.Sea || this._leadDetail.transportType == eTransportType.SeaLCL || this._leadDetail.transportType == eTransportType.Air) {
        firstTableData.push(
          [{ text: 'POL (Port of Loading)', style: 'columnText', alignment: 'left' },
          { text: this._leadData?.portOfLoading || ' - ', style: 'columnText', alignment: 'left' }]
        );
        firstTableData.push(
          [{ text: 'POD (Port of Discharge)', style: 'columnText', alignment: 'left' },
          { text: this._leadData?.portOfDischarge || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      firstTableData.push(
        [{ text: 'Place Of Delivery', style: 'columnText', alignment: 'left' },
        { text: this._leadData?.placeOfDelivery || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Requested Survey Date', style: 'columnText', alignment: 'left' },
        { text: this._leadData.formattedSurveyDate || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Requested Packing Date', style: 'columnText', alignment: 'left' },
        { text: this._leadData.packagingDate || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Requested Loading Date', style: 'columnText', alignment: 'left' },
        { text: this._leadData.loadingDate || ' - ', style: 'columnText', alignment: 'left' }]
      );
      if (this._leadData.storageDateFrom) {
        firstTableData.push(
          [{ text: 'Requested Storage Date', style: 'columnText', alignment: 'left' },
          { text: this._leadData.storageDate || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      firstTableData.push(
        [{ text: 'Client Notes', style: 'columnText', alignment: 'left' },
        { text: this._moveSurvey.externalNotes || ' - ', style: 'columnText', alignment: 'left' }]
      );

      if (docDefinition && docDefinition.content && docDefinition.content[3] && docDefinition.content[3].table && docDefinition.content[3].table.body) {
        // Add dynamic data to the second table
        firstTableData.forEach(data => {
          docDefinition.content[3].table.body.push(data);
        });
      }

      //Address Details
      docDefinition.content.push(
        { text: 'Address Details', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
      );

      for (let i = 0; i < Math.max(this._originAddresses.length, this._destinationAddresses.length); i++) {
        // Check if either origin or destination address exists at index i
        if (this._originAddresses[i] || this._destinationAddresses[i]) {
          // Add row for indicating the address index
          if (i !== 0) {
            docDefinition.content.push({
              text: `${i === 0 ? 'First' : i === 1 ? 'Second' : 'Third'} Address`,
              style: 'tableHeader',
              margin: [0, 10, 0, 6], // [left, top, right, bottom]
            });
          }

          // Add row for Origin and Destination headers
          docDefinition.content.push({
            table: {
              widths: ['25%', '25%', '25%', '25%'],
              body: [
                [{ text: 'Origin', style: 'tableHeader' }, '', { text: 'Destination', style: 'tableHeader' }, ''],
              ],
            },
            // Remove borders
          });

          // Add rows for street, city, state, country, and postal code
          const addressRows = [
            ['Street', this._originAddresses[i]?.streetAddress || '-', 'Street', this._destinationAddresses[i]?.streetAddress || '-'],
            ['City', this._originAddresses[i]?.city || '-', 'City', this._destinationAddresses[i]?.city || '-'],
            ['State', this._originAddresses[i]?.state || '-', 'State', this._destinationAddresses[i]?.state || '-'],
            ['Country', this.getCountryName(this._originAddresses[i]?.country), 'Country', this.getCountryName(this._destinationAddresses[i]?.country)],
            ['Postal Code', this._originAddresses[i]?.zipCode || '-', 'Postal Code', this._destinationAddresses[i]?.zipCode || '-']
          ];

          addressRows.forEach(row => {
            docDefinition.content.push({
              table: {
                widths: ['25%', '25%', '25%', '25%'],
                body: [row],
              }, // Remove borders
            });
          });
          addressRows.forEach(row => {
            // Check if docDefinition and its properties exist before pushing data into it
            if (docDefinition && docDefinition.content && docDefinition.content[4] && docDefinition.content[4].table && docDefinition.content[4].table.body) {
              docDefinition.content[4].table.body.push(row);
            }
          });
        }
      }

      docDefinition.content.push({
        text: 'Survey Details',
        style: 'smallHeadings',
        alignment: 'left',
        margin: [0, 10, 0, 10]
      });
      console.log('Final :', docDefinition);
      // Group items by surveyPlace
      const groupedItems: { [key: string]: typeof tableData } = {};
      this._areaItems.forEach(item => {
        if (!item.isDeleted) {
          const surveyPlace = item.surveyPlace || 'Unknown'; // Use 'Unknown' as fallback if surveyPlace is null
          if (!groupedItems[surveyPlace]) {
            groupedItems[surveyPlace] = [];
          }
          groupedItems[surveyPlace].push([
            { text: item.item || ' - ' },
            { text: item.quantity || ' - ' },
            { text: item.volume ? item.volume.toFixed(2) : ' - ' },
            { text: item.weight ? item.weight.toFixed(2) : ' - ' },
            { text: item.dimension || ' - ' }
          ]);
        }
      });

      // Generate table data
      const tableData: any[] = [];
      for (const surveyPlace in groupedItems) {
        if (Object.prototype.hasOwnProperty.call(groupedItems, surveyPlace)) {
          const itemCount = this.getSurveyItemCount(surveyPlace);
          const volume = this.calculateSurveyVolume(surveyPlace, this._volumeUnit, 'bySurveyPlace');
          const weight = this.calculateSurveyWeight(surveyPlace, this._weightUnit, 'bySurveyPlace');


          tableData.push([
            { text: `${surveyPlace} (Item count: ${itemCount}, Volume: ${volume}, Weight: ${weight})`, colSpan: 5, bold: true, fillColor: '#f2f2f2' },
            {}, {}, {}
          ]);
          tableData.push(...groupedItems[surveyPlace]);
        }
      }

      // Push the table definition into the content array
      docDefinition.content.push({
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*'],
          body: [
            ['Item', 'Quantity', `Volume (${this._volumeUnit})`, `Weight (${this._weightUnit})`, `Dimension (${this._dimensionUnit})`],
            ...tableData
          ]
        },
      });

      docDefinition.content.push(
        { text: 'Shipment Details', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
      );

      var shipmentData = [
        { text: this._leadData.moveTransportLabel || ' - ', style: 'columnText', alignment: 'left' },
        { text: this.calculateSurveyVolume('', this._volumeUnit, 'total') || ' - ', style: 'columnText', alignment: 'left' },
        { text: this.calculateSurveyWeight('', this._weightUnit, 'total') || ' - ', style: 'columnText', alignment: 'left' },
      ];


      docDefinition.content.push(
        {
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['33%', '33%', '34%'],
            body: [
              [
                { text: 'Shipment', style: 'tableHeader', alignment: 'left' },
                { text: 'Volume ' + '(' + (this._volumeUnit) + ')', style: 'tableHeader', alignment: 'left' },
                { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
              ],
              shipmentData
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        },
      );
      docDefinition.content.push(
        { text: 'Signatures', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
      );
      docDefinition.content.push({
        columns: [
          {
            stack: [
              {
                text: 'Surveyed By:', style: 'simpleText', alignment: 'left',
                margin: [0, 10, 0, 0]
              },
              {
                text: 'Survey Manager', style: 'columnText', alignment: 'left',
                margin: [0, 10, 0, 0]
              },
              this._moveSurvey.managerSignature ? {
                image: this._moveSurvey.managerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0]
              } : null
            ]
          },
          {
            stack: [
              {
                text: 'Client Name:', style: 'simpleText', alignment: 'right',
                margin: [0, 10, 0, 0]
              },
              {
                text: this._leadData?.customer || ' - ', style: 'columnText', alignment: 'right',
                margin: [0, 10, 0, 0]
              },
              this._moveSurvey.customerSignature ? {
                image: this._moveSurvey.customerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0],
                alignment: 'right'
              } : null
            ]
          }
        ]
      });
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      this.pdfDocGenerator = pdfDocGenerator;
      resolve();
    });
  }

  async getSurveyDetailsForPdf(leadDetailId: any, leadId: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this._leadDetailId = leadDetailId;
        this._leadId = leadId;

        // Define an array to store the promises for each asynchronous operation
        const promises: Promise<void>[] = [];

        // Get lead details by lead ID
        promises.push(new Promise<void>((resolve, reject) => {
          this._movdService.getLeadSummary(this._leadDetailId).subscribe({
            next: (data: LeadBoardResponse) => {
              console.log("lead details by leadId", data);
              if (data.leads.length > 0) {
                this._leadData = data.leads[0];
                // console.log("lead details by leadId", this._leadData);
              }
              resolve(); // Resolve the promise when the operation is completed
            },
            error: (error) => {
              console.error("error getting lead detail", error);
              reject(error); // Reject the promise if an error occurs
            }
          });
        }));

        // Get move surveys
        promises.push(new Promise<void>((resolve, reject) => {
          this._areaItems = [];
          this._materials = [];
          this._labour = [];
          this._vehicles = [];
          this._containers = [];

          this._movdService.getMoveSurvey(this._leadDetailId).subscribe({
            next: (data: any) => {
              // console.log("move survey list", data);
              if (data) {
                this._moveSurvey = data;
                this._surveyMaterialsList = this._moveSurvey.surveyMaterials;
                this._surveyMaterialsList.forEach((material: SurveyMaterial) => {
                  switch (material.type) {
                    case 'Material':
                      this._materials.push(material);
                      break;
                    case 'Area Item':
                      this._areaItems.push(material);
                      break;
                    case 'Labour':
                      this._labour.push(material);
                      break;
                    case 'Vehicle':
                      this._vehicles.push(material);
                      break;
                    case 'Container':
                      this._containers.push(material);
                      break;
                    // Handle other types if needed
                    default:
                      break;
                  }
                });

                this.initializeMaterialRows();

                if (this._surveyPlaceToItemMap.size > 0) {
                  this._selectedSurveyPlace = Array.from(this._surveyPlaceToItemMap.keys())[0];
                  this.filterItemsBySurveyPlace(this._selectedSurveyPlace);
                }

                if (this._areaItems.length > 0) {
                  this._areaItems.forEach((item) => {
                    if (item.surveyPlace !== null && item.item) {
                      this._surveyPlaceToItemMap.set(item.surveyPlace, item.item);
                    }
                  });

                  // Get the first key from the map and call filterItemsBySurveyPlace
                  const firstSurveyPlace = Array.from(this._surveyPlaceToItemMap.keys())[0];
                  if (firstSurveyPlace) {
                    this.filterItemsBySurveyPlace(firstSurveyPlace);
                  }
                }
              } else {
                this._moveSurvey = new MoveSurvey();
                this.initializeMaterialRows();
              }
              resolve(); // Resolve the promise when the operation is completed
            },
            error: (error) => {
              console.error(error);
              reject(error); // Reject the promise if an error occurs
            }
          });
        }));

        // Get lead detail by ID
        promises.push(new Promise<void>((resolve, reject) => {
          this._originAddresses = [];
          this._destinationAddresses = [];
          this._movdService.getLeadDetailById(this._leadDetailId).subscribe({
            next: (data: any) => {
              // console.log("one lead detail", data);
              if (data) {
                this._leadDetail = data;
                if (this._leadDetail && this._leadDetail.addresses !== null) {
                  const originAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 0));
                  const destAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 1));

                  if (originAdrress.length > 0) {
                    this._originAddresses = originAdrress;
                  }

                  if (destAdrress.length > 0) {
                    this._destinationAddresses = destAdrress;
                  }
                }
              } else {
                this._leadDetail = new LeadDetail();
              }
              resolve(); // Resolve the promise when the operation is completed
            },
            error: (error) => {
              console.error(error);
              reject(error); // Reject the promise if an error occurs
            }
          });
        }));

        // Get company settings
        promises.push(
          new Promise<void>((resolve, reject) => {
            this.getCompanySetting().then(() => {
              resolve(); // Resolve the promise when the operation is completed
            }).catch((error) => {
              console.error("Error getting company settings:", error);
              reject(error); // Reject the promise if an error occurs
            });
          })
        );

        // Wait for all promises to resolve using Promise.all
        Promise.all(promises)
          .then(() => {
            // All asynchronous operations completed successfully
            resolve();
          })
          .catch((error) => {
            // Handle errors if any of the promises reject
            console.error("Error fetching survey detail:", error);
            reject(error);
          });
      } catch (error) {
        console.error("Error fetching survey detail:", error);
        reject(error);
      }
    });
  }

  initializeMaterialRows() {
    if (this._materials && this._materials.length === 0) {
      this.addRow('Material', this._materials);
    }
    if (this._labour && this._labour.length === 0) {
      this.addRow('Labour', this._labour);
    }
    if (this._vehicles && this._vehicles.length === 0) {
      this.addRow('Vehicle', this._vehicles);
    }
    if (this._containers && this._containers.length === 0) {
      this.addRow('Container', this._containers);
    }
  }
  addRow(type: string, targetArray: SurveyMaterial[]): void {
    const newMaterial = new SurveyMaterial();
    newMaterial.leadDetailId = this._leadDetailId;
    newMaterial.type = type;
    targetArray.push(newMaterial);
  }

  getSurveyItemCount(surveyPlace: string): number {
    const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }
  calculateSurveyVolume(surveyPlace: string, unit: string, type: string): string | number {
    let totalVolume;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalVolume = items.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    } else {
      totalVolume = this._areaItems.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalVolume.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'cuft' && this._volumeUnit === 'cuft') {
      // Convert from cuft to cbm
      const convertedVolume = totalVolume * this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cbm`;
    } else if (unit === 'cbm' && this._volumeUnit === 'cbm') {
      // Convert from cbm to cuft
      const convertedVolume = totalVolume / this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cuft`;
    }

    return result;
  }
  calculateSurveyWeight(surveyPlace: string, unit: string, type: string): string | number {
    let totalWeight;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalWeight = items.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    } else {
      totalWeight = this._areaItems.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalWeight.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'kg' && this._weightUnit === 'kg') {
      // Convert from kg to lbs
      const convertedWeight = totalWeight * this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} lbs`;
    } else if (unit === 'lbs' && this._weightUnit === 'lbs') {
      // Convert from lbs to kg
      const convertedWeight = totalWeight / this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} kg`;
    }

    return result;
  }
  filterItemsBySurveyPlace(selectedSurveyPlace: string | null) {
    if (selectedSurveyPlace) {
      this._filteredAreaItems = this._areaItems.filter((item) => item.surveyPlace === selectedSurveyPlace);
    } else {
      this._filteredAreaItems = [];
    }
  }
  async generateSurveyPdf(leadDetailId: any, leadId: any, pdfType: any) {
    await this.getSurveyDetailsForPdf(leadDetailId, leadId);

    if (!this.pdfDocGenerator || this.pdfDocGenerator) {
      await this.createSurveyPdf();
    }

    if (pdfType === 'View') {
      this.pdfDocGenerator.open();
    }
    else if (pdfType === 'Download') {
      this.pdfDocGenerator.download();
    }
  }

  createPackingPdf() {
    return new Promise<void>((resolve, reject) => {
      // Include your docDefinition code here
      var docDefinition: any = {
        content: [
          this._companySetting.logo && this._companySetting.logo.startsWith('data:image') ? {
            image: this._companySetting.logo,
            width: 110,
            alignment: 'center',
          } : {
            text: 'Company Logo',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10],
          },
          {
            text: 'Inventory List',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10] // [left, top, right, bottom]
          },
          {
            table: {
              layout: 'lightHorizontalLines',
              widths: ['50%', '50%'], // Two columns
              body: []
            },
            margin: [0, 10, 0, 20] // [left, top, right, bottom]
          }
        ],
        styles: {
          header: {
            fontSize: 20,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          columnText: {
            fontSize: 11,
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          smallHeadings: {
            bold: true,
            fontSize: 14,
            color: 'black'
          }
        },
        footer: function (currentPage: any, pageCount: any) {
          return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 10, alignment: 'right', margin: [0, 5, 40, 0] };
        }
      };

      // Your dynamic data for the second table
      var firstTableData = [];

      // Check conditions and add data accordingly
      firstTableData.push(
        [{ text: 'Move Id:', style: 'columnText', alignment: 'left' },
        { text: (this._packagingDetailDTO?.moveDetailId ? this.generalService.formatId(this._packagingDetailDTO.moveDetailId, 'move') : '-'), style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Customer Id:', style: 'columnText', alignment: 'left' },
        { text: (this._packagingDetailDTO?.customerId ? this.generalService.formatId(this._packagingDetailDTO.customerId, 'customer') : '-'), style: 'columnText', alignment: 'left' }]
      );


      firstTableData.push(
        [{ text: 'Customer Name:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.customer || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Account Name:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.accountName || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Move Type:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveType(this._packagingDetailDTO?.moveType || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Move Category:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveCategoryType(this._packagingDetailDTO?.moveCategory || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Transport Type:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapTransportType(this._packagingDetailDTO?.moveTransport || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Origin:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.origin || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Destination:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.destination || ' - ', style: 'columnText', alignment: 'left' }]
      );
      // if (this._leadDetail.categoryType == eMoveCategoryType.DoorToDoorExport || this._leadDetail.categoryType == eMoveCategoryType.DoorToPort && this._leadDetail.transportType == eTransportType.Sea || this._leadDetail.transportType == eTransportType.SeaLCL || this._leadDetail.transportType == eTransportType.Air) {
      //   firstTableData.push(
      //     [{ text: 'POL:', style: 'columnText', alignment: 'left' },
      //     { text: this._packagingDetailDTO?.portOfLoading || ' - ', style: 'columnText', alignment: 'left' }]
      //   );
      //   firstTableData.push(
      //     [{ text: 'POD:', style: 'columnText', alignment: 'left' },
      //     { text: this._packagingDetailDTO?.portOfDischarge || ' - ', style: 'columnText', alignment: 'left' }]
      //   );
      // }
      // firstTableData.push(
      //   [{ text: 'Place Of Delivery:', style: 'columnText', alignment: 'left' },
      //   { text: this._packagingDetailDTO?.placeOfDelivery || ' - ', style: 'columnText', alignment: 'left' }]
      // );
      firstTableData.push(
        [{ text: 'Packaging Date:', style: 'columnText', alignment: 'left' },
        { text: this.generalService.formatDate(this._packagingDetailDTO?.packagingDateFrom, this._packagingDetailDTO?.packagingDateTo) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Client Notes:', style: 'columnText', alignment: 'left' },
        { text: this._packagingInventory?.externalNotes || ' - ', style: 'columnText', alignment: 'left' }]
      );

      if (docDefinition && docDefinition.content && docDefinition.content[2] && docDefinition.content[2].table && docDefinition.content[2].table.body) {
        // Add dynamic data to the second table
        firstTableData.forEach(data => {
          docDefinition.content[2].table.body.push(data);
        });
      }
      // console.log('first table data', firstTableData);

      // Inventory Items List
      if (this._inventoryList.length > 0) {
        docDefinition.content.push(
          { text: 'Inventory Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] }
        );

        var inventoryListData = [];
        for (let row of this._inventoryList) {
          if (row.isDeleted === false) {
            const categoryLabel = this.packagingTypeOptions.find(option => option.value === row.packagingType)?.label || '-';
            inventoryListData.push([row.itemNo, row.description, row.weight, categoryLabel]);
          }
        }

        docDefinition.content.push({
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['15%', '35%', '20%', '30%'],
            body: [
              [
                { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
                { text: 'Category', style: 'tableHeader', alignment: 'left' }
              ],
              // Dynamic data goes here
              ...inventoryListData,
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        });

        const totalItems = this.calculatePackingTotal(this._inventoryList).totalItems;
        const totalWeight = this.calculatePackingWeight(this._weightUnit, 'total', this._inventoryList);

        docDefinition.content.push(
          { text: `Total Items: ${totalItems !== undefined ? totalItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' },
          { text: `Total Weight: ${totalWeight !== undefined ? totalWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
        );
      }
      docDefinition.content.push({ text: '', pageBreak: 'after' });
      // Assemble / disassemble Items Table
      if (this._assembleDisassembleList.length > 0) {
        docDefinition.content.push(
          { text: 'Assemble/ Disassemble Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] }
        );

        var assembleListData = [];
        for (let row of this._assembleDisassembleList) {
          if (row.isDeleted === false) {
            assembleListData.push([row.itemNo, row.description, row.weight]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['15%', '60%', '25%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                  { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' }
                ],
                // Dynamic data goes here
                ...assembleListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          }
        );

        if (docDefinition && docDefinition.content && docDefinition.content[3] && docDefinition.content[3].table && docDefinition.content[3].table.body) {
          // Add dynamic data to the second table
          assembleListData.forEach(data => {
            docDefinition.content[3].table.body.push(data);
          });
        }

        const totalItems = this.calculatePackingTotal(this._assembleDisassembleList).totalItems;
        const totalWeight = this.calculatePackingWeight(this._weightUnit, 'total', this._assembleDisassembleList);



        docDefinition.content.push(
          [
            { text: `Total Items: ${totalItems !== undefined ? totalItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
        docDefinition.content.push(
          [
            { text: `Total Weight: ${totalWeight !== undefined ? totalWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
      }


      // Crated Items Table
      if (this._cratedItemList.length > 0) {
        docDefinition.content.push(
          { text: 'Crated Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        );
        var cratedListData = [];
        for (let row of this._cratedItemList) {
          if (row.isDeleted === false) {
            cratedListData.push([row.itemNo, row.description, row.dimensions, row.cuft, row.weight, row.remarks]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['15%', '25%', '17%', '10%', '17%', '16%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                  { text: 'Dimensions', style: 'tableHeader', alignment: 'left' },
                  { text: 'Cuft', style: 'tableHeader', alignment: 'left' },
                  { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
                  { text: 'Remarks', style: 'tableHeader', alignment: 'left' }
                ],
                // Dynamic data goes here
                ...cratedListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          },
        );

        if (docDefinition && docDefinition.content && docDefinition.content[6] && docDefinition.content[6].table && docDefinition.content[6].table.body) {
          // Add dynamic data to the second table
          cratedListData.forEach(data => {
            docDefinition.content[6].table.body.push(data);
          });
        }
        const totalcratedItems = this.calculatePackingTotal(this._cratedItemList).totalItems;
        const totalcratedItemsWeight = this.calculatePackingWeight(this._weightUnit, 'total', this._cratedItemList);

        docDefinition.content.push(
          [
            { text: `Total Items: ${totalcratedItems !== undefined ? totalcratedItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
        docDefinition.content.push(
          [
            { text: `Total Weight: ${totalcratedItemsWeight !== undefined ? totalcratedItemsWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
      }
      //Electronic Items List
      if (this._electronicList.length > 0) {
        docDefinition.content.push(
          { text: 'Electronic Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        );
        var electronicListData = [];
        for (let row of this._electronicList) {
          if (row.isDeleted === false) {
            electronicListData.push([row.itemNo, row.description, row.make, row.model, row.weight, row.remarks]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['15%', '27%', '15%', '14%', '14%', '15%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                  { text: 'Make', style: 'tableHeader', alignment: 'left' },
                  { text: 'Model/Serial', style: 'tableHeader', alignment: 'left' },
                  { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
                  { text: 'Remarks', style: 'tableHeader', alignment: 'left' }
                ],
                // Dynamic data goes here
                ...electronicListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          },
        );

        if (docDefinition && docDefinition.content && docDefinition.content[6] && docDefinition.content[6].table && docDefinition.content[6].table.body) {
          // Add dynamic data to the second table
          electronicListData.forEach(data => {
            docDefinition.content[6].table.body.push(data);
          });
        }
        const totalElectroniItems = this.calculatePackingTotal(this._electronicList).totalItems;
        const totalElectroniItemsWeight = this.calculatePackingWeight(this._weightUnit, 'total', this._electronicList);

        docDefinition.content.push(
          [
            { text: `Total Items: ${totalElectroniItems !== undefined ? totalElectroniItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
        docDefinition.content.push(
          [
            { text: `Total Weight: ${totalElectroniItemsWeight !== undefined ? totalElectroniItemsWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
      }
      //High Values Items
      if (this._highValueList.length > 0) {
        docDefinition.content.push(
          { text: 'High Value Items', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
        );
        var highValueListData = [];
        for (let row of this._highValueList) {
          if (row.isDeleted === false) {
            highValueListData.push([row.itemNo, row.description, row.dimensions, row.weight, row.value]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['15%', '38%', '18%', '15%', '14%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Description of Item', style: 'tableHeader', alignment: 'left' },
                  { text: 'Dimensions', style: 'tableHeader', alignment: 'left' },
                  { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
                  { text: 'Value ' + 'in ' + '(' + (this._currency) + ')', style: 'tableHeader', alignment: 'left' }
                ],
                // Dynamic data goes here
                ...highValueListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          },
        );

        if (docDefinition && docDefinition.content && docDefinition.content[6] && docDefinition.content[6].table && docDefinition.content[6].table.body) {
          // Add dynamic data to the second table
          highValueListData.forEach(data => {
            docDefinition.content[6].table.body.push(data);
          });
        }
        const totalHighItems = this.calculatePackingTotal(this._highValueList).totalItems;
        const totalHighItemsWeight = this.calculatePackingWeight(this._weightUnit, 'total', this._highValueList);

        docDefinition.content.push(
          [
            { text: `Total Items: ${totalHighItems !== undefined ? totalHighItems.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
        docDefinition.content.push(
          [
            { text: `Total Weight: ${totalHighItemsWeight !== undefined ? totalHighItemsWeight.toString() : ' - '}`, style: 'columnText', alignment: 'left' }
          ]
        );
      }
      docDefinition.content.push({
        columns: [
          {
            stack: [
              {
                text: 'Customer Signature:', style: 'columnText', alignment: 'left',
                margin: [0, 30, 0, 0]
              },
              this._packagingInventory.customerSignature ? {
                image: this._packagingInventory.customerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0]
              } : null
            ]
          },
          {
            stack: [
              {
                text: 'Manager Signature:', style: 'columnText', alignment: 'right',
                margin: [0, 30, 0, 0]
              },
              this._packagingInventory.managerSignature ? {
                image: this._packagingInventory.managerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0],
                alignment: 'right'
              } : null
            ]
          }
        ]
      });
      // console.log('docDefinition:', docDefinition);

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      this.pdfDocGenerator = pdfDocGenerator;
      resolve();
    });
  }

  async getPackingDetailsForPdf(leadDetailId: any): Promise<void> {
    try {
      this._leadDetailId = leadDetailId;

      // Clear the lists
      this._assembleDisassembleList = [];
      this._cratedItemList = [];
      this._electronicList = [];
      this._highValueList = [];

      // Get packaging details by lead detail ID
      await new Promise<void>((resolve, reject) => {
        this._movdService.getPackagingDetailsByLeadDetailId(this._leadDetailId).subscribe({
          next: (data: any) => {
            if (data) {
              this._packagingDetailId = data.id;
              this._packingDetail.packagingDetailId = data.id;
              this._leadId = data.leadId;
            }
            resolve(); // Resolve the promise when the operation is completed
          },
          error: (error) => {
            console.error("Error getting packaging details by lead detail ID:", error);
            reject(error); // Reject the promise if an error occurs
          }
        });
      });

      // Get packaging details by lead ID
      await new Promise<void>((resolve, reject) => {
        this._movdService.getPackagingDetailsByLeadId(this._leadId).subscribe({
          next: (data: PackingListResponse) => {
            console.log('packaging detail id', this._packagingDetailId);
            console.log('packaging detail', data);
            if (data && data.packingList.length > 0) {
              const filteredData = data.packingList.find((item: PackagingBoardListDTO) => item.packagingDetailId === this._packagingDetailId);
              this._packagingDetailDTO = filteredData || new PackagingBoardListDTO();
              console.log("_packagingDetailDTO", this._packagingDetailDTO);
              resolve(); // Resolve the promise when the operation is completed
            }
          },
          error: (error) => {
            console.error("Error getting packaging details by lead ID:", error);
            reject(error); // Reject the promise if an error occurs
          }
        });
      });

      // Get packaging inventory by packaging detail ID
      await new Promise<void>((resolve, reject) => {
        this._movdService.getPackagingInventoryByPackagingDetailId(this._packagingDetailId).subscribe({
          next: (data: any) => {
            if (data) {
              this._packagingInventory = data;
              this._packagingInventoryId = this._packagingInventory.id;
            } else {
              this._packagingInventory = new PackagingInventory();
              this._packagingInventoryId = 0;
              this._inventoryList = [];
            }
            resolve(); // Resolve the promise when the operation is completed
          },
          error: (error: any) => {
            console.error("Error getting packaging inventory by packaging detail ID:", error);
            reject(error); // Reject the promise if an error occurs
          }
        });
      });

      // Get packaging inventory items by packaging inventory ID
      await new Promise<void>((resolve, reject) => {
        this._movdService.getPackagingInventoryItemsByPackagingInventoryId(this._packagingInventoryId).subscribe({
          next: async (data: any) => {
            if (data && data.length > 0) {
              let filteredData = data.filter((item: any) => item.isDeleted === false);
              if (filteredData && filteredData.length > 0) {
                this._inventoryList = filteredData;
                await this.filterAndAssignItemsByPackagingType();
              }
            }
            resolve(); // Resolve the promise when the operation is completed
          },
          error: (error: any) => {
            console.error("Error getting packaging inventory items by packaging inventory ID:", error);
            reject(error); // Reject the promise if an error occurs
          }
        });
      });

      // Get company settings
      await this.getCompanySetting();

      // All asynchronous operations completed successfully
      return Promise.resolve();
    } catch (error) {
      console.error("Error fetching packing details:", error);
      return Promise.reject(error);
    }
  }


  calculatePackingTotal(itemList: PackagingInventoryItem[]): { totalItems: number, totalWeight: number } {
    let totalItems = 0;
    let totalWeight = 0;

    for (let item of itemList) {
      if (!item.isDeleted && item.itemNo) {
        totalItems++;
        totalWeight += item.weight ?? 0;
      }
    }

    return { totalItems, totalWeight };
  }
  getPackingItemCount(surveyPlace: string, itemList: PackagingInventoryItem[]): number {
    const items = itemList.filter((item) => !item.isDeleted);
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  calculatePackingWeight(unit: string, type: string, itemList: PackagingInventoryItem[]): string | number {
    let totalWeight = 0;

    if (type === 'bySurveyPlace') {
      const items = itemList.filter((item) => !item.isDeleted);
      totalWeight = items.reduce((acc, item) => acc + (item.weight || 0), 0);
    } else {
      totalWeight = itemList.reduce((acc, item) => acc + (item.weight || 0), 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalWeight.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'kg' && this._weightUnit === 'kg') {
      // Convert from kg to lbs
      const convertedWeight = totalWeight * this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} lbs`;
    } else if (unit === 'lbs' && this._weightUnit === 'lbs') {
      // Convert from lbs to kg
      const convertedWeight = totalWeight / this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} kg`;
    }

    return result;
  }

  filterAndAssignItemsByPackagingType(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        // Clear the lists
        // this._inventoryList = [];
        this._assembleDisassembleList = [];
        this._cratedItemList = [];
        this._electronicList = [];
        this._highValueList = [];

        // Filter and assign items to the corresponding lists
        this._inventoryList.forEach(item => {
          switch (item.packagingType) {
            case ePackingType.AssembleDisassemble:
              this._assembleDisassembleList.push(item);
              break;
            case ePackingType.CratedItem:
              this._cratedItemList.push(item);
              break;
            case ePackingType.Electronic:
              this._electronicList.push(item);
              break;
            case ePackingType.HighValue:
              this._highValueList.push(item);
              break;
            default:
              break;
          }
        });

        // Resolve the promise when the operation is completed
        resolve();
      } catch (error) {
        console.error("Error filtering and assigning items by packaging type:", error);
        reject(error);
      }
    });
  }

  async generatePackingPdf(leadDetailId: any, pdfType: any) {
    // console.log('lead detail id ', leadDetailId);
    await this.getPackingDetailsForPdf(leadDetailId);
    if (!this.pdfDocGenerator || this.pdfDocGenerator) {
      await this.createPackingPdf();
    }
    if (pdfType === 'View') {
      this.pdfDocGenerator.open();
    }
    else if (pdfType === 'Download') {
      this.pdfDocGenerator.download();
    }
  }
  // Helper function to safely get the country name
  getCountryName(countryCode: Country | null): string {
    return countryCode !== null && countryCode !== undefined ? Country[countryCode] : '-';
  }
}
