import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  token!: string;
  newPassword!: string;
  confirmNewPassword!: string;
  isLoading = false;

  resetPasswordForm: FormGroup = new FormGroup({});
  errorMessage: string | null = null;
  submitted = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private _appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        this.passwordLengthValidator.bind(this),
      ]),
      confirmPassword: new FormControl('', Validators.required),
    });

    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
    });

    // Subscribe to value changes in confirmPassword control
    this.resetPasswordForm.get('confirmPassword')?.valueChanges.subscribe(() => {
      this.validatePasswords();
    });
  }

  passwordLengthValidator(control: FormControl): { [key: string]: boolean } | null {
    // Enforce a minimum length of 8 characters including at least one special character
    const passwordRegex = /^(?=.*[!@#$%^&*/.])[A-Za-z\d!@#$%^&*/.]{8,}$/;

    if (!passwordRegex.test(control.value)) {
      return { 'passwordLength': true };
    }

    return null;
  }

  validatePasswords() {
    const passwordControl = this.resetPasswordForm.get('password');
    const confirmPasswordControl = this.resetPasswordForm.get('confirmPassword');

    if (passwordControl?.value !== confirmPasswordControl?.value) {
      this.errorMessage = '*Passwords do not match!';
    } else {
      this.errorMessage = null;
    }
  }

  resetPassword(e: Event) {
    this.errorMessage = null;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    const passwordControl = this.resetPasswordForm.get('password');
    const confirmPasswordControl = this.resetPasswordForm.get('confirmPassword');

    const newPassword = passwordControl?.value;
    const confirmNewPassword = confirmPasswordControl?.value;

    if (newPassword !== confirmNewPassword) {
      this.errorMessage = '*Passwords do not match!';
      return;
    }

    if (this.token && newPassword) {
      this.authService.resetPassword(this.token, newPassword).subscribe({
        next: () => {
          this.router.navigate(['/login']);
          this._appComponent.showSuccessSwal('password', 'reset');
        },
        error: (error) => {
          console.error(error);
          this._appComponent.showErrorSwal('password', 'reset');
        }
      });
    } else {
      console.error("Passwords do not match");
      this.errorMessage = '*Passwords do not match!';
    }
  }
}


export interface PasswordResetDTO {
  token: string;
  password: string;
}