import { ChangeDetectorRef, Component, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbDropdown, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MovdService } from '../services/movd.service';
import { AppComponent } from '../../../app.component';
import { NavigationExtras, Router } from '@angular/router';
import { Lead } from '../models/lead.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { MoveCategoryTypeOptions, LeadDetail, MoveTypeOptions, TransportTypeOptions, eMoveCategoryType, eMoveType, eTransportType } from '../models/lead-detail.model';
import { MoveStatus } from '../models/move-status.model';
import { ContactService } from 'src/services/contact.service';
import { ElementRef, Renderer2 } from '@angular/core';
import { GeneralService } from 'src/services/general.service';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SearchCustomerDTO } from '../interfaces/search-customer-dto';
import { Status, eStatusType } from '../models/status.model';
import { Customer, eContactType } from '../../crm/contact/contact.models';
import { AuthService } from 'src/services/auth.service';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { allLeadsColumns, LeadBoardDTO, LeadBoardResponse } from './lead-board.model';


@Component({
  selector: 'app-lead-board',
  templateUrl: './lead-board.component.html',
  styleUrls: ['./lead-board.component.scss']
})
export class LeadBoardComponent implements OnInit {
  modalRef!: NgbModalRef;

  searchQuery: string = '';
  _completedStatuses: string[] = [
    'Lead Initiated'
    //'Customer Information', 'Move Info',
    //'Pre-Move Survey',
    //'Quotation Sent',
    //'Quotation Confirmed'
  ];

  // for data table
  columnMode: ColumnMode = ColumnMode.force;
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  // columnMode: ColumnMode = ColumnMode.flex;
  // columnMode: ColumnMode = ColumnMode.standard;
  columnToggleSubject = new Subject<void>();
  loadingIndicator = true;
  tableData: any[] = [];
  tableColumns: any[] = [];
  _allColumns: string[] = allLeadsColumns;
  defaultColumns: string[] = ['Lead ID', 'Customer', 'Account', 'Status', 'Origin', 'Destination'];
  _selectedColumns: { [key: string]: boolean } = {};
  _selectedStatus: string = 'All';

  // variables
  _companyId: number;
  _customerType: number | null = null;
  _leadStatusId: any = 'all';

  _emailExists: boolean = false;
  _userEmail: string | null = null;
  _leadEmail: string | null = null;

  // Object
  _lead = new Lead();
  _newCustomer = new Customer();
  _leadBoardItem = new LeadBoardDTO();

  // lists
  _allCustomers: any = [];
  _filteredContactPOCs: Customer[] | null = [];
  _filteredTPPOCs: Customer[] | null = [];
  _contacts: any = [];
  _companies: any = [];
  _leadsTableData: LeadBoardDTO[] = [];
  _displayedLeadsList: LeadBoardDTO[] = [];
  _leadsList: any[] = [];
  _leadStatuses: any[] = [];
  _statusList: any[] = [];
  // dynamic titles
  _modaltitle: string = 'Default Title';
  _addBtnTitle: string = '';
  _chooseAccountTitle: string = '';

  // NGB Modals
  @ViewChild('pocModal') pocModal: any;
  @ViewChild('basicModal') basicModal: any;
  @ViewChild('leadPreviewModal') leadPreviewModal: any;

  _basicModalRef: NgbModalRef | undefined;
  _pocModalRef: NgbModalRef | undefined;

  // For POC ngForm
  showPOCForm: boolean = false;
  newPOCName: string = '';
  newPOCCompany: string = '';

  // search customer functionality
  _searchCustomer = new SearchCustomerDTO();
  _searchedCustomersList: SearchCustomerDTO[] = [];
  @ViewChild('firstNameDropdown', { static: false }) firstNameDropdown!: NgbDropdown;
  @ViewChild('lastNameDropdown', { static: false }) lastNameDropdown!: NgbDropdown;
  @ViewChild('phoneDropdown', { static: false }) phoneDropdown!: NgbDropdown;
  @ViewChild('emailDropdown', { static: false }) emailDropdown!: NgbDropdown;

  // for lead status
  _leadInitiatedStatus = new Status();
  _firstStatus: string = '';
  _lastStatus: string = '';
  _leadInitiatedWord = 'Lead Initiated';

  _newStatus: string = '';
  _inProgress: string = '';
  _wonStatus: string = '';
  _lostStatus: string = '';

  contact: any;
  isLoading: boolean = false;
  // dates for datepickers
  // for survey
  // packing 
  _hoveredDate: NgbDate | null = null;
  _fromDate: NgbDate;
  _toDate: NgbDate | null = null;

  // loading
  _hoveredDate1: NgbDate | null = null;
  _fromDate1: NgbDate | null;
  _toDate1: NgbDate | null = null;
  _loadingDate: NgbDateStruct | null;

  // storage dates
  _surveyDate: NgbDateStruct | null;

  // delivery date
  _deliveryDate: NgbDateStruct | null;
  _storageNeeded: boolean = false;
  _hoveredDate2: NgbDate | null = null;
  _fromDate2: NgbDate;
  _toDate2: NgbDate | null = null;

  // modal instance for all date pickers
  _modalInstance!: NgbModalRef;
  _modalInstance1!: NgbModalRef;
  _modalInstance2!: NgbModalRef;

  // enums 
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;
  phone: any;

  // for account and thirdparty
  _selectedAccountId: number | null = 0;
  _selectedThirdPartyId: number | null = 0;

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;

  _leadInfoReadPermission = false;
  _crmContactReadPermission = false;
  _userRole: string | null = null;

  _currentPage = 1;
  _pageSize = 10;
  _totalRecords = 0;
  _searchTerm = '';
  private _searchTermSubject: Subject<string> = new Subject<string>();

  constructor(
    private _modalService: NgbModal,
    private fb: FormBuilder,
    private _movdService: MovdService,
    private _contactService: ContactService,
    private _appComponent: AppComponent,
    private _router: Router,
    private _calendar: NgbCalendar,
    private renderer: Renderer2,
    public formatter: NgbDateParserFormatter,
    public generalService: GeneralService,
    private _changeDetectRef: ChangeDetectorRef,
    private _authService: AuthService,
    private breadcrumbService: BreadcrumbService,
  ) {
    this._userRole = localStorage.getItem('role');
    this._companyId = Number(localStorage.getItem('companyId'));

    const today = _calendar.getToday();
    this._surveyDate = _calendar.getToday();
    this._deliveryDate = _calendar.getToday();
    this._loadingDate = _calendar.getToday();

    this._fromDate = _calendar.getToday();
    this._toDate = _calendar.getNext(_calendar.getToday(), 'd', 10);

    this._fromDate1 = _calendar.getToday();
    this._toDate1 = _calendar.getNext(_calendar.getToday(), 'd', 10);

    this._fromDate2 = _calendar.getToday();
    this._toDate2 = _calendar.getNext(_calendar.getToday(), 'd', 10);

    this.columnToggleSubject.pipe(
      debounceTime(10) // Adjust this value as needed toggleColumnVisibility() will only be called once per 300 milliseconds 
    ).subscribe(() => this.toggleColumnVisibility());

    // this.createLeadBoardColumns();
    // Subscribe to _searchTermSubject with debounceTime to delay execution
    this._searchTermSubject.pipe(debounceTime(1000)).subscribe(searchTerm => {
      // console.log('Perform search for:', searchTerm);
      // Perform actual search operation here, e.g., call a service method
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Leads', url: '/leads-board' },

    ]);


    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Leads List.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Leads List.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('Leads List.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Leads List.Delete');

    this._crmContactReadPermission = permissions.includes('CRM - Contacts.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Info.Read') || this._userRole === 'CompanyAdmin';

    // console.log("ng on init of lead board");
    // Load selected columns for this section from localStorage or initialize with defaults
    const storedSelectedColumns = localStorage.getItem('selectedColumnsForLeadsList');
    if (storedSelectedColumns) {
      const parsedColumns = JSON.parse(storedSelectedColumns);
      this._selectedColumns = {};
      for (const column of this._allColumns) {
        this._selectedColumns[column] = parsedColumns[column] === true; // Only assign true values
      }
    } else {
      this._selectedColumns = {};
      for (const column of this.defaultColumns) {
        this._selectedColumns[column] = true;
      }
    }

    if (this._userRole !== 'CompanyAdmin') {
      this._allColumns = this._allColumns.filter(column => column !== 'Action');
    }
    // Update tableColumns based on selected columns
    this.tableColumns = this._allColumns
      .filter(column => this._selectedColumns[column] === true)
      .map(column => ({ name: column }));

    if (this._companyId !== 0) {
      this.getLeadsForBoardById();
      this.getStatuses(eStatusType.Lead, eMoveCategoryType.DoorToDoorLocal, eTransportType.Air);
    }

    this.getCustomers();
  }



  // search customer
  searchCustomer(type: string) {
    this._searchedCustomersList = []
    let name = null;
    let phone = null;
    let email = null;

    if (this._lead.firstName) {
      this._lead.customerName = this._lead.lastName
        ? `${this._lead.firstName} ${this._lead.lastName}`
        : this._lead.firstName;
    } else {
      this._lead.customerName = null;
    }
    this._lead.email = this._leadEmail;
    name = this._lead.customerName;
    phone = this._lead.phone;
    email = this._lead.email;

    // console.log("name", name);
    // console.log("phone", phone);
    // console.log("email", email);

    if (name === null && email === null && phone === null) {
      return;
    }

    this._movdService.searchCustomer(name, phone, email).subscribe({
      next: (response: any) => {
        // console.log("searched Customers list", response);
        if (response && response.length > 0) {
          this._searchedCustomersList = response;
        }
      },

    });
    this._changeDetectRef.detectChanges();
  }

  selectCustomer(searchedCustomer: SearchCustomerDTO) {
    // console.log("searched customer:", searchedCustomer);
    if (searchedCustomer.customerName) {
      let names = searchedCustomer.customerName.split(' ');
      this._lead.firstName = names[0];
      this._lead.lastName = names.length > 1 ? names.slice(1).join(' ') : '';
    }

    this._lead.customerId = searchedCustomer.customerId;
    this._lead.phone = searchedCustomer.phone;
    this._leadEmail = searchedCustomer.email;

    this._changeDetectRef.detectChanges();
  }

  onEmailChange(event: Event) {
    const newEmail = (event.target as HTMLInputElement)?.value;
    if (newEmail) {
      const selectedCustomer = this._searchedCustomersList.find(customer =>
        customer.email?.toLowerCase() === newEmail.toLowerCase()
      );

      if (selectedCustomer) {
        this._lead.customerId = selectedCustomer.customerId;
      } else {
        this._lead.customerId = 0;
      }

      this._leadEmail = newEmail;
    }
  }



  // statuses 
  getStatuses(statusType: eStatusType, category: eMoveCategoryType, transport: eTransportType) {
    this._movdService.GetStatuses(statusType, category, transport).subscribe({
      next: (response: any) => {
        // console.log("status list lead board", response);
        // this._statusList = response.filter((item: { isDeleted: boolean; }) => item.isDeleted === false);

        // this section for saving lead initiated done for the newly created lead
        // let leadInitiateWord = 'Lead Initiated';
        let leadInitiatedStatus = response.filter((item: Status) => item.isDeleted === false && item.title.toLowerCase() === this._leadInitiatedWord.toLowerCase());

        // console.log("leadInitiatedStatus", leadInitiatedStatus);
        if (leadInitiatedStatus && leadInitiatedStatus.length === 0) {
          this.saveLeadInitiatedStatus();
        } else {
          this._leadInitiatedStatus = leadInitiatedStatus[0];
        }

        const firstStatus = response.find((item: any) => item.order === 0);
        this._firstStatus = firstStatus ? firstStatus.title : '';

        const lastStatus = response.reduce((maxItem: any, currentItem: any) => {
          return currentItem.order > (maxItem ? maxItem.order : 0) ? currentItem : maxItem;
        }, null);
        this._lastStatus = lastStatus ? lastStatus.title : '';

        // // Check if the statuses in _statusList match the completed statuses
        // const allStatusesMatch = this._statusList.every((status: { title: string; }) =>
        //   this._completedStatuses.includes(status.title)
        // );

      },
      error: (error: any) => {
        console.error("error getting status list", error);
      }
    });
  }


  // for changing table data according to status
  onClickStatus(label: string) {
    this._selectedStatus = label;
    switch (label) {
      case 'All':
        this._displayedLeadsList = this._leadsTableData;
        this.tableColumns.splice(4, 0, { header: 'Status' });
        break;

      case 'New':
        // console.log("first status", this._firstStatus);
        const newLeads = this._leadsTableData.filter(leads => leads.status === this._leadInitiatedWord && leads.markLost !== true && leads.moveInitiated !== true);
        this._displayedLeadsList = newLeads;
        // console.log("New leads", this._displayedLeadsList);
        // this.showLeadsByStatus(label);
        this.tableColumns = this.tableColumns.filter(column => column.header !== 'Status');
        break;

      case 'In Progress':
        const inProgressLeads = this._leadsTableData.filter(leads => leads.status !== this._leadInitiatedWord && leads.markLost !== true && leads.moveInitiated !== true);
        this._displayedLeadsList = inProgressLeads;
        // console.log("In Progress leads", this._displayedLeadsList);
        // this.showLeadsByStatus(label);
        this.tableColumns = this.tableColumns.filter(column => column.header !== 'Status');
        break;

      case 'Won':
        const wonLeads = this._leadsTableData.filter(leads => leads.moveInitiated === true);
        this._displayedLeadsList = wonLeads;
        // console.log("Won leads", this._displayedLeadsList);
        this.tableColumns = this.tableColumns.filter(column => column.header !== 'Status');
        // this.showLeadsByStatus(label);
        break;

      case 'Lost':
        const lostLeads = this._leadsTableData.filter(leads => leads.markLost === true);
        this._displayedLeadsList = lostLeads;
        // console.log("Lost leads", this._displayedLeadsList);
        this.tableColumns = this.tableColumns.filter(column => column.header !== 'Status');
        // this.showLeadsByStatus(label);
        break;

      default:
        // Handle the case when the label is not recognized
        break;
    }
  }

  showLeadsByStatus(status: any) {
    const filteredDeals = this._leadsTableData.filter(leads => leads.status === status);
    this._displayedLeadsList = filteredDeals;
    // console.log("_displayedDeals: ", this._displayedLeadsList)
    //console.log("pipeId: ", pipeId)
  }

  isStatusCompleted(status: string): boolean {
    return this._completedStatuses.includes(status);

  }

  getLeadsForBoardById() {
    this._movdService.getLeadsForBoard(this._currentPage, this._pageSize, this._searchTerm).subscribe({
      next: (data: LeadBoardResponse) => {
        // console.log('data leads', data);

        this._leadsTableData = data.leads;
        this._totalRecords = data.totalRecords;
        this._displayedLeadsList = data.leads;

        if (data.leads && data.leads.length > 0) {
          this.createLeadBoardColumns();
          // Update tableColumns to include new combined date columns
        }
      },
      error: (error) => {
        console.error("error getting leads for board", error);
      }
    });
  }

  createLeadBoardColumns() {
    // leadBoardDto object for columns
    var leadBoardDto = new LeadBoardDTO();

    this.tableColumns = Object.keys(leadBoardDto)
      .filter((key) => key !== 'leadId'
        && key !== 'lastName'
        && key !== 'phone'
        && key !== 'email'
        && key !== 'pocId'
        && key !== 'accountId'
        && key !== 'accountName'
        && key !== 'thirdPartyId'
        && key !== 'thirdPartyPocId'
        && key !== 'accountManagerId'
        && key !== 'creationDate'
        && key !== 'surveyId'
      )
      .map((key) => {
        let header: string;
        let prop: string = key;
        switch (key) {
          case 'leadDetailId':
            header = 'Lead ID';
            break;
          case 'account':
            header = 'Account';
            break;
          case 'customerName':
            header = 'Customer';
            break;
          case 'status':
            header = 'Status';
            break;
          case 'origin':
            header = 'Origin';
            break;
          case 'destination':
            header = 'Destination';
            break;
          case 'moveType':
            header = 'Move';
            break;
          case 'moveCategory':
            header = 'Move Category';
            break;
          case 'moveTransport':
            header = 'Transport Type';
            break;
          case 'portOfLoading':
            header = 'POL';
            break;
          case 'portOfDischarge':
            header = 'POD';
            break;
          case 'placeOfDelivery':
            header = 'Place Of Delivery';
            break;
          case 'pocName':
            header = 'POC';
            break;
          case 'thirdPartyName':
            header = 'Third Party';
            break;
          case 'thirdPartyPocName':
            header = 'Third Party POC';
            break;
          case 'packagingDate':
            header = 'Pack Date';
            break;
          case 'loadingDate':
            header = 'Loading Date';
            break;
          case 'storageDate':
            header = 'Storage Date';
            break;
          case 'warehouseStorage':
            header = 'Warehouse Storage';
            break;
          case 'deliveryDate':
            header = 'Delivery Date';
            break;
          case 'accountManager':
            header = 'Account Manager';
            break;
          default:
            header = key; // Use the original key if no specific header is defined
            break;
        }
        return { header, prop };
      });
    this.toggleColumnVisibility();

  }

  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  // to convert string date in row
  convertStringToNgbDateStruct(dateString: string): NgbDateStruct | null {
    if (dateString) {
      const dateParts = dateString.split('T')[0].split('-'); // Split the date string

      if (dateParts.length === 3) {
        const year = +dateParts[0]; // Convert to number
        const month = +dateParts[1]; // Convert to number
        const day = +dateParts[2]; // Convert to number

        // Check if the components are valid numbers
        if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
          return { year, month, day };
        }
      }
    }
    return null;
  }


  leadPreviewClick(data: any, type: string) {
    if (this._readPermission === false) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to visit the Lead detail', '');
      return;
    }
    // console.log("leadPreviewClick", data);
    this._leadBoardItem = data;
    // this._leadBoardItem = this._leadsList.find((leadBoarditem: any) => leadBoarditem.leadDetailId === data.leadDetailId);
    // console.log("_leadBoardItem", this._leadBoardItem);

    if (type === 'previewBtnClick') {
      const surveryDate = this.convertStringToNgbDateStruct(data.surveyDate);
      this._surveyDate = surveryDate;

      const deliveryDate = this.convertStringToNgbDateStruct(data.deliveryDate);
      this._deliveryDate = deliveryDate;

      // console.log("this._deliveryDate", this._deliveryDate);
      // console.log("preview click", data);
      this._modalService.open(this.leadPreviewModal, { size: 'md' })
    } else if (type === 'leadIdClick') {
      this.leadInfoClick();
    }

  }

  openBasicModal() {
    this._lead = new Lead();
    this._basicModalRef = this._modalService.open(this.basicModal, {
      backdrop: 'static',
      keyboard: false,
    });

    this._basicModalRef.result.then(
      (result) => {

      },
      (reason) => {

        if (reason === 'backdrop-click') {

        }
      }
    );
  }


  //closeModal() {
  //  this._modalService.dismissAll(); // This will close the modal
  //}
  //preventBackdropClose(event: MouseEvent) {
  //  // Check if the click target is the backdrop element
  //  if (event.target && event.target === event.currentTarget) {
  //    // Optionally, you can add additional logic here to handle clicks on the backdrop
  //    // For example, you can close the modal if needed
  //    // this._basicModalRef.dismiss('by: backdrop click');
  //    // Or prevent the click without closing the modal
  //    event.preventDefault();
  //    event.stopPropagation();
  //  }
  //}

  closeModal() {
    this._modalService.dismissAll();
  }




  addlead() {
    // Implement your add lead logic here
  }

  openPopup(field: string) {
    if (field === 'poc') {
      this.showPOCForm = true;
    }
  }

  openpoc(field: string) {
    this._newCustomer = new Customer();
    this._emailExists = false;
    this._userEmail = null;

    if ((field === 'poc' || field === 'thirdPartyPoc' || field === 'thirdParty' || field === 'contact' || field === 'account') && this.pocModal) {
      if (field === 'poc') {
        this._modaltitle = 'Add POC';
        this._addBtnTitle = 'Add POC';
        this._chooseAccountTitle = 'Account';
        this._customerType = 0;

      } else if (field === 'thirdPartyPoc') {
        this._modaltitle = 'Add Third Party POC';
        this._addBtnTitle = 'Add POC';
        this._chooseAccountTitle = 'Third Party';
        this._customerType = 0;

      } else if (field === 'thirdParty') {
        this._modaltitle = 'Add Third Party';
        this._addBtnTitle = 'Add Third Party';
        this._chooseAccountTitle = 'Third Party';
        this._customerType = 1;
      } else if (field === 'account') {
        this._modaltitle = 'Add Account';
        this._addBtnTitle = 'Add Account';
        this._customerType = 1;
      }


      this._pocModalRef = this._modalService.open(this.pocModal);
    } else if (field === 'poc') {
      this.showPOCForm = true;
    }
  }

  checkEmailExists(email: string | null) {
    if (!email) {
      // If the email is null or empty, reset the _emailExists flag
      this._emailExists = false;
      return;
    }

    this._authService.checkEmailExists(email).subscribe({
      next: (response: boolean) => {
        this._emailExists = response;
        // console.log("checking email exists or not", response);
      },
      error: (error: any) => {
        console.error("error validating email", error);
      }
    });
  }

  savePOC() {
    // Save logic for POC here
    this.showPOCForm = false;
    this.newPOCName = '';
    this.newPOCCompany = '';
  }

  cancelPOC() {
    this.showPOCForm = false;
    this.newPOCName = '';
    this.newPOCCompany = '';
  }

  validatePhoneNumber(event: KeyboardEvent): void {
    const input = event.key;
    const isNumeric = /[0-9]/.test(input);
    const currentPhone = this._lead.phone || '';

    // If it's not a valid input, prevent further changes
    if (!isNumeric && input !== '+') {
      event.preventDefault();
      return;
    }

    // Automatically prepend '+' if it's not already present and the input is a number
    if (currentPhone.length === 0 && isNumeric) {
      this._lead.phone = '+' + input;
      event.preventDefault();
      return;
    }

    // Prevent '+' sign if it's not at the beginning of the number
    if (input === '+' && currentPhone.length !== 0) {
      event.preventDefault();
      return;
    }

    const newValue = currentPhone + input;
    if (newValue.length > 15) {
      event.preventDefault();
      return;
    }
    else if (newValue.length > 0 && newValue.length < 8) {
      this.phone = newValue;
    }
    // Assign the new value to the phone number
    // this._lead.phone = newValue;
  }



  isValidPhoneNumber(value: string): boolean {
    return /[0-9+^$]/.test(value) && value.length >= 8 && value.length <= 15;
  }

  isFormValid(): boolean {
    // return !!this.customerName && !!this.lastName && !!this.phone && !!this.email;
    return true
  }

  getCustomers() {
    this._contactService.getContacts().subscribe({
      next: (response: any) => {
        // console.log("this._companyId", this._companyId);
        if (response.contacts && response.contacts.length > 0) {
          this._allCustomers = response.contacts;
          // console.log("getCustomers data cc", response);
          this._contacts = response.contacts.filter((item: Customer) => item.contactType === eContactType.Contact && item.companyId !== this._companyId);
          this._companies = response.contacts.filter((item: Customer) => item.contactType === eContactType.Company && item.id !== this._companyId);

          // console.log("this._contacts", this._contacts);
          // console.log("this._companies", this._companies);

          if (this._selectedAccountId) {
            this._filteredContactPOCs = this._contacts.filter((contact: Customer) => contact.companyId === this._selectedAccountId);
          }
          if (this._selectedThirdPartyId) {
            this._filteredTPPOCs = this._contacts.filter((contact: Customer) => contact.companyId === this._selectedThirdPartyId);
          }
          // console.log("_contacts", this._contacts);
          // console.log("_companies", this._companies);
        }
      },
      error: (error: any) => {
        console.error("error", error);
      }
    });
  }

  isFieldEmpty(field: string | null) {
    return field === null || field.trim().length === 0;
  }

  createCustomer() {
    // console.log("createCustomer", this._newCustomer);

    if (this._emailExists ||
      this.isFieldEmpty(this._newCustomer.customerName) ||
      this.isFieldEmpty(this._newCustomer.phone) ||
      this.isFieldEmpty(this._userEmail) ||
      this._customerType === null) {
      return;
    }

    this._newCustomer.email = this._userEmail;
    this._newCustomer.contactType = this._customerType;
    this._newCustomer.ownedBy = this._companyId;
    this._newCustomer.createdBy = Number(localStorage.getItem('currentUserId'));

    this._contactService.addContact(this._newCustomer).subscribe({
      next: (response: any) => {
        // console.log("createCustomer respnse", response);
        this._appComponent.showSuccessSwal("created", "record");
        this.getCustomers();
        if (this._pocModalRef && this._pocModalRef !== undefined) {
          this._pocModalRef.close();
        }
      },
      error: (error: any) => {
        console.error("createCustomer error", error);
        this._appComponent.showErrorSwal("creating", "record");
      }
    });
  }

  filterContacts(type: string, id: number | null) {

    console.log("filter contacts", id);
    if (this._contacts && this._contacts.length > 0) {
      if (type === 'contact') {
        this._selectedAccountId = id;
        if (this._contacts && this._contacts.length > 0) {
          this._filteredContactPOCs = this._contacts.filter((contact: Customer) => contact.companyId === id);
        }
      } else if (type === 'thirdParty') {
        this._selectedThirdPartyId = id;
        this._filteredTPPOCs = this._contacts.filter((contact: Customer) => contact.companyId === id);
      }
    }
  }

  createLead() {
    this.isLoading = true;
    this._lead.email = this._leadEmail;
    if (
      this._lead.firstName === null || this._lead.firstName.trim().length === 0 ||
      this._lead.phone === null || this._lead.phone.trim().length === 0 ||
      this._lead.email === null || this._lead.email.trim().length === 0
    ) {
      return;
    }

    this._lead.ownedBy = this._companyId;
    if (this._lead.firstName) {
      this._lead.customerName = this._lead.lastName
        ? `${this._lead.firstName} ${this._lead.lastName}`
        : this._lead.firstName;
    } else {
      this._lead.customerName = null;
    }

    // console.log("creating lead", this._lead);

    this._movdService.saveLead(this._lead).subscribe({
      next: (response: any) => {
        this.isLoading = true;
        // console.log("createLead response", response);
        this._appComponent.showSuccessSwal("created", "record");
        if (this._basicModalRef && this._basicModalRef !== undefined) {
          this._basicModalRef.close();
          this.saveStatus(response.id);
          this.navigateToLeadDetail(response);
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        this._appComponent.showErrorSwal("creating", "record");
        console.error("error creating lead", error);
      }
    });
  }

  async deleteLeadDetail(data: any) {
    // console.log("delete Lead Detail", data);

    let confirmed = this._appComponent.showConfirmationDialog(
      'Delete lead?',
      'If a related Survey or Move exists, it will also be deleted. Are you sure?',
      'Yes, delete it!'
    );

    if (await confirmed === false) {
      return;
    }

    const leadDetailId = data.leadDetailId;

    this.isLoading = true;
    this._movdService.deleteLeadDetail(leadDetailId).subscribe({
      next: (response: any) => {
        this.isLoading = false;

        // console.log("deleteLead response", response);
        this._appComponent.showSuccessSwal("deleted", "record");
        this.getLeadsForBoardById();
      },
      error: (error: any) => {
        this.isLoading = false;
        console.error("error deleting lead", error);
      }
    });
  }

  navigateToLeadDetail(data: LeadDetail) {
    const leadDetailId = data.id;
    const leadId = data.leadId;
    const queryParams = { leadDetailId, leadId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this._router.navigate(['/lead-detail'], navigationExtras);
  }

  updateLead() {
    const userId = Number(localStorage.getItem('currentUserId'));
    const lead: Lead = {
      id: this._leadBoardItem.leadId || 0,
      firstName: this._leadBoardItem.firstName,
      lastName: this._leadBoardItem.lastName,
      phone: this._leadBoardItem.phone,
      email: this._leadBoardItem.email,
      accountId: this._leadBoardItem.accountId,
      pocId: this._leadBoardItem.pocId,
      thirdPartyId: this._leadBoardItem.thirdPartyId,
      thirdPartyPocId: this._leadBoardItem.thirdPartyPocId,
      modificationDate: new Date(),
      modifiedBy: userId,
      // props that dont need to be updated
      ownedBy: 0,
      createdBy: 0,
      deletionDate: new Date(),
      isDeleted: false,
      accountName: null,
      pocName: null,
      thirdPartyName: null,
      thirdPartyPocName: null,
      leadDetailId: null,
      customerId: 0,
      customerName: null
    };

    // console.log("update lead respnse", lead);
    this._movdService.updateLead(lead.id, lead).subscribe({
      next: (response: any) => {
        // console.log("update lead respnse", response);
        this._appComponent.showSuccessSwal("updated", "record");
        if (this._basicModalRef && this._basicModalRef !== undefined) {
          this._basicModalRef.close();
        }
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("updating", "record");
        console.error("error", error);
      }
    });
  }

  openColumnModal(content: TemplateRef<any>) {
    if (this._userRole === 'CompanyAdmin' && !this._allColumns.includes('Action')) {
      this._allColumns.push('Action');
    }

    this._modalService.open(content, { size: 'md' }).result.then((result) => {

    }).catch((res) => { });
  }

  onPageChange(event: any) {
    // console.log("onPageCha");

    this._currentPage = event.offset + 1;
    this.getLeadsForBoardById();
  }

  onInputChange(event: any) {
    // console.log("on input change event", event);
    // console.log("on input change event.data", event.data);

    if (event && event.data === null) {
      // console.log("condition checked", event && event.data === null);
      this.getLeadsForBoardById();
    }
  }

  onSearch(): void {
    this._searchTermSubject.next(this._searchTerm);

    this.getLeadsForBoardById();
  }

  toggleColumnVisibility() {
    this.tableColumns = this._allColumns
      .filter(column => this._selectedColumns[column])
      .map(column => ({ header: column }));

    localStorage.setItem('selectedColumnsForLeadsList', JSON.stringify(this._selectedColumns));


  }

  openDateModal(type: any, template: any) {
    this._modalService.open(template);
  }

  onDateSelectSurvey(newDate: NgbDate) {
    this._surveyDate = newDate;
  }

  onDateSelectDelivery(newDate: NgbDate) {
    this._deliveryDate = newDate;
  }

  formatSurveyDate(): string {
    return this._surveyDate
      ? `${this._surveyDate.day.toString().padStart(2, '0')}/${this._surveyDate.month.toString().padStart(2, '0')}/${this._surveyDate.year}`
      : '';
  }

  formatDeliveryDate() {
    return this._deliveryDate
      ? `${this._deliveryDate.day.toString().padStart(2, '0')}/${this._deliveryDate.month.toString().padStart(2, '0')}/${this._deliveryDate.year}`
      : '';
  }

  onDateSelection(date: NgbDate) {
    if (!this._fromDate && !this._toDate) {
      this._fromDate = date;
    } else if (this._fromDate && !this._toDate && date.after(this._fromDate)) {
      this._toDate = date;
    } else {
      this._toDate = null;
      this._fromDate = date;
    }

    if (this._modalInstance !== undefined) {
      if (this._fromDate !== null && this._toDate !== null) {
        this._modalInstance.close();
      }
    }
  }

  isHovered(date: NgbDate) {
    return this._fromDate && !this._toDate && this._hoveredDate && date.after(this._fromDate) && date.before(this._hoveredDate);
  }

  isInside(date: NgbDate) {
    return this._toDate && date.after(this._fromDate) && date.before(this._toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this._fromDate) || (this._toDate && date.equals(this._toDate)) || this.isInside(date) || this.isHovered(date);
  }

  leadInfoClick() {
    if (this._leadInfoReadPermission === false && this._userRole !== 'CompanyAdmin') {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the lead info form', '');
      return;
    }

    const leadDetailId = this._leadBoardItem.leadDetailId;
    const leadId = this._leadBoardItem.leadId;
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Lead Dashboard', url: '/leads-board' },
      { text: 'Lead Information Form', url: '/lead-detail' },
    ]);
    // localStorage.setItem('leadId', this._allLeads.leadId.toString());
    // localStorage.setItem('moveDetailId', moveDetailId.toString());

    const queryParams = { leadDetailId, leadId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    // const url = this._router.createUrlTree(['/lead-detail'], navigationExtras).toString();
    // console.log("url", url);

    this._router.navigate(['/lead-detail'], navigationExtras);
    // window.open(url, '_blank');

  }

  returnLeadUrl(row: any) {
    const leadDetailId = row.leadDetailId;
    const leadId = row.leadId;
    return { leadDetailId, leadId };
  }

  // now save move status after creating a lead and move detail
  saveStatus(leadDetailId: any) {
    // console.log('leadDetailId', leadDetailId);
    const moveStatusList: MoveStatus[] = [];

    // const sortedStatusArray = this._statusList.sort((a, b) => a.order - b.order);
    // // console.log("sorted status array", sortedStatusArray);

    // const firstStatus = sortedStatusArray[0];
    // const date = new Date();
    // // console.log("date", new Date());
    // for (const category of MoveCategoryTypeOptions) {
    //   const moveStatus = new MoveStatus();

    //   moveStatus.id = 0;
    //   moveStatus.refId = leadDetailId;
    //   moveStatus.statusId = firstStatus.id;
    //   moveStatus.done = true;
    //   moveStatus.date = new Date();
    //   moveStatus.category = category.id;

    //   moveStatusList.push(moveStatus);
    // }

    const moveStatus: MoveStatus = {
      id: 0,
      refId: leadDetailId,
      statusId: this._leadInitiatedStatus.id,
      date: new Date(),
      done: true,
      category: this._leadInitiatedStatus.category,
      transportType: this._leadInitiatedStatus.transportType,
      createdBy: 0,
      isDeleted: false
    }

    // Call the MovdService to send the update request to the backend
    this._movdService.createMoveStatusList([moveStatus]).subscribe({
      next: (response: any) => {
        // console.log('createMoveStatusList response', response);
      },
      error: (error: any) => {
        console.error('Error updating status', error);
        // Handle error, e.g., show an error message to the user
      }
    });
  }

  redirectToAccount(id: any) {
    if (this._crmContactReadPermission === false) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the profile', '');
      return;
    }

    if (!id) {
      return;
    }

    const text = 'company';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Lead Dashboard', url: '/leads-board' },
      { text: 'Profile', url: '/profile' },
    ]);

    this._router.navigate(['/profile'], navigationExtras);
  }

  // validateEmailExists(): void {
  //   const input = this._userEmail;

  //   if (!input || input.trim().length === 0) {
  //     // Invalid or empty email format, reset 'emailExists' and return.
  //     this._emailExists = false;
  //     return;
  //   }

  //   if (this._allCustomers && this._allCustomers.length > 0) {
  //     this._emailExists = this._allCustomers.some((user: { email: string | null; }) => user.email === input);

  //   } else {
  //     this._emailExists = false;
  //   }
  //   // console.log("outer console email exists:", this._emailExists);
  // }


  // create a single state as Lead Initiated
  saveLeadInitiatedStatus(): void {
    let currentUserId = Number(localStorage.getItem('currentUserId'));

    let status: Status = {
      id: 0,
      title: 'Lead Initiated',
      order: 0,
      category: eMoveCategoryType.DoorToDoorLocal,
      transportType: eTransportType.Air,
      statusType: eStatusType.Lead,
      ownedBy: this._companyId,
      isDeleted: false,
      isActive: true,
      createdBy: currentUserId,
      required: false
    }
    // console.log('status data ', this._status);

    this._movdService.saveStatus(status).subscribe({
      next: (response: any) => {
        // console.log('Save lead initiate status response', response);
        if (response) {
          this._leadInitiatedStatus = response;
        }
        this._appComponent.showSuccessSwal('saved', 'status');
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('saving', 'status');
        console.error('Error saving status', error);
      }
    });

  }
}
